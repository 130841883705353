import { post } from "./rest";

import {
  WorkflowEventSchema,
  WorkflowSchema,
} from "@pollyslack/hub/src/schemas/workflow";

const root = "/workflows/";

export async function info(params: {
  workflowId: string;
}): Promise<{ workflow: WorkflowSchema }> {
  return await post(`${root}info`, params);
}

export async function list(params: {
  search?: string;
  showDeleted?: boolean;
}): Promise<WorkflowSchema[]> {
  return await post(`${root}list`, params);
}

export async function update(params: {
  workflowId: string;
  active?: boolean;
}): Promise<{ workflow: WorkflowSchema }> {
  return await post(`${root}update`, params);
}

export async function events(params: {
  workflowId: string;
}): Promise<{ workflowEvents: WorkflowEventSchema[] }> {
  return await post(`${root}events`, params);
}
