import React from "react";

import {
  Resource,
  ResourceGrantMap,
} from "@pollyslack/hub/src/pacl/roles/common";

import { ResourceGrantsList } from "./ResourceGrantsList";

type ResourceGrantsTableProps = {
  resources: ResourceGrantMap;
};
export const ResourceGrantsTable: React.FunctionComponent<
  ResourceGrantsTableProps
> = (props: ResourceGrantsTableProps) => {
  const { resources } = props;
  const resourceNames = Object.keys(resources).sort();

  return (
    <table className="table table-sm table-borderless">
      <tbody>
        {resourceNames.map((n) => {
          const grants = resources[n as Resource] as any[];
          return <ResourceGrantsList key={n} grants={grants} />;
        })}
      </tbody>
    </table>
  );
};
