import _ from "lodash";
import React from "react";

import { SignedInContext } from "../SignedInContext";

/**
 * A hook for loading data
 *
 * @typeparam T  The generic type parameter for the data.
 * @typeparam S  The generic type parameter for the search.
 * @param load An async function that loads data for the search parameters.
 * @param
 * @returns The load result, and a function for setting the search parameters.
 */
export function useApi<T, S>(
  load: (s: S) => Promise<T>,
): [
  {
    isLoading: boolean;
    error: string | null;
    data: T;
  },
  (s: S) => void,
] {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [search, setSearch] = React.useState<S>();
  const [data, setData] = React.useState<T>();
  const { setSignedIn } = React.useContext(SignedInContext);

  function setSearchDeep(s: S) {
    if (!_.isEqual(s, search)) {
      setSearch(s);
    }
  }

  React.useEffect(() => {
    if (!search) {
      return () => {};
    }
    let didCancel = false;
    async function fetchData(): Promise<void> {
      setError(null);
      setIsLoading(true);
      try {
        const result = await load(search);
        if (!didCancel) {
          setData(result);
        }
      } catch (e) {
        if (e.message.startsWith("401:")) {
          setSignedIn(false);
        }
        if (!didCancel) {
          setError(e.message);
        }
      }
      if (!didCancel) {
        setIsLoading(false);
      }
    }
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [search, load, setSignedIn]);
  return [{ isLoading, error, data }, setSearchDeep];
}
