export const enum Cadence {
  WEEKLY = 0,
  BIWEEKLY = 1,
  MONTHLY = 2,
  THREE_MONTHS = 3,
  BIANNUALLY = 4,
  TRIWEEKLY = 5,
  DAILY = 10,
  DAILY_INCLUDING_WEEKENDS = 11,
}
