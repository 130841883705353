import React from "react";

import { ToolbarButton } from "../../components/ToolbarButton";

type TrackActionsToolbarProps = {
  trackId: string;
  onTransferOwnershipClick?: () => void;
  onDeactivateClick?: () => void;
  onReactivateClick?: () => void;
};

export const TrackActionsToolbar: React.FunctionComponent<
  TrackActionsToolbarProps
> = (props: TrackActionsToolbarProps) => {
  return (
    <div className="btn-group mb-2">
      {props.onTransferOwnershipClick && (
        <ToolbarButton
          icon="fa-exchange-alt"
          title="Transfer Ownership"
          onClick={props.onTransferOwnershipClick}
        />
      )}
      {props.onDeactivateClick && (
        <ToolbarButton
          className="btn-outline-danger"
          icon="fa-times-circle"
          title="Deactivate Track"
          onClick={props.onDeactivateClick}
        />
      )}
      {props.onReactivateClick && (
        <ToolbarButton
          className="btn-outline-success"
          icon="fa-plus-circle"
          title="Reactivate Track"
          onClick={props.onReactivateClick}
        />
      )}
    </div>
  );
};
