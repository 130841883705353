import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { FormGroup } from "./FormGroup";
import { list as subscriptionsList } from "../../services/api/Subscription";
import { SubscriptionSchema as Subscription } from "@pollyslack/hub/src/features/types";

interface Props {
  currentOwnerId: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    currentOwnerId: string,
    newOwnerId: string,
    options: {
      transferData?: boolean;
      transferSubscription?: { subscriptionId: string };
    },
    memo?: string,
  ) => Promise<void>;
}

export function TransferOwnershipModal({
  currentOwnerId,
  isOpen,
  onClose,
  onSubmit,
}: Props): JSX.Element {
  const [transferSubscription, setTransferSubscription] = useState(false);
  const [transferData, setTransferData] = useState(false);
  const [newOwnerId, setNewOwnerId] = useState("");
  const [activeSubscription, setActiveSubscription] =
    useState<Subscription>(undefined);
  const [memo, setMemo] = useState<string>();

  useEffect(() => {
    subscriptionsList({
      userId: currentOwnerId,
    }).then((subs) => {
      const activeSubs = subs.filter((sub) => sub.active);
      activeSubs.length === 1 && setActiveSubscription(activeSubs[0]);
      setTransferData(activeSubs.length !== 1);
    });
  }, []);

  const disableSubmit = !newOwnerId || (!transferData && !transferSubscription);

  return (
    <>
      <Modal isOpen={isOpen}>
        <div className="modal-header">
          <h5 className="modal-title">Transfer Ownership</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <FormGroup
            type="text"
            label="Current Owner ID"
            defaultValue={currentOwnerId}
            disabled
          />
          <FormGroup
            type="text"
            label="New Owner ID"
            placeholder="New Owner mongo _id..."
            onChange={(e) => setNewOwnerId(e.currentTarget.value)}
          />
          <FormGroup
            type="text"
            label="Memo (optional)"
            placeholder="optional memo"
            onChange={(e) => setMemo(e.currentTarget.value)}
          />
          <FormGroup
            type="checkbox"
            id="should-transfer-data"
            label="Transfer Data"
            defaultChecked={!activeSubscription}
            onChange={(e) => setTransferData(e.currentTarget.checked)}
          />
          <FormGroup
            type="checkbox"
            id="should-transfer-subscription"
            label={`Transfer Subscription${
              !activeSubscription
                ? "(number of active subscriptions is not 1)"
                : ""
            }`}
            disabled={!activeSubscription}
            onChange={(e) => setTransferSubscription(e.currentTarget.checked)}
          />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-light" onClick={onClose}>
            Close
          </button>
          <button
            className="btn btn-primary"
            disabled={disableSubmit}
            onClick={async () => {
              await onSubmit(
                currentOwnerId,
                newOwnerId,
                {
                  transferData,
                  ...(transferSubscription && activeSubscription
                    ? {
                        transferSubscription: {
                          subscriptionId: activeSubscription._id,
                        },
                      }
                    : {}),
                },
                memo,
              );
              onClose();
            }}
          >
            Submit
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}
