import React from "react";

import { Track } from "@pollyslack/hub/src/types";
import { list } from "../../services/api/Track";

import { DocumentSearchPage } from "../../components/DocumentSearchPage";
import { TracksTable } from "../../components/TracksTable";

export class TracksPage extends React.Component {
  public render() {
    return (
      <DocumentSearchPage
        typeName="Track"
        placeholder="Track or Organization ID, User Slack ID or Name"
        load={list}
        renderTable={(tracks: Track[]) => <TracksTable tracks={tracks} />}
      />
    );
  }
}
