import { DurationUnit } from "./DurationUnit";
import { Cadence } from "./Cadence";
import { ResultsDeliveryType } from "./ResultsDeliveryType";

// `is` is a no-op type assertion
// Useful in switch statements to get exhausitivity checking.
//    ex: default: is<never>(varBeingSwitchedOn);
export function is<T>(t: T): T {
  return t;
}

// Alternate form for the cadences, use this where it sounds more natural
export function getCadenceDisplayTextWithEvery(cadence: Cadence): string {
  switch (cadence) {
    case Cadence.DAILY_INCLUDING_WEEKENDS:
      return "Every day";
    case Cadence.DAILY:
      return "Every weekday";
    case Cadence.WEEKLY:
      return "Every week";
    case Cadence.BIWEEKLY:
      return "Every 2 weeks";
    case Cadence.TRIWEEKLY:
      return "Every 3 weeks";
    case Cadence.MONTHLY:
      return "Every month";
    case Cadence.THREE_MONTHS:
      return "Every 3 months";
    case Cadence.BIANNUALLY:
      return "Every 6 months";
    default:
      is<never>(cadence);
      throw Error(`Unknown cadence value ${cadence}`);
  }
}

export const getDurationDisplayText = (
  duration: number,
  durationUnit: DurationUnit,
): string => {
  return duration > 1
    ? `${duration} ${durationUnit}`
    : `${duration} ${durationUnit.slice(0, -1)}`;
};

export const getResultsDeliveryTypeDisplayText = (
  resultsDeliveryType: ResultsDeliveryType,
): string => {
  switch (resultsDeliveryType) {
    case ResultsDeliveryType.REAL_TIME:
      return "Real-time";
    case ResultsDeliveryType.DONT_SHARE:
      return "Don't share";
    case ResultsDeliveryType.THREADED:
      return "Threaded";
  }
};
