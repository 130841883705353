import React from "react";
import { SurveyWithDetails } from "../../common/apiTypes";

import { BoolCheck } from "../BoolCheck";
import { Date } from "../Date";
import {
  OrganizationLink,
  SurveyLink,
  TrackLink,
  WorkflowLink,
} from "../DocumentLink";
import { DocumentTable } from "../DocumentTable";

type SurveysTableProps = {
  surveys: SurveyWithDetails[];
};

export const SurveysTable: React.FunctionComponent<SurveysTableProps> = (
  props: SurveysTableProps,
) => {
  const properties = [
    {
      title: "Survey ID",
      render: (d: SurveyWithDetails) => (
        <SurveyLink docId={d._id}>{d.platformId}</SurveyLink>
      ),
    },
    { title: "Title", render: (d: SurveyWithDetails) => d.title },
    {
      title: "Org",
      render: (d: SurveyWithDetails) => <OrganizationLink docId={d.orgId} />,
    },
    { title: "Sender ID", render: (d: SurveyWithDetails) => d.author },
    {
      title: "Members",
      render: (d: SurveyWithDetails) => d.membersCount,
    },
    {
      title: "Responses",
      render: (d: SurveyWithDetails) => d.responsesCount,
    },
    { title: "Sender Name", render: (d: SurveyWithDetails) => d.authorName },
    {
      title: "Deleted",
      render: (d: SurveyWithDetails) => <BoolCheck check={d.deleted} />,
    },
    {
      title: "Send Date",
      render: (d: SurveyWithDetails) => <Date date={d.sendDate} />,
    },
    {
      title: "Close Date",
      render: (d: SurveyWithDetails) => <Date date={d.closeDate} />,
    },
    {
      title: "Send by DM",
      render: (d: SurveyWithDetails) => <BoolCheck check={d.dmAppeal} />,
    },
    {
      title: "Track ID",
      render: (d: SurveyWithDetails) => <TrackLink docId={d.trackId} />,
    },
    {
      title: "Workflow ID",
      render: (d: SurveyWithDetails) => <WorkflowLink docId={d.workflowId} />,
    },
  ];

  return (
    <DocumentTable<SurveyWithDetails>
      properties={properties}
      objects={props.surveys}
    />
  );
};
