import React from "react";

export { ExpanderContent } from "./ExpanderContent";
export { ExpanderToggle } from "./ExpanderToggle";

type ExpanderProps = {
  expanded: boolean;
  toggle: () => void;
  label?: string;
};

/** @deprecated Use ExpanderToggle / ExpanderContent */
export const Expander: React.FunctionComponent<ExpanderProps> = (
  props: ExpanderProps,
) => {
  return (
    <button className="btn btn-link" onClick={props.toggle}>
      {props.label || "Expand"}{" "}
      {props.expanded ? (
        <i className="fa fa-caret-up" />
      ) : (
        <i className="fa fa-caret-down" />
      )}
    </button>
  );
};
