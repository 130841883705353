import React from "react";

import { ResourceGrantMap } from "@pollyslack/hub/src/pacl/roles/common";
import { AllowedFeatures } from "@pollyslack/hub/src/features/types";
import { FeaturesTable } from "./FeaturesTable";
import { ResourceGrantsTable } from "./ResourceGrantsTable";

type PermissionsDetailsProps = {
  features: AllowedFeatures;
  resources: ResourceGrantMap;
};
export const PermissionsDetails: React.FunctionComponent<
  PermissionsDetailsProps
> = (props: PermissionsDetailsProps) => {
  const { features, resources } = props;
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm">
          <h3>Boolean Features</h3>
          <FeaturesTable features={features} type="boolean" />
        </div>
        <div className="col-sm">
          <h3>Numeric Features</h3>
          <FeaturesTable features={features} type="numeric" />
        </div>
        <div className="col-sm">
          <h3>Resource Grants</h3>
          <ResourceGrantsTable resources={resources} />
        </div>
      </div>
    </div>
  );
};
