import React from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";

import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/tomorrow-night";

SyntaxHighlighter.registerLanguage("json", json);

/**
 * Renders code by stringifying and formatting the object passed.
 * @param props
 */
export const Code: React.FunctionComponent<{ object: Object }> = (props: {
  object: Object;
}) => {
  const codeString = JSON.stringify(props.object, null, 1);
  return (
    <SyntaxHighlighter language="json" style={style}>
      {codeString}
    </SyntaxHighlighter>
  );
};
