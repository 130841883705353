export const QUEUE_URL = process.env.QUEUE_URL;
export const MONGO_URL = process.env.MONGO_URL;
export const ROOT_URL = process.env.ROOT_URL;
export const CLOUDWATCH_LOGS_LOG_GROUP = "SecurityAuditTrail"; // same for prod and dev
export const CLOUDWATCH_LOGS_LOG_STREAM =
  process.env.CLOUDWATCH_LOGS_LOG_STREAM;
export const GOOGLE_AUTH_KEY = process.env.GOOGLE_AUTH_KEY;
export const GOOGLE_AUTH_SECRET = process.env.GOOGLE_AUTH_SECRET;
export const NODE_ENV = process.env.NODE_ENV;
export const STRIPE_TOKEN = process.env.STRIPE_TOKEN;

export const MOCK_SLACK_MESSAGE_TYPE = "mock";
