import moment from "moment";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const ToolTipContent = (props: { payload: any; valueKey: string }) => {
  const { valueKey, payload } = props;
  if (payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          background: "#fff",
          fontWeight: "bold",
          lineHeight: "1",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.6)",
          border: "2px solid #00b8eb",
        }}
      >
        <p className="label">{`${payload[0].payload.date}`}</p>
        <hr />
        <p className="desc">{`${valueKey} ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

type TimeSeriesAreaChartProps = {
  data: {
    date: string | Date;
    value: number;
  }[];
  tsFrequency?: "month";
  height?: number;
  strokeColor?: string;
  fillColor?: string;
  dataKey?: string;
  tooltipKey?: string;
};

const TimeSeriesAreaChart: React.FC<TimeSeriesAreaChartProps> = ({
  data,
  tsFrequency,
  height,
  strokeColor,
  fillColor,
  dataKey,
  tooltipKey,
}) => {
  if (tsFrequency && tsFrequency === "month") {
    data.forEach((d) => {
      d.date = moment(d.date).format("MMM YYYY");
    });
  }

  return (
    <ResponsiveContainer width="100%" height={height ?? 400}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="chartColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="15%" stopColor={"#00b8eb"} stopOpacity={0.85} />
            <stop offset="98%" stopColor={"#00b8eb"} stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          style={{ fontSize: "0.85rem", fontWeight: 700 }}
        />
        <YAxis style={{ fontSize: "0.85rem", fontWeight: 700 }} />
        <Tooltip
          content={<ToolTipContent valueKey={tooltipKey} payload={data} />}
        />
        <Area
          dataKey={dataKey ?? "value"}
          stroke={strokeColor ?? "#00b8eb"}
          fill={fillColor ?? "url(#chartColor)"}
          type="linear"
          dot={{
            fill: "#ffffff",
            stroke: "#3695ce",
            r: 5,
          }}
          strokeWidth={2}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default TimeSeriesAreaChart;
