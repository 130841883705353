import React from "react";
import { Spinner } from "reactstrap";

export type LoadingScreenProps = {
  text: string;
};
export const LoadingScreen: React.FunctionComponent<LoadingScreenProps> = (
  props: LoadingScreenProps,
) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.92)",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "9999",
      }}
    >
      <Spinner />
      <h3 className="ml-3 pt-1">{props.text}</h3>
    </div>
  );
};
