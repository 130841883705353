import React from "react";

import { Code } from "../../Code";
import { ExpanderContent } from "../../Expander";

export type ExpandableCodeRowProps = {
  expanded: boolean;
  object: any;
  colSpan: number;
};

export const ExpandableCodeRow: React.FunctionComponent<
  ExpandableCodeRowProps
> = (props: ExpandableCodeRowProps) => {
  return (
    <ExpanderContent expanded={props.expanded}>
      <tr>
        <td colSpan={props.colSpan}>
          <Code object={props.object} />
        </td>
      </tr>
    </ExpanderContent>
  );
};
