import {
  FeatureGrants,
  SubscriptionSchema as Subscription,
  SubscriptionSchema,
  Tier,
} from "@pollyslack/hub/src/features/types";
import { GetUsageResult, Organization } from "@pollyslack/hub/src/types";

import { OrgTokens } from "../../common/apiTypes";
import { post } from "./rest";

const orgRoot = "/organizations/";

export async function info(id: string): Promise<Organization> {
  return await post(`${orgRoot}info`, { org_id: id });
}

export async function setPollyAdmin(
  orgId: string,
  userId: string,
  admin: boolean,
): Promise<{ ok: boolean; error?: String }> {
  return await post(`${orgRoot}set_polly_admin`, {
    org_id: orgId,
    user_id: userId,
    admin,
  });
}

export async function tokens(id: string): Promise<OrgTokens> {
  return await post(`${orgRoot}tokens`, { org_id: id });
}

export async function triggerSync(id: string): Promise<string> {
  return await post(`${orgRoot}trigger_sync`, { org_id: id });
}

export async function setOrgTier(params: {
  orgId: string;
  endDate?: string;
  tier?: Tier;
  isTrial?: boolean;
  memo?: string;
  stripeCustomerId?: string;
  clearEndDate?: boolean;
  featureOverrides?: FeatureGrants;
}): Promise<{ organization: Organization }> {
  return await post(`${orgRoot}set_org_tier`, params);
}

export async function setEnterpriseTier(params: {
  slackEnterpriseId: string;
  orgId: string;
  endDate?: string;
  tier?: Tier;
  isTrial?: boolean;
  memo?: string;
  stripeCustomerId?: string;
  clearEndDate?: boolean;
  featureOverrides?: FeatureGrants;
}): Promise<{ subscription: Subscription }> {
  return await post(`${orgRoot}set_enterprise_tier`, params);
}

export async function getEnterpriseSubscriptions(params: {
  slackEnterpriseIds: string[];
}): Promise<SubscriptionSchema[]> {
  return await post(`${orgRoot}get_enterprise_subscriptions`, params);
}

export async function setEnterpriseSubActiveState(params: {
  _id: string;
  active: boolean;
}): Promise<{ subscription: Subscription }> {
  return await post(`${orgRoot}set_enterprise_sub_active_state`, params);
}

export async function list(params: {
  search: string;
}): Promise<Organization[]> {
  return await post(`${orgRoot}list`, params);
}

export async function sendTestDM(blockKitText: string): Promise<boolean> {
  const res = await post(`${orgRoot}trigger_test_dm`, {
    blockKit: blockKitText,
  });
  return res.status === "success";
}

export async function getActiveUserList(orgId: string): Promise<string[]> {
  const res = await post(`${orgRoot}get_active_user_list`, {
    orgId,
  });
  return res;
}

export async function getSlackAdminUserList(orgId: string): Promise<string[]> {
  const res = await post(`${orgRoot}get_slack_admin_user_list`, {
    orgId,
  });
  return res;
}

export async function validateUserList(
  orgId: string,
  userIds: string[],
): Promise<{ status: string; message: string }> {
  const res = await post(`${orgRoot}validate_user_list`, {
    orgId,
    userIds,
  });
  return { status: res.status, message: res.message };
}

export async function triggerDM(
  blockKitText: string,
  orgId: string,
  userIds: string[],
): Promise<boolean> {
  const res = await post(`${orgRoot}trigger_dm`, {
    blockKit: blockKitText,
    orgId,
    userIds,
  });
  return res.status === "success";
}

export async function getPreviouslyTriggeredDmData(
  orgId: string,
): Promise<object> {
  const res = await post(`${orgRoot}get_previously_triggered_dm_data`, {
    orgId,
  });
  return res;
}

export async function getMAUData(
  monthsOfHistory: number,
  orgId: string,
  enterpriseId: string,
): Promise<GetUsageResult> {
  const res = await post(`${orgRoot}get_mau`, {
    monthsOfHistory,
    orgId,
    enterpriseId,
  });
  return res;
}
