import React from "react";

import { ToolbarButton } from "../../../components/ToolbarButton";

type PollToolsProps = {
  deleteDisabled?: boolean;
  onDeleteClick: () => Promise<void>;
};

export const PollTools: React.FunctionComponent<PollToolsProps> = (
  props: PollToolsProps,
) => {
  return (
    <div className="btn-group mb-2">
      <ToolbarButton
        className="btn-outline-danger"
        icon="fa-trash"
        title="Delete polly and associated objects and messages"
        onClick={props.onDeleteClick}
        disabled={props.deleteDisabled}
      />
    </div>
  );
};
