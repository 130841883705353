import { SlackEnterpriseId } from "@pollyslack/hub/src/slackIdTypes";
import { EnterpriseGrid, Organization } from "@pollyslack/hub/src/types";

import { EnterpriseTokens } from "../../common/apiTypes";

import { post } from "./rest";

const root = "/enterprises/";

export async function info(id: string): Promise<EnterpriseGrid> {
  return await post(`${root}info`, { slackEnterpriseId: id });
}

export async function tokens(id: string): Promise<EnterpriseTokens> {
  return await post(`${root}tokens`, { slackEnterpriseId: id });
}

export async function createSubscription(
  slackEnterpriseId: SlackEnterpriseId,
  orgId: string,
  stripeCustomerId: string,
  memo: string,
  expiration?: string,
): Promise<string> {
  const params = {
    slack_enterprise_id: slackEnterpriseId,
    org_id: orgId,
    stripe_customer_id: stripeCustomerId,
    memo,
    expiration,
  };
  return await post(`${root}create_subscription`, params);
}

export async function list(params: {
  search: string;
}): Promise<Organization[]> {
  return await post(`${root}list`, params);
}
