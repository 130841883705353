import * as React from "react";
import Spinner from "react-spinner-material";

// This is no longer exported from the typings, so we duplicate it here
interface SpinnerProps {
  size: number;
  spinnerColor: string;
  spinnerWidth: number;
  visible: boolean;
  [key: string]: any;
}

export type PollySpinnerProps = { visible: boolean } & Partial<SpinnerProps>;

const PollySpinner: React.StatelessComponent<PollySpinnerProps> = (props) => {
  const mergedProps = {
    visible: props.visible,
    radius: props.size || 30,
    color: props.spinnerColor || "#00BCD4",
    stroke: props.spinnerWidth || 2,
  };
  return <Spinner {...mergedProps} />;
};

export default PollySpinner;
