import React, { useState } from "react";

import { Survey } from "@pollyslack/hub/src/types";

import { OrganizationLink, TrackLink } from "src/components/DocumentLink";
import { BoolCheck } from "../../../components/BoolCheck";
import { Code } from "../../../components/Code";
import { Date } from "../../../components/Date";
import { ExpanderContent, ExpanderToggle } from "../../../components/Expander";

type SurveyDetailsProps = {
  survey: Survey;
};

export const SurveyDetails: React.FunctionComponent<SurveyDetailsProps> = (
  props: SurveyDetailsProps,
) => {
  const survey = props.survey;
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <table className="table table-sm table-hover">
        <tbody>
          <tr>
            <th>ID</th>
            <td>{survey._id}</td>
          </tr>
          <tr>
            <th>Title</th>
            <td>{survey.title}</td>
          </tr>
          <tr>
            <th>Introduction</th>
            <td>{survey.appeal}</td>
          </tr>
          <tr>
            <th>Appear As</th>
            <td>{survey.appearAs}</td>
          </tr>
          <tr>
            <th>Created</th>
            <td>
              <Date date={survey.createdAt} />
            </td>
          </tr>
          <tr>
            <th>Send Date</th>
            <td>
              <Date date={survey.sendDate} />
            </td>
          </tr>
          <tr>
            <th>Close Date</th>
            <td>
              <Date date={survey.closeDate} />
            </td>
          </tr>
          <tr>
            <th>Org</th>
            <td>
              <OrganizationLink docId={survey.orgId} />
            </td>
          </tr>
          <tr>
            <th>Send by DM</th>
            <td>
              <BoolCheck check={survey.dmAppeal} />
            </td>
          </tr>
          <tr>
            <th>Sent</th>
            <td>
              <BoolCheck check={survey.sent} />
            </td>
          </tr>
          <tr>
            <th>Deleted</th>
            <td>
              <BoolCheck check={survey.deleted} />
            </td>
          </tr>
          <tr>
            <th>Shared</th>
            <td>
              <BoolCheck check={survey.shared} />
            </td>
          </tr>
          <tr>
            <th>Results Type</th>
            <td>{survey.results}</td>
          </tr>
          <tr>
            <th>Post Results on Close</th>
            <td>
              <BoolCheck check={survey.postResultsOnClose} />
            </td>
          </tr>
          <tr>
            <th>Results Delivered</th>
            <td>{survey.resultsDelivered}</td>
          </tr>
          <tr>
            <th>Track ID</th>
            <td>
              <TrackLink docId={survey.trackId} />
            </td>
          </tr>
          <tr>
            <th>Reminders</th>
            <td>
              <span>
                {survey.reminderCount > 0
                  ? `${survey.reminderCount} every ${survey.reminderFrequency} ${survey.reminderFrequencyUnit}`
                  : "None"}
              </span>
            </td>
          </tr>
          <tr>
            <th>Workflow ID</th>
            <td>{survey.workflowId}</td>
          </tr>
          <tr>
            <th>Workflow Action ID</th>
            <td>{survey.workflowActionId}</td>
          </tr>
          <tr>
            <th>Workflow Event ID</th>
            <td>{survey.workflowEventId}</td>
          </tr>
          <tr>
            <th>Org Template ID</th>
            <td>{survey.orgTemplateId}</td>
          </tr>
          <tr>
            <th>Global Template ID</th>
            <td>{survey.globalTemplateId}</td>
          </tr>
        </tbody>
      </table>
      View raw json{" "}
      <ExpanderToggle
        expanded={expanded}
        toggle={() => setExpanded(!expanded)}
      />
      <ExpanderContent expanded={expanded}>
        <Code object={props.survey} />
      </ExpanderContent>
    </div>
  );
};
