import React, { useState } from "react";
import { PollWithDetails } from "../../../common/apiTypes";

import { BoolCheck } from "../../../components/BoolCheck";
import { Code } from "../../../components/Code";
import { Date } from "../../../components/Date";
import { OrganizationLink, TrackLink } from "../../../components/DocumentLink";
import { ExpanderContent, ExpanderToggle } from "../../../components/Expander";

type PollDetailsProps = {
  poll: PollWithDetails;
};

export const PollDetails: React.FunctionComponent<PollDetailsProps> = (
  props: PollDetailsProps,
) => {
  const poll = props.poll;
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <table className="table table-sm table-hover">
        <tbody>
          <tr>
            <th>ID</th>
            <td>{poll._id}</td>
          </tr>
          <tr>
            <th>Title</th>
            <td>{poll.title}</td>
          </tr>
          <tr>
            <th>Introduction</th>
            <td>{poll.appeal}</td>
          </tr>
          <tr>
            <th>Appear As</th>
            <td>{poll.appearAs}</td>
          </tr>
          <tr>
            <th>Created</th>
            <td>
              <Date date={poll.createdAt} />
            </td>
          </tr>
          <tr>
            <th>Assign Date</th>
            <td>
              <Date date={poll.assignDate} />
            </td>
          </tr>
          <tr>
            <th>Close Date</th>
            <td>
              {poll.question ? (
                <Date date={poll.question.closeDate} />
              ) : (
                "No question belongs to this polly. Something has gone wrong."
              )}
            </td>
          </tr>
          <tr>
            <th>Org</th>
            <td>
              <OrganizationLink docId={poll.orgId} />
            </td>
          </tr>
          <tr>
            <th>Send by DM</th>
            <td>
              <BoolCheck check={poll.strictVoting} />
            </td>
          </tr>
          <tr>
            <th>Sent</th>
            <td>
              <BoolCheck check={poll.sent} />
            </td>
          </tr>
          <tr>
            <th>Deleted</th>
            <td>
              <BoolCheck check={poll.deleted} />
            </td>
          </tr>
          <tr>
            <th>Shared</th>
            <td>
              <BoolCheck check={poll.shared} />
            </td>
          </tr>
          <tr>
            <th>Anonymity Level</th>
            <td>
              {poll.question
                ? poll.question.anonymityLevel
                : "No question belongs to this polly. Something has gone wrong."}
            </td>
          </tr>
          <tr>
            <th>Results Type</th>
            <td>{poll.results}</td>
          </tr>
          <tr>
            <th>Post Results on Close</th>
            <td>
              <BoolCheck check={poll.postResultsOnClose} />
            </td>
          </tr>
          <tr>
            <th>Results Delivered</th>
            <td>{poll.resultsDelivered}</td>
          </tr>
          <tr>
            <th>Track ID</th>
            <td>
              <TrackLink docId={poll.trackId} />
            </td>
          </tr>
          <tr>
            <th>Reminders</th>
            <td>
              <span>
                {poll.reminderCount && poll.reminderCount > 0
                  ? `${poll.reminderCount} every ${poll.reminderFrequency} ${poll.reminderFrequencyUnit}`
                  : "None"}
              </span>
            </td>
          </tr>
          <tr>
            <th>Workflow ID</th>
            <td>{poll.workflowId}</td>
          </tr>
          <tr>
            <th>Workflow Action ID</th>
            <td>{poll.workflowActionId}</td>
          </tr>
          <tr>
            <th>Workflow Event ID</th>
            <td>{poll.workflowEventId}</td>
          </tr>
          <tr>
            <th>Org Template ID</th>
            <td>{poll.orgTemplateId}</td>
          </tr>
          <tr>
            <th>Global Template ID</th>
            <td>{poll.globalTemplateId}</td>
          </tr>
        </tbody>
      </table>
      View raw json{" "}
      <ExpanderToggle
        expanded={expanded}
        toggle={() => setExpanded(!expanded)}
      />
      <ExpanderContent expanded={expanded}>
        <Code object={props.poll} />
      </ExpanderContent>
    </div>
  );
};
