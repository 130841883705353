import React from "react";

export type BoolCheckProps = {
  check?: boolean;
  colors?: boolean;
};

/**
 * Renders boolean as a check or x icon
 * @param props
 */
export const BoolCheck: React.FunctionComponent<BoolCheckProps> = (
  props: BoolCheckProps,
) => {
  const checkedColorClass = props.colors ? "text-info" : null;
  const uncheckedColorClass = props.colors ? "text-secondary" : null;
  return (
    <>
      {props.check ? (
        <i className={`fas fa-check-circle ${checkedColorClass}`} />
      ) : (
        <i className={`far fa-circle ${uncheckedColorClass}`} />
      )}
    </>
  );
};

BoolCheck.defaultProps = {
  colors: true,
};
