import React from "react";

import {
  FeatureGrants,
  AnyFeature,
  isNumericFeature,
} from "@pollyslack/hub/src/features/types";

type OverridesProps = {
  overrides: FeatureGrants;
};

export const Overrides: React.FunctionComponent<OverridesProps> = (
  props: OverridesProps,
) => {
  const { overrides } = props;
  if (!overrides) {
    return null;
  }
  return (
    <>
      {Object.keys(overrides)
        .map((k) =>
          isNumericFeature(k as AnyFeature)
            ? `${k}:${overrides[k as AnyFeature]}`
            : `${k}`,
        )
        .join(", ")}
    </>
  );
};
