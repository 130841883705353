import React, { useState } from "react";

import { Brief } from "@pollyslack/hub/src/types";

import { BoolCheck } from "../../../components/BoolCheck";
import { ExpanderToggle } from "../../../components/Expander";
import { ExpandableCodeRow } from "../../../components/Tables/ExpandableCodeRow";

type BriefDetailsProps = {
  brief: Brief;
};

export const BriefDetails: React.FunctionComponent<BriefDetailsProps> = (
  props: BriefDetailsProps,
) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <tr>
        <td>{props.brief.targetUser}</td>
        <td>Timestamp: {props.brief.timestamps}</td>
        <td>
          <BoolCheck check={props.brief.deleted} />
        </td>
        <td>{props.brief.dmChannel}</td>
        <td>
          <ExpanderToggle
            expanded={expanded}
            toggle={() => setExpanded(!expanded)}
          />
        </td>
      </tr>
      <ExpandableCodeRow expanded={expanded} colSpan={6} object={props.brief} />
    </>
  );
};
