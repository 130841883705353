import { Collection, Db, MongoClient } from "mongodb";

import { LifecycleEvent, Organization } from "../../types";
import { SubscriptionSchema } from "./schema";

let client: MongoClient;
let db: Db;
let orgs: Collection<Organization>;
let subs: Collection<SubscriptionSchema>;
let lce: Collection<LifecycleEvent>;

export function init(mongoClient: MongoClient): void {
  if (!client) {
    client = mongoClient;
    db = client.db();
    orgs = db.collection("organizations");
    subs = db.collection("subscriptions");
    lce = db.collection("lifecycleevents");
  }
}

/**
 * Meteor doesn't give us access to the mongodb client. That means we can't
 * use transactions.
 */
export function initForMeteor(meteorDb: Db): void {
  if (!db) {
    db = meteorDb;
    orgs = db.collection("organizations");
    subs = db.collection("subscriptions");
    lce = db.collection("lifecycleevents");
  }
}

export { client, orgs, subs, lce };
