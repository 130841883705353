import React from "react";
import { ToolbarButton } from "../../../components/ToolbarButton";

type LicenseAssignmentToolbarProps = {
  onAddAuthorClick?: () => void;
  onAddAuthorsByChannelClick?: () => void;
  onAddAuthorsByListClick?: () => void;
};

export const LicenseAssignmentToolbar: React.FunctionComponent<
  LicenseAssignmentToolbarProps
> = (props: LicenseAssignmentToolbarProps) => {
  return (
    <div className="btn-group mb-2">
      <ToolbarButton
        icon="fa-user-plus"
        title="Add Author"
        onClick={props.onAddAuthorClick}
      />
      <ToolbarButton
        icon="fa-user-plus"
        title="Add Authors By Channel"
        onClick={props.onAddAuthorsByChannelClick}
      />
      <ToolbarButton
        icon="fa-user-plus"
        title="Add Authors By Emails"
        onClick={props.onAddAuthorsByListClick}
      />
    </div>
  );
};
