import _ from "lodash";
import {
  FeatureGrants,
  SubscriptionSchema as Subscription,
  SubscriptionAmplitudeEventStatus,
  Tier,
} from "@pollyslack/hub/src/features/types";

import { post } from "./rest";

const root = "/subscriptions/";

export async function info(params: {
  id: string;
  $populate?: {
    authors?: boolean;
    billingAdmins?: boolean;
  };
}): Promise<any> {
  return await post(`${root}info`, params);
}

export async function infoBySlackEnterpriseId(params: {
  id: string;
}): Promise<any> {
  return await post(`${root}info.enterprise`, params);
}

export async function list(params: {
  orgId?: string;
  userId?: string;
}): Promise<Subscription[]> {
  return await post(`${root}list`, params);
}

export async function update(params: {
  id: string;
  tier: Tier;
  featureOverrides: FeatureGrants;
  isTrial: boolean;
  licenses: number;
  endDate?: string;
  stripeCustomerId?: string;
  stripeSubscriptionItemId?: string;
  billingAdminIds?: string[];
  memo?: string;
  amplitudeEventStatus?: SubscriptionAmplitudeEventStatus;
}): Promise<Subscription> {
  // remove 0, null and NaN numeric values
  const cleanedFeatures = _.omitBy(
    params.featureOverrides,
    (v) => !v || _.isNaN(v),
  );
  return await post(`${root}update`, {
    ...params,
    featureOverrides: cleanedFeatures,
  });
}

export async function deactivate(params: {
  id: string;
  memo?: string;
}): Promise<Subscription> {
  return await post(`${root}deactivate`, params);
}

export async function reactivate(params: {
  id: string;
  memo?: string;
}): Promise<Subscription> {
  return await post(`${root}reactivate`, params);
}

export async function addAuthor(params: {
  id: string;
  userId: string;
}): Promise<{ subscription?: Subscription; error?: string }> {
  return await post(`${root}addAuthor`, params);
}

export async function addAuthorsByChannel(params: {
  id: string;
  channelId: string;
}): Promise<{
  subscription?: Subscription;
  users?: number;
  oldSubCount: number;
  botCount: number;
  billableCount: number;
  missingCount: number;
  error?: string;
}> {
  return await post(`${root}addAuthorsByChannel`, params);
}

export async function addAuthorsByList(params: {
  id: string;
  emails: string;
}): Promise<{
  subscription?: Subscription;
  users?: number;
  oldSubCount: number;
  error?: string;
}> {
  return await post(`${root}addAuthorsByList`, params);
}

export async function removeAuthor(params: {
  id: string;
  userId: string;
}): Promise<{ subscription?: Subscription; error?: string }> {
  return await post(`${root}removeAuthor`, params);
}

export async function changeOwner(params: {
  id: string;
  userId: string;
}): Promise<Subscription> {
  return await post(`${root}changeOwner`, params);
}
