import React from "react";

import { list } from "../../services/api/Organization";

import { Organization } from "@pollyslack/hub/src/types";
import { DocumentSearchPage } from "../../components/DocumentSearchPage";
import { OrganizationsTable } from "./components/OrganizationsTable";

export class OrganizationsPage extends React.Component {
  public render() {
    return (
      <DocumentSearchPage
        typeName="Organization"
        placeholder="Organization ID, Slack Team ID, or Org Name"
        omitDeleted
        load={list}
        renderTable={(organizations: Organization[]) => (
          <OrganizationsTable organizations={organizations} />
        )}
      />
    );
  }
}
