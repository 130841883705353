interface BaseProps {
  label: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

interface InputProps extends BaseProps {
  type: "text";
  defaultValue?: string;
  placeholder?: string;
}

interface CheckboxProps extends BaseProps {
  type: "checkbox";
  id?: string;
  defaultChecked?: boolean;
}

export function FormGroup(props: InputProps | CheckboxProps): JSX.Element {
  const { label, type, disabled, onChange } = props;

  if (props.type == "text") {
    const { placeholder, defaultValue } = props;
    return (
      <div className="form-group">
        <label>{label}</label>
        <input
          type={type}
          className="form-control"
          placeholder={placeholder || ""}
          defaultValue={defaultValue || ""}
          disabled={disabled}
          onChange={onChange}
        />
      </div>
    );
  } else {
    const { id, defaultChecked } = props;
    return (
      <div className="form-group">
        <div className="form-check">
          <input
            type="checkbox"
            id={id}
            className="form-check-input"
            disabled={!!disabled}
            defaultChecked={!!defaultChecked}
            onChange={onChange}
          />
          <label htmlFor={id} className="form-check-label">
            {label}
          </label>
        </div>
      </div>
    );
  }
}
