import React from "react";

import { Permission } from "@pollyslack/hub/src/pacl/resources/permission";

type ResourceGrantProps = {
  id: string;
  permissions: Permission[];
};

export const ResourceGrant: React.FunctionComponent<ResourceGrantProps> = (
  props: ResourceGrantProps,
) => {
  const { id, permissions } = props;
  return (
    <tr>
      <td>{id}</td>
      <td>{permissions.join(", ")}</td>
    </tr>
  );
};
