// libraries
import * as _ from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { Organization, Post, Track, User } from "@pollyslack/hub/src/types";

import { Code } from "../../components/Code";
import MenuBar from "../../components/MenuBar";
import OrgView from "../../components/OrgView";
import PostOptionsView from "../../components/PostOptionsView";
import PostView from "../../components/PostView";
import { getOrgLabel } from "../../components/SlackOrg";
import { getUserLabel } from "../../components/SlackUser";
import TrackView from "../../components/TrackView";
import { listPosts, listTracks, searchUsers } from "../../services/api/User";
import { decryptTokens } from "../../services/api/User";

interface UserContextState {
  dataSource: string[];
  inputValue: string;
  tracks?: Track[];
  posts?: Post[];
  user?: User;
  users: User[];
  orgs: Organization[];
  errorText: string;
  postsTableOffset: number;
  postsPageSize: number;
  selectedPost?: Post;
}

export default class UserContext extends React.Component<
  any,
  UserContextState
> {
  static propTypes = {};
  static defaultProps = {};

  state: UserContextState = {
    dataSource: [],
    inputValue: "",
    orgs: [],
    user: undefined,
    users: [],
    tracks: [],
    posts: [],
    errorText: "",
    postsTableOffset: 0,
    postsPageSize: 10,
    selectedPost: undefined,
  };

  org(): Organization | undefined {
    if (this.state.user) {
      return _.find(this.state.orgs, { _id: this.state.user.profile.org });
    } else {
      return undefined;
    }
  }

  onNewRequest = async (searchTerm: string) => {
    const { users, orgs } = await searchUsers(searchTerm);
    const user = users.length > 0 ? users[0] : undefined;
    this.setState({
      users,
      user,
      orgs,
      tracks: [],
      posts: [],
      selectedPost: undefined,
    });
    await this.onUserSelected(user);
  };

  onUserSelected = async (user?: User) => {
    const error = user ? "" : "No matching user found";
    this.setState({
      errorText: error,
      user,
    });
    if (!user) {
      return;
    }
    const slackUserId = user.profile.user_id;
    const tracks = slackUserId ? await listTracks(slackUserId) : undefined;
    const posts = slackUserId ? await listPosts(slackUserId) : undefined;
    this.setState({
      tracks,
      posts,
    });
  };

  onPaginateHandler = (newOffset: number) => {
    this.setState({
      postsTableOffset: newOffset,
    });
  };

  onPostSelectionHandler = (selectedRows: number[] | string) => {
    if (typeof selectedRows !== "string" && selectedRows.length === 1) {
      this.setState({
        selectedPost:
          this.state.posts?.[selectedRows[0] + this.state.postsTableOffset],
      });
    }
  };

  renderUserSelect(): JSX.Element | null {
    if (this.state.users.length < 2) {
      return null;
    }
    const options = this.state.users.map((user) => {
      const org = _.find(this.state.orgs, { _id: user.profile.org });
      const userInfo = getUserLabel(user);
      const orgInfo = org && getOrgLabel(org);
      return {
        value: user._id,
        label: `${userInfo} [org: (${orgInfo})]`,
      };
    });
    const onChange = (option: { value: string; label: string }) => {
      const user = _.find(this.state.users, { _id: option.value });
      this.setState({ user });
      this.onUserSelected(user);
    };
    const value = this.state.user
      ? _.find(options, { value: this.state.user._id })
      : null;
    return (
      <div>
        <div>
          There are {this.state.users.length} matching users, select which you
          want to view:
        </div>
        <Select
          options={options}
          placeholder="Select a user..."
          value={value}
          onChange={onChange as unknown as any}
        />
      </div>
    );
  }

  render() {
    const org = this.org();
    return (
      <div>
        <MenuBar />
        <div className="p-4">
          <h1>Users</h1>
          <div className="row">
            <div className="input-group col-lg-4 col-md-6">
              <input
                className="form-control"
                placeholder={"Search email, Slack user_id, or _id"}
                onBlurCapture={(e) => this.onNewRequest(e.currentTarget.value)}
              />
              <div className="input-group-append">
                <button className="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
          {this.renderUserSelect()}
          {this.state.user ? (
            <div>
              <hr />
              <UserContextUserView user={this.state.user} />
              <br />
              <Code object={this.state.user} />
            </div>
          ) : (
            <div></div>
          )}
          {org ? (
            <div>
              <hr />
              <OrgView org={org} />
            </div>
          ) : (
            <div></div>
          )}

          {this.state?.tracks?.length && this.state.tracks.length > 0 ? (
            <div>
              <hr />
              <TrackView tracks={this.state.tracks} />
            </div>
          ) : (
            <div></div>
          )}

          {this.state?.posts?.length && this.state.posts.length > 0 ? (
            <div>
              <hr />
              <PostView
                posts={this.state.posts}
                offset={this.state.postsTableOffset}
                pageSize={this.state.postsPageSize}
                onPaginateHandler={this.onPaginateHandler}
              />
            </div>
          ) : (
            <div></div>
          )}
          {this.state.selectedPost ? (
            <PostOptionsView post={this.state.selectedPost} />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
}

interface UserContextUserViewProps {
  user: User;
}

interface UserContextUserViewState {
  userToken?: string;
  botToken?: string;
}

class UserContextUserView extends React.Component<
  UserContextUserViewProps,
  UserContextUserViewState
> {
  constructor(props: UserContextUserViewProps) {
    super(props);
    this.state = {
      userToken: undefined,
      botToken: undefined,
    };
    this.decryptTokens = this.decryptTokens.bind(this);
  }

  componentDidUpdate(prevProps: UserContextUserViewProps) {
    if (!prevProps || prevProps.user._id !== this.props.user._id) {
      this.setState({
        userToken: undefined,
        botToken: undefined,
      });
    }
  }

  async decryptTokens() {
    const tokens = await decryptTokens(this.props.user._id);
    this.setState({
      userToken: tokens.userToken,
      botToken: tokens.botToken,
    });
  }

  renderTokens(): JSX.Element | null {
    const hasToken = !!(
      _.get(this.props.user, "services.slack.accessToken") ||
      _.get(this.props.user, "services.slack.bot.bot_access_token")
    );
    if (!hasToken) {
      return null;
    }
    if (this.state.userToken || this.state.botToken) {
      return (
        <div>
          <div>User token: {this.state.userToken}</div>
          <div>Bot token: {this.state.botToken}</div>
        </div>
      );
    } else {
      return (
        <div>
          <button onClick={this.decryptTokens}>Decrypt Tokens</button>
        </div>
      );
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <Link to={`/user/${this.props.user._id}`}>
          {this.props.user.profile.name} ({this.props.user._id})
        </Link>
        {this.renderTokens()}
      </div>
    );
  }
}
