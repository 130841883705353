import * as _ from "lodash";

import { Organization, Team } from "@pollyslack/hub/src/types";
import { post } from "./rest";

const userRoot = "/teams/";

export async function searchTeams(
  search: string,
): Promise<{ teams: Team[]; orgs: Organization[] }> {
  search = _.trim(search);
  if (!search) {
    // Not a valid search, return nothing
    return { teams: [], orgs: [] };
  }
  return await post(`${userRoot}search`, { search });
}

export async function info(channelId: string): Promise<Team> {
  return await post(`${userRoot}info`, { channelId });
}

export async function list(params: {
  search: string;
  namePattern?: string;
  ignoreDms?: boolean;
}): Promise<Team[]> {
  return await post(`${userRoot}list`, params);
}
