import { PrebuiltWorkflowInfo } from "../prebuiltWorkflows";
import { ApiParameterValue, Omit, Platform, PollyDuration } from "../types";

export enum WorkflowEventType {
  userAddedToWorkspace = "userAddedToWorkspace",
  userAddedToChannel = "userAddedToChannel",
  emojiReaction = "emojiReaction",
  apiInvocation = "apiInvocation",
  slashCommandInvocation = "slashCommandInvocation",
  secondEmojiReaction = "secondEmojiReaction",
}

export enum WorkflowDynamicAudience {
  // "user" is the user directly involved in the event, e.g. whoever joined the workspace/channel,
  // the one who added the emoji reaction, or the one who used the slash command
  user = "user",
  user2 = "user2",
  channel = "channel",
  messagePoster = "messagePoster", // for emoji reactions, the user who posted the message the emoji was added to
  fromApiParameters = "fromApiParameters", // indicates audience values are passed in in API parameters
}

export enum ApiEventAttributeDataType {
  string = "string",
  number = "number",
  date = "date",
  boolean = "boolean",
  slackChannelIds = "slackChannelIds",
  channelNames = "channelNames",
  emails = "emails",
  slackUserIds = "slackUserIds",
}

export interface EventAttributeConfigSchema {
  showToAudience: boolean;
  displayAs: string;
}

export interface ApiEventAttributeConfigSchema
  extends EventAttributeConfigSchema {
  dataType: ApiEventAttributeDataType;
}

export interface WorkflowUserWorkspaceTriggerConfigSchema {
  eventType: WorkflowEventType.userAddedToWorkspace;
  eventAttributeConfig?: WorkflowUserWorkspaceEventAttributeConfig;
}

export interface WorkflowUserWorkspaceEventAttributeConfig {
  [WorkflowDynamicAudience.user]: EventAttributeConfigSchema;
}

export interface WorkflowUserChannelTriggerConfigSchema {
  eventType: WorkflowEventType.userAddedToChannel;
  channels?: string[];
  eventAttributeConfig?: WorkflowUserChannelEventAttributeConfig;
}

export interface WorkflowUserChannelEventAttributeConfig {
  [WorkflowDynamicAudience.user]: EventAttributeConfigSchema;
  [WorkflowDynamicAudience.channel]: EventAttributeConfigSchema;
}

export interface WorkflowEmojiReactionTriggerConfigSchema {
  eventType: WorkflowEventType.emojiReaction;
  emojis?: string[];
  channels?: string[];
  eventAttributeConfig?: WorfklowEmojiReactionEventAttributeConfig;
}

export interface WorfklowEmojiReactionEventAttributeConfig {
  [WorkflowDynamicAudience.user]: EventAttributeConfigSchema;
  [WorkflowDynamicAudience.channel]: EventAttributeConfigSchema;
  [WorkflowDynamicAudience.messagePoster]: EventAttributeConfigSchema;
}

export interface WorkflowSecondEmojiReactionTriggerConfigSchema {
  eventType: WorkflowEventType.secondEmojiReaction;
  emojis?: string[];
  secondEmojis?: string[];
  firstEmojiTimestamp?: string;
  channels?: string[];
  eventAttributeConfig?: WorkflowSecondEmojiEventAttributeConfig;
}

export interface WorkflowSecondEmojiEventAttributeConfig {
  [WorkflowDynamicAudience.user]: EventAttributeConfigSchema;
  [WorkflowDynamicAudience.user2]: EventAttributeConfigSchema;
  [WorkflowDynamicAudience.channel]: EventAttributeConfigSchema;
  [WorkflowDynamicAudience.messagePoster]: EventAttributeConfigSchema;
}

export interface WorkflowApiTriggerConfigSchema {
  eventType: WorkflowEventType.apiInvocation;
  apiParameters?: string[];
  eventAttributeConfig?: WorkflowApiEventAttributeConfig;
}

export interface WorkflowApiEventAttributeConfig {
  [x: string]: ApiEventAttributeConfigSchema;
}

export interface WorkflowSlashCommandTriggerConfigSchema {
  eventType: WorkflowEventType.slashCommandInvocation;
  channels?: string[];
  eventAttributeConfig?: WorkflowSlashCommandEventAttributeConfig;
}

export interface WorkflowSlashCommandEventAttributeConfig {
  [WorkflowDynamicAudience.user]: EventAttributeConfigSchema;
  [WorkflowDynamicAudience.channel]: EventAttributeConfigSchema;
}

export type WorkflowEventAttributeConfig =
  | WorkflowUserChannelEventAttributeConfig
  | WorkflowUserWorkspaceEventAttributeConfig
  | WorfklowEmojiReactionEventAttributeConfig
  | WorkflowSecondEmojiEventAttributeConfig
  | WorkflowApiEventAttributeConfig
  | WorkflowSlashCommandEventAttributeConfig;

export interface WorkflowActionSchema {
  _id: string;
  orgTemplateId: string;
  templateClonedFrom: {
    orgTemplateId: string;
    globalTemplateId: string;
  };
  staticAudience: string[];
  dynamicAudience: WorkflowDynamicAudience[];
  // For Triggers via API only, the names of API parameters containing the audience,
  // if dynamicAudience is set to WorkflowDynamicAudience.fromApiParameters
  apiAudienceParameters?: string[];
  delay: PollyDuration;
  sampleRate: number;
}

export type WorkflowTriggerConfigSchema =
  | WorkflowUserWorkspaceTriggerConfigSchema
  | WorkflowUserChannelTriggerConfigSchema
  | WorkflowEmojiReactionTriggerConfigSchema
  | WorkflowSecondEmojiReactionTriggerConfigSchema
  | WorkflowSlashCommandTriggerConfigSchema
  | WorkflowApiTriggerConfigSchema;

/** Database schema definition for Workflow */
export interface WorkflowSchema {
  _id: string;
  createdAt: Date;
  userId: string;
  orgId: string;
  title: string;
  active: boolean;
  deleted: boolean;
  triggerConfig: WorkflowTriggerConfigSchema;
  actions: WorkflowActionSchema[];
  prebuiltWorkflowInfo?: PrebuiltWorkflowInfo;
  /** Integration with google sheets. If set, this Poll should dump live results to this sheet id */
  googleSheetId?: string;
}

export interface WorkflowEventBaseSchema {
  _id: string;
  createdAt: Date;
  occurredAt: Date;
  orgId: string;
  eventType: WorkflowEventType;
  /** ids of workflows already triggered by this event */
  triggeredWorkflowIds: string[];
  /** Whether or not the event has been deleted */
  deleted?: boolean;
  platform?: Platform;
}

export interface WorkflowUserWorkspaceEventSchema
  extends WorkflowEventBaseSchema {
  eventType: WorkflowEventType.userAddedToWorkspace;
  /** Slack ID of the user added to the workspace */
  userId: string;
}

export interface WorkflowUserChannelEventSchema
  extends WorkflowEventBaseSchema {
  eventType: WorkflowEventType.userAddedToChannel;
  /** Slack ID of the user added to the channel */
  userId: string;
  /** Slack ID of the channel user is added to */
  channelId: string;
  /** If applicable, slack ID of the user who invited the other user to the channel */
  inviterId?: string;
}

export interface WorkflowEmojiReactionEventSchema
  extends WorkflowEventBaseSchema {
  eventType: WorkflowEventType.emojiReaction;
  /** Slack ID of the user who posted the emoji reaction */
  userId: string;
  /** Slack ID of the channel the message was in */
  channelId: string;
  /** Emoji reaction, without colons */
  emoji: string;
  /** Slack ID of the author of the message the emoji was a reaction to (may be null) */
  messagePoster?: string;
  /** timestamp of the message the emoji was about, in slack's timestamp format */
  messageTimestamp?: string;
  /** text of the original message */
  messageText?: string;
}

export interface WorkflowApiEventSchema extends WorkflowEventBaseSchema {
  eventType: WorkflowEventType.apiInvocation;
  /** Mongo ID of the workflow triggered by the api call */
  workflowId: string;
  /** Mongo ID of the token used to authorize the api call (optional until a migration is done) */
  tokenId?: string;
  /** Object filled with user-designated api parameters */
  apiParameters?: { [key: string]: ApiParameterValue };
}

export interface WorkflowSlashCommandEventSchema
  extends WorkflowEventBaseSchema {
  eventType: WorkflowEventType.slashCommandInvocation;
  /** Slack ID of the user who invoked the slash command */
  userId: string;
  /** Slack ID of the channel the slash command was invoked in */
  channelId: string;
  /** Mongo ID of the workflow triggered by the slash command */
  workflowId: string;
}

export interface WorkflowSecondEmojiReactionEventSchema
  extends WorkflowEventBaseSchema {
  eventType: WorkflowEventType.secondEmojiReaction;

  /** Slack ID of the user who posted the first reaction */
  userId: string;
  /** Slack ID of the user who posted the second reaction */
  userId2?: string;
  /** Slack ID of the channel the message was in */
  channelId: string;
  /** First emoji reaction, without colons */
  emoji: string;
  /** timestamp of the first emoji. The timestamp of the second emoji is stored in occurredAt */
  firstEmojiTimestamp?: Date;
  /** Second emoji reaction, without colons */
  emoji2?: string;
  /** Slack ID of the author of the message the emoji was a reaction to (may be null) */
  messagePoster?: string;
  /** timestamp of the message the emoji was about, in slack's timestamp format */
  messageTimestamp?: string;
  /** text of the original message */
  messageText?: string;
}

export type WorkflowEventSchema =
  | WorkflowUserWorkspaceEventSchema
  | WorkflowUserChannelEventSchema
  | WorkflowEmojiReactionEventSchema
  | WorkflowSecondEmojiReactionEventSchema
  | WorkflowApiEventSchema
  | WorkflowSlashCommandEventSchema;

/** The information needed to create or look up a WorkflowEvent */
type EventInfo<EventType extends WorkflowEventSchema> = Omit<
  EventType,
  "_id" | "createdAt" | "triggeredWorkflowIds"
>;
export type WorkflowUserWorkspaceEventInfo =
  EventInfo<WorkflowUserWorkspaceEventSchema>;
export type WorkflowUserChannelEventInfo =
  EventInfo<WorkflowUserChannelEventSchema>;
export type WorkflowEmojiReactionEventInfo =
  EventInfo<WorkflowEmojiReactionEventSchema>;
// prettier fails to break this line so disable eslint
// eslint-disable-next-line max-len
export type WorkflowSecondEmojiReactionEventInfo =
  EventInfo<WorkflowSecondEmojiReactionEventSchema>; // force prettier to break line
export type WorkflowApiEventInfo = EventInfo<WorkflowApiEventSchema>;
export type WorkflowSlashCommandEventInfo =
  EventInfo<WorkflowSlashCommandEventSchema>;

/** Information used to create or look-up a WorkflowEvent
 *
 * Omits `_id`, `createdAt`, and `triggeredWorkflowIds` properties
 */
export type WorkflowEventInfo =
  | WorkflowUserWorkspaceEventInfo
  | WorkflowUserChannelEventInfo
  | WorkflowEmojiReactionEventInfo
  | WorkflowSecondEmojiReactionEventInfo
  | WorkflowApiEventInfo
  | WorkflowSlashCommandEventInfo;
