import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  WorkflowEventSchema,
  WorkflowSchema as Workflow,
} from "@pollyslack/hub/src/schemas/workflow";

import { DocumentTabNav } from "../../components/DocumentTabNav";
import MenuBar from "../../components/MenuBar";
import { events, info } from "../../services/api/Workflow";
import { WorkflowDetails } from "./components/WorkflowDetails";
import { WorkflowEventsTable } from "./components/WorkflowEventsTable";

type TabNames = "workflow" | "events";
interface WorkflowPageContentState {
  workflow?: Workflow;
  workflowEvents?: WorkflowEventSchema[];

  currentTab: TabNames;
}

class WorkflowPageContent extends React.Component<
  RouteComponentProps<{ workflowId: string }>,
  WorkflowPageContentState
> {
  private tabs = [
    {
      id: "workflow",
      label: "Workflow",
      onClick: () => this.setTab("workflow"),
    },
    { id: "events", label: "Events", onClick: () => this.tabEvents() },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      workflow: undefined,
      workflowEvents: undefined,

      currentTab: "workflow",
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const workflowId = this.props.match.params.workflowId;
    const response = await info({
      workflowId,
    });
    this.setState({
      workflow: response.workflow,
    });
  }

  async loadEvents() {
    const workflowId = this.props.match.params.workflowId;
    const response = await events({
      workflowId,
    });
    this.setState({
      workflowEvents: response.workflowEvents,
    });
  }

  public setTab(tabName: TabNames) {
    this.setState({ currentTab: tabName });
  }

  public isTab(tabName: TabNames) {
    return this.state.currentTab === tabName;
  }

  async tabEvents() {
    this.setTab("events");
    await this.loadEvents();
  }

  public render() {
    return (
      <div>
        <MenuBar />
        <div className="p-4">
          <h1>Workflow Info</h1>
          <DocumentTabNav initialTab="workflow" tabs={this.tabs} />
          <div className="mt-4">
            {this.state.workflow && this.isTab("workflow") && (
              <>
                <WorkflowDetails workflow={this.state.workflow} />
              </>
            )}
            {this.state.workflowEvents && this.isTab("events") && (
              <>
                <WorkflowEventsTable
                  workflowEvents={this.state.workflowEvents}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const WorkflowPage = withRouter(WorkflowPageContent);
