import * as _ from "lodash";

import { Organization, Post, Track, User } from "@pollyslack/hub/src/types";

import { UserTokens } from "../../common/apiTypes";
import { post } from "./rest";
import { Tier } from "@pollyslack/hub/src/features/types";

const userRoot = "/users/";
export async function decryptTokens(id: string): Promise<UserTokens> {
  return await post(`${userRoot}decrypt_tokens`, { user_id: id });
}

export async function searchUsers(
  search: string,
): Promise<{ users: User[]; orgs: Organization[] }> {
  search = _.trim(search);
  if (!search) {
    // Not a valid search, return nothing
    return { users: [], orgs: [] };
  }
  return await post(`${userRoot}search`, { search });
}

export async function listTracks(userId: string): Promise<Track[]> {
  return await post(`${userRoot}list_tracks`, { user_id: userId });
}

export async function listPosts(userId: string): Promise<Post[]> {
  return await post(`${userRoot}list_posts`, { user_id: userId });
}

export async function info(userId: string): Promise<any> {
  return await post(`${userRoot}info`, { userId });
}

export async function list(params: {
  search: string;
  namePattern?: string;
  onlyPollyAdmins?: boolean;
  onlySlackAdmins?: boolean;
  onlyBots?: boolean;
}): Promise<User[]> {
  return await post(`${userRoot}list`, params);
}

export async function permissions(userId: string): Promise<any> {
  return await post(`${userRoot}permissions`, { userId });
}

export async function createSubscription(
  orgId: string,
  userId: string,
  tier: Tier,
  stripeCustomerId: string,
  memo: string,
  licenses?: number,
  expiration?: string,
): Promise<string> {
  const params = {
    org_id: orgId,
    user_id: userId,
    tier,
    stripe_customer_id: stripeCustomerId,
    memo,
    licenses,
    expiration,
  };
  return await post(`${userRoot}create_subscription`, params);
}

export async function transferOwnerShip(
  currentOwnerId: string,
  newOwnerId: string,
  options: {
    transferData?: boolean;
    transferSubscription?: { subscriptionId: string };
  },
  memo?: string,
): Promise<{ success?: string; failure?: string }> {
  const params = {
    currentOwnerId,
    newOwnerId,
    transferData: !!options.transferData,
    ...(options.transferSubscription
      ? {
          subscriptionId: options.transferSubscription.subscriptionId,
        }
      : {}),
    ...(memo ? { memo } : {}),
  };
  return await post(`${userRoot}transfer_ownership`, params);
}
