import { SubscriptionSchema } from "@pollyslack/hub/src/features";
import { Organization } from "@pollyslack/hub/src/types";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { BoolCheck } from "../../../components/BoolCheck";
import { Date } from "../../../components/Date";
import { OrganizationLink } from "../../../components/DocumentLink";
import { DocumentTable } from "../../../components/DocumentTable";
import { getEnterpriseSubscriptions } from "../../../services/api/Organization";

type OrganizationsTableProps = {
  organizations: Organization[];
};
export const OrganizationsTable: React.FunctionComponent<
  OrganizationsTableProps
> = (props: OrganizationsTableProps) => {
  const [orgEnterpriseSubs, setOrgEnterpriseSubs] = useState<
    SubscriptionSchema[]
  >([]);
  useEffect(() => {
    const slackEnterpriseIds = _.uniq(
      props.organizations
        .map((org) => org.settings.slackEnterpriseId)
        .filter((id) => !!id),
    );
    getEnterpriseSubscriptions({ slackEnterpriseIds })
      .then((subs) => {
        setOrgEnterpriseSubs(subs);
      })
      .catch(() => setOrgEnterpriseSubs([]));
  }, [props.organizations]);

  const properties = [
    {
      title: "Organization ID",
      render: (d: Organization) => <OrganizationLink docId={d._id} />,
    },
    { title: "Name", render: (d: Organization) => d.name },
    { title: "Domain", render: (d: Organization) => d.domain },
    {
      title: "Plan (Enterprise overwrite)",
      render: (d: Organization) => {
        const sub = orgEnterpriseSubs.find(
          (sub) => sub.slackEnterpriseId === d.settings.slackEnterpriseId,
        );
        return sub
          ? `${d.settings.subscription?.tier} (${sub.tier})`
          : d.settings.subscription?.tier;
      },
    },
    {
      title: "Created",
      render: (d: Organization) => <Date date={d.createDate} />,
    },
    {
      title: "Users",
      render: (d: Organization) => d.settings.cachedUserCount?.userCount,
    },
    {
      title: "Active",
      render: (d: Organization) => (
        <BoolCheck check={d.settings.slackBotTokenActive} />
      ),
    },
  ];
  return (
    <DocumentTable<Organization>
      properties={properties}
      objects={props.organizations}
    />
  );
};
