import React, { PropsWithChildren, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

import { useApi } from "src/hooks/useApi";

import MenuBar from "src/components/MenuBar";

import {
  DocumentSearch,
  DocumentSearchParams,
} from "../../components/DocumentSearch";

interface Props<T> {
  typeName: string;
  placeholder: string;
  omitDeleted?: boolean;
  load: (searchParams: DocumentSearchParams) => Promise<T[]>;
  renderTable: (docs: T[], reload: () => void) => React.ReactNode;
}

export function DocumentSearchPage<T extends { _id: string }>({
  typeName,
  placeholder,
  omitDeleted,
  load,
  renderTable,
}: PropsWithChildren<Props<T>>) {
  const [result, setSearch] = useApi<T[], DocumentSearchParams>(load);
  const [searchParams, setSearchParams] = useState({
    search: "",
    showDeleted: false,
  });
  const [debouncedSearch] = useDebounce(searchParams.search, 500);
  const [debouncedShowDeleted] = useDebounce(searchParams.showDeleted, 500);
  useEffect(() => {
    setSearch({ search: debouncedSearch, showDeleted: debouncedShowDeleted });
  }, [debouncedSearch, debouncedShowDeleted, setSearch]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col p-0">
          <MenuBar />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card mt-2">
            <div className="card-header">
              <h2 className="card-title">{typeName}s</h2>
              <DocumentSearch
                typeName={typeName}
                placeholder={placeholder}
                numFound={result.data?.length}
                omitDeleted={omitDeleted}
                onSubmit={setSearchParams}
                loading={result.isLoading}
              />
            </div>
          </div>
          <div className="card bg-dark mt-2">
            <div className="card-body">
              {result?.data &&
                renderTable(result.data, () => {
                  this.load();
                })}
              {result?.error && (
                <div className="alert alert-danger">
                  {result.error.toString()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
