import { PollWithDetails } from "../../common/apiTypes";
import { post } from "./rest";

const pollsRoot = "/polls/";

export async function info(params: {
  pollId: string;
  $populate?: {
    question?: boolean;
    teams?: boolean;
    author?: boolean;
    briefs?: boolean;
  };
}): Promise<any> {
  return await post(`${pollsRoot}info`, params);
}

export async function list(searchParams: {
  search?: string;
  showDeleted?: boolean;
}): Promise<PollWithDetails[]> {
  return await post(`${pollsRoot}list`, searchParams);
}

export async function markDeleted(pollId: string): Promise<any> {
  return await post(`${pollsRoot}markDeleted`, { pollId });
}
