import * as React from "react";

import { Post } from "@pollyslack/hub/src/types";

export type PostsViewProps = {
  posts: Post[];
  offset: number;
  pageSize: number;
  onPaginateHandler(newOffset: number): void;
};

const PostsView: React.StatelessComponent<PostsViewProps> = ({
  posts,
  offset,
  pageSize,
  onPaginateHandler,
}) => {
  const postsListView = posts.map((post: Post, idx: number) => {
    if (idx >= offset && idx < offset + pageSize) {
      return (
        <tr key={post._id}>
          <td>{post.title}</td>
          <td>
            {post.createdAt
              ? new Date(post.createdAt.toString()).toLocaleDateString() +
                " " +
                new Date(post.createdAt.toString()).toLocaleTimeString()
              : "N/A"}
          </td>
          <td>{post.votes}</td>
          <td>{post.deleted ? "yes" : "no"}</td>
        </tr>
      );
    }
    return null;
  });

  return (
    <div>
      <h4>Posts for this user:</h4>
      <table className="table">
        <thead>
          <tr>
            <td>Title</td>
            <td>Created On</td>
            <td>Vote Count</td>
            <td>Deleted</td>
          </tr>
        </thead>
        <tbody>{postsListView}</tbody>
        <tfoot>
          <tr>
            <td style={{ float: "right" }}>
              <button
                disabled={offset === 0}
                onClick={() => onPaginateHandler(offset - pageSize)}
              >
                {"<"}
              </button>
              <button
                disabled={offset + pageSize >= posts.length}
                onClick={() => onPaginateHandler(offset + pageSize)}
              >
                {">"}
              </button>
            </td>
            <td style={{ float: "right" }}>
              {Math.min(offset + 1, posts.length) +
                "-" +
                Math.min(offset + pageSize, posts.length) +
                " of " +
                posts.length}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default PostsView;
