import { mapNumberToEmoji } from "../../../utils";
import { questionTemplateMap } from "./questionTemplates";
import { PostOptionsTemplate, PollType, PostOptions } from "../../../types";

/**
 * Returns PostOptionsTemplate[]
 * @export
 * @param {PollType} questionTemplateId
 * @returns {PostOptionsTemplate[]}
 */
export function getPostOptionsTemplatesFromQuestionTemplate(
  questionTemplateId: PollType,
): PostOptionsTemplate[] {
  const questionTemplate = questionTemplateMap.get(questionTemplateId);
  if (!questionTemplate) {
    throw new Error(
      `Question Template not found with questionTemplateId: ${questionTemplateId}`,
    );
  }
  return questionTemplate.choices
    .sort((a, b) => a.orderIndex - b.orderIndex)
    .map((choice) => {
      return {
        _id: choice.orderIndex,
        orderIndex: choice.orderIndex,
        renderIndex: choice.renderIndex,
        value: choice.label,
      };
    });
}

export function getPostOptionsFromQuestionTemplate(
  questionTemplateId: PollType,
): PostOptions[] {
  let postOptionsTemplates: PostOptionsTemplate[];
  try {
    postOptionsTemplates =
      getPostOptionsTemplatesFromQuestionTemplate(questionTemplateId);
  } catch (e) {
    throw new Error(e);
  }

  return postOptionsTemplates.map((postOptionTemplate) => {
    return {
      ...postOptionTemplate,
      votes: 0,
      emoji: mapNumberToEmoji(postOptionTemplate.orderIndex + 1),
    };
  });
}

export function sortByRenderIndex(options: any[]) {
  return options.sort(
    (optionA, optionB) => optionA.renderIndex - optionB.renderIndex,
  );
}

export function sortByOrderIndex(options: any[]) {
  return options.sort(
    (optionA, optionB) => optionA.orderIndex - optionB.orderIndex,
  );
}
