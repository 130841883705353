import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import { User } from "@pollyslack/hub/src/types";

type TransferTrackModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (params: { userId: string }) => void;
  user?: User;
  onUserSearch?: (params: { userId: string }) => Promise<boolean>;
};
export const TransferTrackModal: React.FunctionComponent<
  TransferTrackModalProps
> = (props: TransferTrackModalProps) => {
  const [userId, setUserId] = useState("");
  return (
    <Modal isOpen={props.isOpen}>
      <div className="modal-header">
        <h5 className="modal-title">Transfer Track Ownership</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <div className="form-group">
          <label>New Owner</label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="mongo _id of the new owner"
              onChange={(e) => setUserId(e.currentTarget.value)}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                onClick={async () => {
                  if (!props.onUserSearch) {
                    return;
                  }
                  const success = await props.onUserSearch({ userId });
                  if (!success) {
                    alert("Can't find that user");
                  }
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        {props.user && (
          <div>
            <img
              src={props.user.profile.slackAvatarUrls?.image_24}
              className="mr-2"
              alt=""
            />
            <span>{props.user.profile.email}</span>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-light" onClick={props.onClose}>
          Close
        </button>
        <button
          className="btn btn-primary"
          onClick={() => props.onSubmit({ userId })}
        >
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
};
