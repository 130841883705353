import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { SubscriptionSchema as Subscription } from "@pollyslack/hub/src/features/types";
import { User } from "@pollyslack/hub/src/types";

import { DocumentTabNav } from "../../components/DocumentTabNav";
import MenuBar from "../../components/MenuBar";
import { info } from "../../services/api/Subscription";
import { LicenseAssignmentTab } from "./tabs/LicenseAssignmentTab";
import { SubscriptionDetailsTab } from "./tabs/SubscriptionDetailsTab";

type SubscriptionPageContentProps = RouteComponentProps<{
  subscriptionId: string;
}>;

const SubscriptionPageContent: React.FunctionComponent<
  SubscriptionPageContentProps
> = (props: SubscriptionPageContentProps) => {
  const [tab, setTab] = React.useState<"subscription" | "license-assignment">(
    "subscription",
  );
  const [reloadToggle, setReloadToggle] = React.useState(false);
  const [subscription, setSubscription] = React.useState<Subscription>();
  const [authors, setAuthors] = React.useState<User[]>();
  const [billingAdmins, setBillingAdmins] = React.useState<User[]>();

  React.useEffect(() => {
    const load = async () => {
      const subscriptionId = props.match.params.subscriptionId;
      const response = await info({
        id: subscriptionId,
        $populate: {
          authors: true,
          billingAdmins: true,
        },
      });
      setSubscription(response.subscription);
      setAuthors(response.authors);
      setBillingAdmins(response.billingAdmins);
    };
    load();
  }, [reloadToggle]);

  return (
    <div>
      <MenuBar />
      <div className="p-4">
        <h1>Subscription Info</h1>
        <DocumentTabNav
          initialTab={tab}
          tabs={[
            {
              id: "subscription",
              label: "Subscription",
              onClick: () => setTab("subscription"),
            },
            {
              id: "license-assignment",
              label: "License Assignment",
              onClick: () => setTab("license-assignment"),
            },
          ]}
        />
        <div className="mt-4">
          {subscription && (
            <>
              {tab === "subscription" && (
                <SubscriptionDetailsTab
                  subscription={subscription}
                  authors={authors}
                  billingAdmins={billingAdmins}
                  reload={async () => {
                    setReloadToggle(!reloadToggle);
                  }}
                />
              )}
              {tab === "license-assignment" && (
                <LicenseAssignmentTab
                  subscriptionId={subscription._id}
                  authors={authors ?? []}
                  reload={async () => {
                    setReloadToggle(!reloadToggle);
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const SubScriptionPage = withRouter(SubscriptionPageContent);
