import { PollType } from "../../types";

export enum QuestionType {
  NOMINAL = "nominal",
  ORDINAL = "ordinal",
  INTERVAL = "interval",
  RATIO = "ratio",
}

export interface BaseQuestionTemplateInterface {
  /* PollType will be renamed to something like questionTemplateId.
  It's useful to track what question templates are being used */
  id: PollType;
  /* 1_to_5, 1_to_10, five_emoji_rank, etc */
  userFacingId: string;
  /* What the question type is called on the front end like ":grinning: Emoji Rank" */
  userFacingLabel: string;
  /* General question type: nominal, ordinal, interval, or ratio */
  questionType: QuestionType;
  /* Choices to transform into postTemplates. _id is the dbOrderIndex and renderOrderIndex
   determines how the choices should be ordered for taking/slack results */
  choices: Choice[];
}

// Add other template question interfaces eventually
export type QuestionTemplateInterface = OrdinalQuestionTemplateInterface;

export interface OrdinalQuestionTemplateInterface
  extends BaseQuestionTemplateInterface {
  questionType: QuestionType.ORDINAL;
}

export interface Choice {
  label: string;
  orderIndex: number; // Index to determine the order of questions saved in the db
  renderIndex: number; // Index to sort by when showing questions for the taking experience
}
