import React from "react";

export type ToolbarButtonProps = {
  className?: string;
  title: string;
  icon: string;
  disabled?: boolean;
  onClick: () => void;
};

/**
 * Renders button with optional icon for a toolbar
 * @param props
 */
export const ToolbarButton: React.FunctionComponent<ToolbarButtonProps> = (
  props: ToolbarButtonProps,
) => (
  <button
    className={["btn", props.className ?? "btn-outline-secondary"].join(" ")}
    disabled={!!props.disabled}
    onClick={props.onClick}
  >
    {props.icon && (
      <i
        className={["fas", props.icon].join(" ")}
        style={props.title ? { marginRight: "0.5em" } : {}}
      />
    )}
    {props.title}
  </button>
);
