import * as React from "react";

const NotFound: React.StatelessComponent<any> = () => {
  return (
    <div>
      <h1>
        404 <small>Not Found</small>
      </h1>
    </div>
  );
};

export default NotFound;
