import * as React from "react";

import { Track } from "@pollyslack/hub/src/types";

export type TrackViewProps = {
  tracks: Track[];
};

// dates suck -> new Date(track.createdAt.toString()).toLocaleDateString()
const TrackView: React.StatelessComponent<TrackViewProps> = ({ tracks }) => {
  const trackListView = tracks.map((track: Track) => (
    <tr>
      <td>{track.title}</td>
      <td>
        {track.createdAt
          ? new Date(track.createdAt.toString()).toLocaleDateString() +
            " " +
            new Date(track.createdAt.toString()).toLocaleTimeString()
          : "?"}
      </td>
      <td>
        {track.nextBriefDate
          ? new Date(track.nextBriefDate.toString()).toLocaleDateString() +
            " " +
            new Date(track.nextBriefDate.toString()).toLocaleTimeString()
          : "?"}
      </td>
      <td>{track.active ? "yes" : "no"}</td>
      <td>{track.rotating ? "yes" : "no"}</td>
      <td>{track.strictVoting ? "yes" : "no"}</td>
    </tr>
  ));

  return (
    <div>
      <h4>Tracks for this user:</h4>
      <table className="table">
        <thead>
          <tr>
            <td>Title</td>
            <td>Created On</td>
            <td>Next Brief Date</td>
            <td>Active</td>
            <td>Rotating</td>
            <td>DM Polly</td>
          </tr>
        </thead>
        <tbody>{trackListView}</tbody>
      </table>
    </div>
  );
};

export default TrackView;
