import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Post, Survey, SurveyInstance, User } from "@pollyslack/hub/src/types";

import { UserDetails } from "src/components/UserDetails";
import { AudienceTable, TeamWithId } from "../../components/AudienceTable";
import { DocumentTabNav } from "../../components/DocumentTabNav";
import MenuBar from "../../components/MenuBar";
import { info, markDeleted } from "../../services/api/Survey";
import { InstanceDetails } from "./components/InstanceDetails";
import { QuestionsTable } from "./components/QuestionsTable";
import { SurveyDetails } from "./components/SurveyDetails";
import { SurveyTools } from "./components/SurveyTools";

interface SurveyPageContentState {
  survey?: Survey;
  author?: User;
  audience: TeamWithId[];
  questions: Post[];
  instances: SurveyInstance[];

  currentTab: "survey" | "questions" | "audience" | "instances" | "author";
}

class SurveyPageContent extends React.Component<
  RouteComponentProps<{ surveyId: string }>,
  SurveyPageContentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      survey: undefined,
      author: undefined,
      audience: [],
      questions: [],
      instances: [],

      currentTab: "survey",
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const surveyId = this.props.match.params.surveyId;
    const response = await info(surveyId).catch((e) => alert(e));
    this.setState({
      survey: response?.survey,
      author: response?.author,
      audience: response?.audience,
      questions: response?.questions,
      instances: response?.instances,
    });
  }

  markSurveyDeleted = async () => {
    try {
      if (!this.state.survey) {
        return;
      }
      const response = await markDeleted(this.state.survey._id);
      if (!response.ok) {
        throw new Error(response.message);
      }
      alert("Polly deletion initiated");
    } catch (e) {
      alert("Error: " + e);
    }
  };

  public numberOfSubmittedInstances(): number {
    if (this.state.instances) {
      const submitted = this.state.instances.filter((si) => si.submitted);
      return submitted.length;
    }
    return 0;
  }

  public numberOfDms(): number {
    if (this.state.instances) {
      const sent = this.state.instances.filter((si) => si.timestamp);
      return sent.length;
    }
    return 0;
  }

  public tabClass(tabName: string): string {
    if (this.state.currentTab === tabName) {
      return "nav-link active";
    } else {
      return "nav-link";
    }
  }

  public render() {
    return (
      <div>
        <MenuBar />
        <div className="p-4">
          <h1>Polly Info</h1>
          <DocumentTabNav
            initialTab="survey"
            tabs={[
              {
                id: "survey",
                label: "Polly",
                onClick: () => this.setState({ currentTab: "survey" }),
              },
              {
                id: "author",
                label: "Sender",
                onClick: () => this.setState({ currentTab: "author" }),
              },
              {
                id: "audience",
                label: "Audience",
                onClick: () => this.setState({ currentTab: "audience" }),
              },
              {
                id: "questions",
                label: "Questions",
                onClick: () => this.setState({ currentTab: "questions" }),
              },
              {
                id: "instances",
                label: "Instances",
                onClick: () => this.setState({ currentTab: "instances" }),
              },
            ]}
          />
          <div className="mt-4">
            <SurveyTools
              deleteDisabled={this.state.survey?.deleted}
              onDeleteClick={this.markSurveyDeleted}
            />
            {this.state.survey && this.state.currentTab === "survey" && (
              <SurveyDetails survey={this.state.survey} />
            )}

            {this.state.author && this.state.currentTab === "author" && (
              <UserDetails user={this.state.author} />
            )}
            {this.state.audience && this.state.currentTab === "audience" && (
              <AudienceTable teams={this.state.audience} />
            )}
            {this.state.questions && this.state.currentTab === "questions" && (
              <QuestionsTable questions={this.state.questions} />
            )}
            {this.state.instances && this.state.currentTab === "instances" && (
              <table className="table table-sm table-hover">
                <thead>
                  <tr>
                    <th>
                      Submitted ({this.numberOfSubmittedInstances()} /{" "}
                      {this.state.instances.length})
                    </th>
                    <th>User</th>
                    <th>
                      Message Sent ({this.numberOfDms()} /{" "}
                      {this.state.instances.length})
                    </th>
                    <th>Submitted At</th>
                    <th>Next Remind Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.instances.map((i) => {
                    return <InstanceDetails key={i._id} instance={i} />;
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const SurveyPage = withRouter(SurveyPageContent);
