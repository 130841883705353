import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  SubscriptionSchema as Subscription,
  Tier,
} from "@pollyslack/hub/src/features/types";
import { Survey, Track, User } from "@pollyslack/hub/src/types";

import { PollsTable } from "src/components/PollsTable";
import { SurveysTable } from "src/components/SurveysTable";
import { CreateSubscriptionModal } from "../../components/CreateSubscriptionModal";
import { DocumentTabNav } from "../../components/DocumentTabNav";
import MenuBar from "../../components/MenuBar";
import { SubscriptionsTable } from "../../components/SubscriptionsTable";
import { TracksTable } from "../../components/TracksTable";
import { UserActionsToolbar } from "../../components/UserActionsToolbar";
import { UserDetails } from "../../components/UserDetails";
import { setPollyAdmin } from "../../services/api/Organization";
import {
  createSubscription,
  transferOwnerShip as transferOwnerShipApi,
} from "../../services/api/User";
import { list as pollsList } from "../../services/api/Poll";
import { list } from "../../services/api/Survey";
import { list as tracksList } from "../../services/api/Track";
import { info, permissions } from "../../services/api/User";
import { PermissionsDetails } from "./components/PermissionsDetails";
import { list as subscriptionsList } from "../../services/api/Subscription";
import { toast } from "react-toastify";
import { PollWithDetails } from "../../common/apiTypes";

interface SurveyPageContentState {
  user?: User;
  permissions: any;
  surveys?: Survey[];
  polls?: PollWithDetails[];
  tracks?: Track[];
  subscriptions?: Subscription[];
  createSubscriptionModalOpen: boolean;
  currentTab:
    | "details"
    | "permissions"
    | "surveys"
    | "polls"
    | "tracks"
    | "subscriptions";
}

class UserPageContent extends React.Component<
  RouteComponentProps<{ userId: string }>,
  SurveyPageContentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      user: undefined,
      permissions: null,
      surveys: undefined,
      polls: undefined,
      tracks: undefined,
      subscriptions: undefined,
      currentTab: "details",
      createSubscriptionModalOpen: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const userId = this.props.match.params.userId;
    const response = await info(userId).catch((e) => alert(e));
    this.setState({
      user: response,
    });
  }

  async loadPermissions() {
    if (!this.state.permissions) {
      const response = await permissions(this.props.match.params.userId).catch(
        (e) => alert(e),
      );
      this.setState({ permissions: response });
    }
    this.setState({
      currentTab: "permissions",
    });
  }

  async loadSurveys(options?: { noCache?: boolean; skipTab?: boolean }) {
    if (!this.state.user) {
      alert("Wait until user loads");
    }
    if (!this.state.surveys || options?.noCache) {
      const response = await list({
        search: this.state.user?.profile.user_id,
      });
      this.setState({ surveys: response });
    }
    !options?.skipTab && this.setState({ currentTab: "surveys" });
  }

  async loadPolls(options?: { noCache?: boolean; skipTab?: boolean }) {
    if (!this.state.user) {
      alert("Wait until user loads");
    }
    if (!this.state.polls || options?.noCache) {
      const response = await pollsList({
        search: this.state.user?.profile.user_id,
      });
      this.setState({ polls: response });
    }
    !options?.skipTab && this.setState({ currentTab: "polls" });
  }

  async loadSubscriptions(options?: { noCache?: boolean; skipTab?: boolean }) {
    if (!this.state.subscriptions || options?.noCache) {
      const response = await subscriptionsList({
        userId: this.state.user?._id ?? "",
      });
      this.setState({ subscriptions: response });
    }
    !options?.skipTab && this.setState({ currentTab: "subscriptions" });
  }

  async loadTracks(options?: { noCache?: boolean; skipTab?: boolean }) {
    if (!this.state.user) {
      alert("Wait until user loads");
    }
    if (!this.state.tracks || options?.noCache) {
      const response = await tracksList({
        search: this.state.user?.profile.user_id,
      });
      this.setState({ tracks: response });
    }
    !options?.skipTab && this.setState({ currentTab: "tracks" });
  }

  async createSubscription(params: {
    plan: string;
    expiration?: Date;
    licenses: number;
    stripeCustomerId: string;
    memo: string;
  }) {
    const endDate = params.expiration
      ? params.expiration.toISOString()
      : undefined;
    await createSubscription(
      this.state.user?.profile.org ?? "",
      this.state.user?._id ?? "",
      params.plan as Tier,
      params.stripeCustomerId,
      params.memo,
      params.licenses,
      endDate,
    );
    const response = await subscriptionsList({
      userId: this.state.user?._id ?? "",
    });
    this.setState({
      subscriptions: response,
      createSubscriptionModalOpen: false,
    });
  }

  async grantAdminAccess() {
    await setPollyAdmin(
      this.state.user?.profile.org ?? "",
      this.state.user?._id ?? "",
      true,
    ).catch((e) => alert(e));
    alert("Granted admin access");
    await this.load();
  }

  async removeAdminAccess() {
    await setPollyAdmin(
      this.state.user?.profile.org ?? "",
      this.state.user?._id ?? "",
      false,
    ).catch((e) => alert(e));
    alert("Removed admin access");
    await this.load();
  }

  async transferOwnership(
    currentOwnerId: string,
    newOwnerId: string,
    options: {
      transferData?: boolean;
      transferSubscription?: { subscriptionId: string };
    },
    memo?: string,
  ) {
    try {
      const result = await transferOwnerShipApi(
        currentOwnerId,
        newOwnerId,
        options,
        memo,
      );
      result.success && toast.success(result.success);
      result.failure && toast.error(result.failure);
      await Promise.all([
        this.load(),
        this.loadSurveys({ noCache: true, skipTab: true }),
        this.loadPolls({ noCache: true, skipTab: true }),
        this.loadSubscriptions({ noCache: true, skipTab: true }),
        this.loadTracks({ noCache: true, skipTab: true }),
      ]);
      this.setState({ currentTab: "details" });
    } catch (e) {
      alert(e);
    }
  }

  public tabClass(tab: string): string {
    if (this.state.currentTab === tab) {
      return "nav-link active";
    }
    return "nav-link";
  }

  public render() {
    return (
      <div>
        <MenuBar />
        <div className="p-4">
          <h1>User Info</h1>
          {this.state.user && (
            <DocumentTabNav
              initialTab="details"
              tabs={[
                {
                  id: "details",
                  label: "Details",
                  onClick: () => this.setState({ currentTab: "details" }),
                },
                {
                  id: "subscriptions",
                  label: "Subscriptions",
                  onClick: () => this.loadSubscriptions(),
                },
                {
                  id: "permissions",
                  label: "Permissions",
                  onClick: () => this.loadPermissions(),
                },
                {
                  id: "surveys",
                  label: "Multi-Question Pollys",
                  onClick: () => this.loadSurveys(),
                },
                {
                  id: "polls",
                  label: "Pollys",
                  onClick: () => this.loadPolls(),
                },
                {
                  id: "tracks",
                  label: "Tracks",
                  onClick: () => this.loadTracks(),
                },
              ]}
            />
          )}
          <div className="mt-4">
            <CreateSubscriptionModal
              isOpen={this.state.createSubscriptionModalOpen}
              onClose={() =>
                this.setState({ createSubscriptionModalOpen: false })
              }
              onSubmit={(e) => this.createSubscription(e)}
            />
            {this.state.user && this.state.currentTab === "details" && (
              <>
                <UserActionsToolbar
                  userId={this.state.user._id}
                  isAdmin={this.state.user.profile.orgAdmin}
                  onGrantAdmin={() => this.grantAdminAccess()}
                  onRemoveAdmin={() => this.removeAdminAccess()}
                  onTransferOwnership={this.transferOwnership.bind(this)}
                />
                <UserDetails user={this.state.user} />
              </>
            )}
            {this.state.subscriptions &&
              this.state.currentTab === "subscriptions" && (
                <>
                  <button
                    className="btn btn-outline-success mb-2"
                    onClick={() =>
                      this.setState({ createSubscriptionModalOpen: true })
                    }
                  >
                    Create Subscription
                  </button>
                  <SubscriptionsTable
                    subscriptions={this.state.subscriptions}
                  />
                </>
              )}
            {this.state.permissions &&
              this.state.currentTab === "permissions" && (
                <PermissionsDetails
                  features={this.state.permissions.features}
                  resources={this.state.permissions.resources}
                />
              )}
            {this.state.surveys && this.state.currentTab === "surveys" && (
              <SurveysTable surveys={this.state.surveys} />
            )}
            {this.state.polls && this.state.currentTab === "polls" && (
              <PollsTable polls={this.state.polls} />
            )}
            {this.state.tracks && this.state.currentTab === "tracks" && (
              <TracksTable tracks={this.state.tracks} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const UserPage = withRouter(UserPageContent);
