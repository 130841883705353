import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

type ConfirmWithMemoModalProps = {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onSubmit: (params: { memo: string }) => void;
};

export const ConfirmWithMemoModal: React.FunctionComponent<
  ConfirmWithMemoModalProps
> = (props: ConfirmWithMemoModalProps) => {
  const { isOpen, title, onClose, onSubmit } = props;
  const [memo, setMemo] = useState("");
  useEffect(() => setMemo(""), [isOpen]);
  return (
    <Modal isOpen={isOpen}>
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <div className="form-group">
          <label>Memo</label>
          <input
            type="text"
            className="form-control"
            placeholder="optional memo"
            onChange={(e) => setMemo(e.currentTarget.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-light" onClick={onClose}>
          Close
        </button>
        <button className="btn btn-primary" onClick={() => onSubmit({ memo })}>
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
};
