import React, { useState } from "react";

import { ToolbarButton } from "../ToolbarButton";
import { TransferOwnershipModal } from "../TransferOwnershipModal";

type UserActionsToolbarProps = {
  userId: string;
  isAdmin: boolean;
  onGrantAdmin?: () => void;
  onRemoveAdmin?: () => void;
  onTransferOwnership: (
    currentOwnerId: string,
    newOwnerId: string,
    options: {
      transferData?: boolean;
      transferSubscription?: { subscriptionId: string };
    },
    memo?: string,
  ) => Promise<void>;
  size?: "sm";
};

export const UserActionsToolbar: React.FunctionComponent<
  UserActionsToolbarProps
> = (props: UserActionsToolbarProps) => {
  const parentClass =
    props.size === "sm" ? "btn-group btn-group-sm mb-2" : "btn-group mb-2";

  const [transferOwnershipModalOpen, setTransferOwnershipModalOpen] =
    useState(false);
  return (
    <div className={parentClass}>
      {props.isAdmin ? (
        <>
          {props.onRemoveAdmin && (
            <ToolbarButton
              className="btn-outline-danger"
              icon="fa-user-minus"
              title="Remove Polly Admin Access"
              onClick={props.onRemoveAdmin}
            />
          )}
        </>
      ) : (
        <>
          {props.onGrantAdmin && (
            <ToolbarButton
              className="btn-outline-success"
              icon="fa-user-plus"
              title="Grant Polly Admin Access"
              onClick={props.onGrantAdmin}
            />
          )}
        </>
      )}
      <ToolbarButton
        className="btn-outline-danger"
        icon="fa-exchange-alt"
        title="Transfer Data / Subscription"
        onClick={() => setTransferOwnershipModalOpen(true)}
      />
      <TransferOwnershipModal
        currentOwnerId={props.userId}
        isOpen={transferOwnershipModalOpen}
        onSubmit={props.onTransferOwnership}
        onClose={() => setTransferOwnershipModalOpen(false)}
      />
    </div>
  );
};
