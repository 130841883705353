import React, { useState } from "react";

import { EnterpriseGrid } from "@pollyslack/hub/src/types";

import { Code } from "../../../components/Code";
import { Date } from "../../../components/Date";
import { ExpanderContent, ExpanderToggle } from "../../../components/Expander";

type EnterprioseDetailsProps = {
  enterprise: EnterpriseGrid;
  decryptedSlackBotToken?: string;
  onTokenDecrypt?: () => void;
};

export const EnterpriseDetails: React.FunctionComponent<
  EnterprioseDetailsProps
> = (props: EnterprioseDetailsProps) => {
  const enterprise = props.enterprise;
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <table className="table table-sm table-hover">
        <tbody>
          <tr>
            <th>ID</th>
            <td>{enterprise._id}</td>
          </tr>
          <tr>
            <th>Slack Enterprise ID</th>
            <td>{enterprise.slackEnterpriseId}</td>
          </tr>
          <tr>
            <th>Name</th>
            <td>{enterprise.slackEnterpriseName}</td>
          </tr>
          <tr>
            <th>Created</th>
            <td>
              <Date date={enterprise.createdAt} />
            </td>
          </tr>
          <tr>
            <th>Bot ID</th>
            <td>{enterprise.slackBotUserId}</td>
          </tr>
          {(props.onTokenDecrypt !== null || props.decryptedSlackBotToken) && (
            <tr>
              <th>Bot Token</th>
              <td>
                {props.decryptedSlackBotToken ? (
                  <>{props.decryptedSlackBotToken}</>
                ) : (
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={props.onTokenDecrypt}
                  >
                    Decrypt Token
                  </button>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      View raw json{" "}
      <ExpanderToggle
        expanded={expanded}
        toggle={() => setExpanded(!expanded)}
      />
      <ExpanderContent expanded={expanded}>
        <Code object={props.enterprise} />
      </ExpanderContent>
    </div>
  );
};
