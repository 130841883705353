import * as React from "react";

import { User } from "@pollyslack/hub/src/types";

const SlackUser: React.StatelessComponent<{ user: User }> = ({ user }) => {
  return <span key={user._id}>{getUserLabel(user)}</span>;
};

export function getUserLabel(user: User) {
  const realName = user.profile.real_name;
  const displayName = user.profile.display_name;
  const name =
    displayName && displayName !== realName
      ? `@${realName} (${displayName})`
      : `@${realName}`;
  return `${name} (${user._id})`;
}

export default SlackUser;
