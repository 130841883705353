import React from "react";

export type SearchMatchesProps = {
  numFound?: number;
  typeName: string;
};

/**
 * Renders number of search results found
 * @param props
 */
export const SearchMatches: React.FunctionComponent<SearchMatchesProps> = (
  props: SearchMatchesProps,
) => {
  if (props.numFound === undefined || props.numFound === 0) {
    return <span>No matching {props.typeName}s found</span>;
  }
  return (
    <span>
      {props.numFound} matching {props.typeName}s found
    </span>
  );
};
