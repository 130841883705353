import {
  BooleanFeature,
  NumericFeature,
  NumericFeatureDefaultValues,
  UserFriendlyFeatureNames,
} from "./featureDefinitions";
export { BooleanFeature, NumericFeature };

export type AnyFeature = BooleanFeature | NumericFeature;

export function isBooleanFeature(
  feature: AnyFeature,
): feature is BooleanFeature {
  return feature in BooleanFeature;
}

export function isNumericFeature(
  feature: AnyFeature,
): feature is NumericFeature {
  return feature in NumericFeature;
}

/**
 * Map of feature grants.
 *
 * Not all features need be present, only those being set to a non-default value.
 *
 * Boolean features default to off and can only be turned on, not off, so no
 * need to include features that are not turned on.
 *
 * Numeric features are always turned on but to varying degrees. They should be
 * included if a non-default value is desired.
 */
export type FeatureGrants = {
  [key in BooleanFeature]?: true;
} & {
  [key in NumericFeature]?: number;
};

/**
 * Map of allowed features.
 *
 * All features will be present.
 * Boolean features may be either true or false.
 * Numeric features will have a value.
 */
export type AllowedFeatures = {
  [key in BooleanFeature]: boolean;
} & {
  [key in NumericFeature]: number;
};

/**
 * Returns an AllowedFeatures object with the free level set for all features.
 */
export function freeAllowedFeatures(): AllowedFeatures {
  // cast required due to missing boolean features
  const result: AllowedFeatures = {
    ...NumericFeatureDefaultValues,
  } as AllowedFeatures;
  for (const k in BooleanFeature) {
    result[k as BooleanFeature] = false;
  }
  return result;
}

/**
 * Takes a list of FeatureGrants and returns a single AllowedFeatures
 * containing the combined allowed features.
 */
export function MergeFeatureGrants(
  ...sources: FeatureGrants[]
): AllowedFeatures {
  // BooleanFeature default is false, but NumericFeature default must be
  // set from NumericFeatureDefaultValues
  const features = freeAllowedFeatures();

  if (sources) {
    sources.forEach((fmap) => {
      let k: AnyFeature;
      for (k in fmap) {
        if (isBooleanFeature(k)) {
          features[k] = true;
        } else if (isNumericFeature(k)) {
          features[k] = Math.max(features[k], fmap[k]);
        }
      }
    });
  }
  return features;
}

/**
 * Class to test if a feature is allowed by a combination of AllowedFeatureMaps.
 */
export class FeatureTester {
  constructor(readonly features: AllowedFeatures) {}
  /** A boolean feature is allowed if any source allows it. */
  can(k: BooleanFeature) {
    return this.features[k];
  }
  /** A numeric feature is allowed to extent of the most permissive source. */
  value(k: NumericFeature) {
    return this.features[k];
  }
}

/**
 * Returns a name for a feature suitable for use in UI.
 */
export function featureUserFriendlyName(k: AnyFeature): string {
  return UserFriendlyFeatureNames[k] ?? k;
}
