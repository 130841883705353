import _ from "lodash";
import * as React from "react";
import { ChangeEvent } from "react";
import { Button } from "reactstrap";

import { User } from "@pollyslack/hub/src/types";

import { ResumeTokenInfo } from "../../common/apiTypes";
import NavMenu from "../../components/NavMenu";
import Spinner from "../../components/PollySpinner";
import { getUserLabel } from "../../components/SlackUser";
import * as Service from "../../services/api/ResumeTokens";
import { ROOT_URL } from "../../common/constants";

interface ResumeTokenState {
  loading: boolean;
  tokens: ResumeTokenInfo[];
  users: Map<string, User>;
  idToAdd: string;
  newToken?: {
    userId: string;
    token: string;
  };
}

export default class ResumeToken extends React.Component<{}, ResumeTokenState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: true,
      tokens: [],
      users: new Map(),
      idToAdd: "",
      newToken: undefined,
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    this.setState({ loading: true });
    const { tokens, users } = await Service.list();
    for (const user of users ?? []) {
      this.state.users.set(user._id, user);
    }
    this.setState({
      tokens: tokens ?? [],
      users: this.state.users,
      loading: false,
    });
  }

  content(): JSX.Element {
    const rows = this.state.tokens.map((t) => {
      const user = this.state.users.get(t.userId);
      return (
        user && (
          <tr key={t.hashedToken}>
            <td>{getUserLabel(user)}</td>
            <td>{t.hashedToken}</td>
            <td>{t.createdBy}</td>
            <td>{t.when}</td>
            <td>
              <Button
                color="primary"
                onClick={() => {
                  this.deleteToken(t.userId, t.hashedToken);
                }}
              >
                Delete Resume Token
              </Button>
            </td>
          </tr>
        )
      );
    });
    return (
      <div>
        <h2>Create a token</h2>
        {this.addSection()}
        <h2>Existing tokens</h2>
        <Button color="primary" onClick={() => this.load()}>
          Refresh Table
        </Button>
        <table className="table mt-3">
          <thead>
            <tr>
              <td>User</td>
              <td>Hashed token</td>
              <td>Created by</td>
              <td>Date</td>
              <td>Delete</td>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }

  async add(): Promise<void> {
    const userId = this.state.idToAdd;
    const resp = await Service.create(userId);
    if (resp.error || !resp.token) {
      alert(resp.error);
    } else {
      this.setState({
        newToken: {
          token: resp.token,
          userId,
        },
      });
    }
    this.load();
  }

  async deleteToken(userId: string, hashedToken: string) {
    const resp = await Service.deleteToken(userId, hashedToken);
    if (resp.error) {
      alert(resp.error);
    }
    this.load();
  }

  addSection(): JSX.Element {
    if (this.state.newToken) {
      const code = `localStorage["Meteor.userId"] = "${this.state.newToken.userId}"; localStorage["Meteor.loginToken"] = "${this.state.newToken.token}"`;
      const bookmarkletCode = `javascript: (function () { ${code} })()`;
      const bookmarklet = (
        <a href={encodeURI(bookmarkletCode)}>
          Login as {this.state.newToken.userId}
        </a>
      );
      return (
        <div>
          <div className="row">
            <div className="col">
              To use this token, open an incognito window, go to
              {ROOT_URL}, and then load the token either using the javascript
              console or the bookmarklet.
              <br />
              <strong>
                When done, sign out of Polly and refresh the table to verify the
                token has been removed. Or remove it using the button in the
                table.
              </strong>
            </div>
          </div>
          <h5>Javascript</h5>
          <pre>{code}</pre>
          <h5>Bookmarklet (drag to bookmarks bar)</h5>
          <div>{bookmarklet}</div>
        </div>
      );
    } else {
      const value = this.state.idToAdd;
      const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
        this.setState({ idToAdd: _.trim(ev.target.value) });
      };
      const submit = () => {
        this.add();
      };
      return (
        <div className="row">
          <div className="input-group col-lg-4 col-md-6">
            <input
              className="form-control"
              type="text"
              placeholder="User._id"
              value={value}
              onChange={onChange}
            />
            <div className="input-group-append">
              <button className="btn btn-primary" onClick={submit}>
                Add
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
  render(): JSX.Element {
    const content = this.state.loading ? (
      <Spinner visible={true} />
    ) : (
      this.content()
    );
    return (
      <div>
        <NavMenu />
        <div className="p-4">
          <h1>Resume Tokens for {ROOT_URL}</h1>
          {content}
        </div>
      </div>
    );
  }
}
