import React from "react";
import { toast } from "react-toastify";

import {
  SubscriptionSchema as Subscription,
  SubscriptionAmplitudeEventStatus,
  Tier,
} from "@pollyslack/hub/src/features/types";
import { User } from "@pollyslack/hub/src/types";

import { SubscriptionActionsToolbar } from "../../../components/SubscriptionActionsToolbar";
import { TransferSubscriptionModal } from "../components/TransferSubscriptionModal";
import { UpdateSubscriptionModal } from "../../../components/UpdateSubscriptionModal";
import {
  changeOwner,
  deactivate,
  reactivate,
  update,
} from "../../../services/api/Subscription";
import { SubscriptionDetails } from "../components/SubscriptionDetails";
import { ConfirmWithMemoModal } from "../components/ConfirmWithMemoModal";

type SubscriptionDetailsTabProps = {
  subscription: Subscription;
  authors: User[];
  billingAdmins: User[];
  reload: () => Promise<void>;
};

export const SubscriptionDetailsTab: React.FunctionComponent<
  SubscriptionDetailsTabProps
> = (props: SubscriptionDetailsTabProps) => {
  const [updateModalOpen, setUpdateModalOpen] = React.useState(false);
  const [transferModalOpen, setTransferModalOpen] = React.useState(false);
  const [confirmDeactivateModalOpen, setConfirmDeactivateModalOpen] =
    React.useState(false);
  const [confirmReactivateModalOpen, setConfirmReactivateModalOpen] =
    React.useState(false);

  const active = props.subscription.active;
  return (
    <>
      <SubscriptionActionsToolbar
        onUpdateSubscriptionClick={
          active ? () => setUpdateModalOpen(true) : undefined
        }
        onTransferOwnershipClick={
          active ? () => setTransferModalOpen(true) : undefined
        }
        onDeactivateClick={
          active ? () => setConfirmDeactivateModalOpen(true) : undefined
        }
        onReactivateClick={
          active ? undefined : () => setConfirmReactivateModalOpen(true)
        }
      />
      <TransferSubscriptionModal
        isOpen={transferModalOpen}
        onClose={() => setTransferModalOpen(false)}
        onSubmit={async ({ userId }) => {
          try {
            await changeOwner({ id: props.subscription._id, userId });
            toast.success("Transfered ownership");
            await props.reload();
          } catch (e) {
            toast.error("Error: " + e);
          }
          setTransferModalOpen(false);
        }}
      />
      <UpdateSubscriptionModal
        subscription={props.subscription}
        isOpen={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
        onSubmit={async (params) => {
          try {
            const isTeamToBusinessUpdate =
              props.subscription.tier === Tier.TEAM &&
              params.tier === Tier.BUSINESS;
            await update({
              id: props.subscription._id,
              endDate: params.clearEndDate ? undefined : params.endDate,
              licenses: params.licenses,
              isTrial: params.isTrial,
              stripeCustomerId: params.stripeCustomerId,
              stripeSubscriptionItemId: params.stripeSubscriptionItemId,
              tier: params.tier as Tier,
              featureOverrides: params.featureOverrides,
              billingAdminIds: params.billingAdminIds,
              amplitudeEventStatus:
                params.tier === Tier.TEAM || params.tier === Tier.BUSINESS
                  ? isTeamToBusinessUpdate
                    ? SubscriptionAmplitudeEventStatus.UL_EVENT_PENDING_FOR_TEAM_TO_BUSINESS_PLAN_UPGRADE
                    : SubscriptionAmplitudeEventStatus.UL_EVENT_PENDING_FOR_TEAM_PLAN_PURCHASE
                  : null,
            });
            toast.success("Subscription Updated");
            await props.reload();
          } catch (e) {
            toast.error("Error: " + e);
          }
          setUpdateModalOpen(false);
        }}
      />
      <ConfirmWithMemoModal
        isOpen={confirmDeactivateModalOpen}
        title="Deactivate Subscription"
        onClose={() => {
          setConfirmDeactivateModalOpen(false);
        }}
        onSubmit={async (params) => {
          try {
            await deactivate({
              id: props.subscription._id,
              memo: params.memo,
            });
            toast.success("Deactivated Subscription");
            await props.reload();
          } catch (e) {
            toast.error("Error: " + e);
          }
          setConfirmDeactivateModalOpen(false);
        }}
      />
      <ConfirmWithMemoModal
        isOpen={confirmReactivateModalOpen}
        title="Reactivate Subscription"
        onClose={() => {
          setConfirmReactivateModalOpen(false);
        }}
        onSubmit={async (params) => {
          try {
            await reactivate({
              id: props.subscription._id ?? "",
              memo: params.memo,
            });
            toast.success("Reactivated Subscription");
            await props.reload();
          } catch (e) {
            toast.error("Error: " + e);
          }
          setConfirmReactivateModalOpen(false);
        }}
      />
      <SubscriptionDetails
        subscription={props.subscription}
        owner={props.billingAdmins?.[0]}
      />
    </>
  );
};
