import React from "react";

export type ExpanderContentProps = {
  expanded: boolean;
  children: React.ReactChild;
};

export const ExpanderContent: React.FunctionComponent<ExpanderContentProps> = (
  props: ExpanderContentProps,
) => {
  return <>{props.expanded ? <>{props.children}</> : null}</>;
};
