import React, { Fragment, useState } from "react";

import { Permission } from "@pollyslack/hub/src/pacl/resources/permission";
import { Resource } from "@pollyslack/hub/src/pacl/roles/common";
import { Expander } from "src/components/Expander";

import { ResourceGrant } from "./ResourceGrant";

type ResourceGrantsListProps = {
  grants: {
    id: string;
    type: Resource;
    permissions: Permission[];
  }[];
};

export const ResourceGrantsList: React.FunctionComponent<
  ResourceGrantsListProps
> = (props: ResourceGrantsListProps) => {
  const { grants } = props;
  const [expanded, setExpanded] = useState(false);
  if (grants.length === 0) {
    return null;
  }
  return (
    <Fragment>
      <tr>
        <td>
          <Expander
            label={`${grants[0].type} (${grants.length})`}
            expanded={expanded}
            toggle={() => setExpanded(!expanded)}
          />
        </td>
      </tr>
      {expanded && (
        <tr>
          <td>
            <table className="table table-sm table-hover">
              <tbody>
                {grants.map((g) => (
                  <ResourceGrant
                    key={g.id}
                    id={g.id}
                    permissions={g.permissions}
                  />
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </Fragment>
  );
};
