import React from "react";
import { ToolbarButton } from "../ToolbarButton";

type SubscriptionActionsToolbarProps = {
  onTransferOwnershipClick?: () => void;
  onUpdateSubscriptionClick?: () => void;
  onDeactivateClick?: () => void;
  onReactivateClick?: () => void;
  onCreateClick?: () => void;
};

export const SubscriptionActionsToolbar: React.FunctionComponent<
  SubscriptionActionsToolbarProps
> = (props: SubscriptionActionsToolbarProps) => {
  return (
    <div className="btn-group mb-2">
      {props.onTransferOwnershipClick && (
        <ToolbarButton
          icon="fa-exchange-alt"
          title="Transfer Ownership"
          onClick={props.onTransferOwnershipClick}
        />
      )}
      {props.onUpdateSubscriptionClick && (
        <ToolbarButton
          icon="fa-money-check-alt"
          title="Update Subscription"
          onClick={props.onUpdateSubscriptionClick}
        />
      )}
      {props.onDeactivateClick && (
        <ToolbarButton
          className="btn-outline-danger"
          icon="fa-times-circle"
          title="Deactivate Subscription"
          onClick={props.onDeactivateClick}
        />
      )}
      {props.onReactivateClick && (
        <ToolbarButton
          className="btn-outline-success"
          icon="fa-plus-circle"
          title="Reactivate Subscription"
          onClick={props.onReactivateClick}
        />
      )}
      {props.onCreateClick && (
        <ToolbarButton
          className="btn-outline-success"
          icon="fa-plus-circle"
          title="Create Subscription"
          onClick={props.onCreateClick}
        />
      )}
    </div>
  );
};
