import React from "react";

import { User } from "@pollyslack/hub/src/types";

import { UsersTable } from "../../../components/UsersTable";
import { ToolbarButton } from "../../../components/ToolbarButton";
import { toast } from "react-toastify";
import {
  addAuthor,
  addAuthorsByChannel,
  addAuthorsByList,
  removeAuthor,
} from "../../../services/api/Subscription";
import { LicenseAssignmentToolbar } from "./LicenseAssignmentToolbar";
import { AddAuthorModal } from "./AddAuthorModal";
import { AddAuthorByChannelModal } from "./AddAuthorByChannelModal";
import { AddAuthorsByListModal } from "./AddAuthorsByListModal";

type LicenseDetailsProps = {
  subscriptionId: string;
  authors: User[];
  reload: () => Promise<void>;
};

export const LicenseDetails: React.FunctionComponent<LicenseDetailsProps> = (
  props: LicenseDetailsProps,
) => {
  const [addAuthorModalOpen, setAddAuthorModalOpen] = React.useState(false);
  const [addChannelModalOpen, setAddChannelModalOpen] = React.useState(false);
  const [addListsModalOpen, setAddListsModalOpen] = React.useState(false);

  const [addedAuthorCount, setAddedAuthorCount] = React.useState(0);
  const [ignoredBotCount, setIgnoredBotCount] = React.useState(0);
  const [billableUserCount, setBillableUserCount] = React.useState(0);

  return (
    <div className="col">
      <LicenseAssignmentToolbar
        onAddAuthorClick={() => {
          setAddAuthorModalOpen(true);
        }}
        onAddAuthorsByChannelClick={() => {
          setAddChannelModalOpen(true);
        }}
        onAddAuthorsByListClick={() => {
          setAddListsModalOpen(true);
        }}
      />
      <AddAuthorModal
        isOpen={addAuthorModalOpen}
        onClose={() => setAddAuthorModalOpen(false)}
        onSubmit={async (params: { userId: string }) => {
          setAddAuthorModalOpen(false);
          const { subscription, error } = await addAuthor({
            id: props.subscriptionId,
            userId: params.userId,
          });
          await props.reload();
          if (subscription) {
            toast.success(`Added author: ${params.userId}`);
          } else {
            toast.error(error);
          }
        }}
      />
      <AddAuthorByChannelModal
        isOpen={addChannelModalOpen}
        onClose={() => setAddChannelModalOpen(false)}
        onSubmit={async (params: { channelId: string }) => {
          setAddChannelModalOpen(false);
          const {
            subscription,
            users,
            oldSubCount,
            billableCount,
            botCount,
            missingCount,
            error,
          } = await addAuthorsByChannel({
            id: props.subscriptionId,
            channelId: params.channelId,
          });

          if (subscription) {
            setAddedAuthorCount(subscription.authorIds.length - oldSubCount);
            setIgnoredBotCount(botCount);
            setBillableUserCount(billableCount);
            await props.reload();

            toast.success(`Found ${users} users from: ${params.channelId}`);
            if (missingCount > 0) {
              toast.warn(
                `${missingCount} users were not found in Polly database. Recommend doing an org sync and trying again.`,
                { autoClose: false },
              );
            }
          } else {
            toast.error(error);
          }
        }}
      />
      <AddAuthorsByListModal
        isOpen={addListsModalOpen}
        onClose={() => setAddListsModalOpen(false)}
        onSubmit={async (params: { emailList: string }) => {
          setAddListsModalOpen(false);
          const { subscription, oldSubCount, error } = await addAuthorsByList({
            id: props.subscriptionId,
            emails: params.emailList,
          });

          await props.reload();
          if (subscription) {
            const addedAuthorCount =
              subscription.authorIds.length - oldSubCount;
            setAddedAuthorCount(addedAuthorCount);
            if (addedAuthorCount === 0) {
              toast.error("No users were added from the provided list.");
            } else {
              toast.success(
                `Added ${addedAuthorCount}/${
                  params.emailList.split(",").length
                } users from list.`,
              );
            }
          } else {
            toast.error(error);
          }
        }}
      />
      <h5>Authors</h5>
      {addedAuthorCount > 0 && (
        <span className="text-muted">
          {addedAuthorCount} users added{" "}
          {ignoredBotCount > 0 && <>, {ignoredBotCount} bots ignored.</>}
          {billableUserCount > 0 && (
            <> {billableUserCount} billable users total in channel.</>
          )}
        </span>
      )}
      <UsersTable
        users={props.authors}
        colTitle={false}
        colOrg={false}
        colPollyAdmin={false}
        colSlackRole={false}
        action={(user: User) => (
          <ToolbarButton
            className="btn-outline-danger"
            title="Remove Author"
            icon="fa-trash"
            onClick={async () => {
              const { subscription, error } = await removeAuthor({
                id: props.subscriptionId,
                userId: user._id,
              });
              await props.reload();
              if (subscription) {
                toast.success(`Removed author: ${user._id}`);
              } else {
                toast.error(error);
              }
            }}
          />
        )}
      />
    </div>
  );
};
