import React, { useState } from "react";

import { WorkflowEventSchema } from "@pollyslack/hub/src/schemas/workflow";
import { Date } from "../../../components/Date";
import { ExpanderToggle } from "../../../components/Expander";
import { ExpandableCodeRow } from "../../../components/Tables/ExpandableCodeRow";

type WorkflowEventsTableProps = {
  workflowEvents: WorkflowEventSchema[];
};

export const WorkflowEventsTable: React.FunctionComponent<
  WorkflowEventsTableProps
> = (props: WorkflowEventsTableProps) => {
  return (
    <table className="table table-sm table-hover">
      <thead>
        <tr>
          <th>WorkflowEvent ID</th>
          <th>Created</th>
          <th>Occured</th>
          <th>Type</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.workflowEvents.map((we) => {
          return <WorkflowEventRow key={we._id} workflowEvent={we} />;
        })}
      </tbody>
    </table>
  );
};

const WorkflowEventRow: React.FunctionComponent<{
  workflowEvent: WorkflowEventSchema;
}> = (props: { workflowEvent: WorkflowEventSchema }) => {
  const we = props.workflowEvent;
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <tr key={we._id}>
        <td>{we._id}</td>
        <td>
          <Date date={we.createdAt} />
        </td>
        <td>
          <Date date={we.occurredAt} />
        </td>
        <td>{we.eventType}</td>
        <td>
          <ExpanderToggle
            expanded={expanded}
            toggle={() => setExpanded(!expanded)}
          />
        </td>
      </tr>
      <ExpandableCodeRow expanded={expanded} colSpan={5} object={we} />
    </>
  );
};
