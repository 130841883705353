import React from "react";

import { WorkflowSchema as Workflow } from "@pollyslack/hub/src/schemas/workflow";

import { BoolCheck } from "../BoolCheck";
import { Date } from "../Date";
import { OrganizationLink, UserLink, WorkflowLink } from "../DocumentLink";
import { DocumentTable } from "../DocumentTable";

type WorkflowsTableProps = {
  workflows: Workflow[];
};

export const WorkflowsTable: React.FunctionComponent<WorkflowsTableProps> = (
  props: WorkflowsTableProps,
) => {
  const properties = [
    {
      title: "Workflow ID",
      render: (d: Workflow) => <WorkflowLink docId={d._id} />,
    },
    {
      title: "Title",
      render: (d: Workflow) => d.title,
    },
    {
      title: "Org ID",
      render: (d: Workflow) => <OrganizationLink docId={d.orgId} />,
    },
    {
      title: "User ID",
      render: (d: Workflow) => <UserLink docId={d.userId} />,
    },
    {
      title: "Active",
      render: (d: Workflow) => <BoolCheck check={d.active} />,
    },
    {
      title: "Deleted",
      render: (d: Workflow) => <BoolCheck check={d.deleted} />,
    },
    {
      title: "Created",
      render: (d: Workflow) => <Date date={d.createdAt} />,
    },
    {
      title: "Trigger",
      render: (d: Workflow) => d.triggerConfig.eventType,
    },
    {
      title: "Templates",
      render: (d: Workflow) => d.actions.length,
    },
  ];

  return (
    <DocumentTable<Workflow>
      properties={properties}
      objects={props.workflows}
    />
  );
};
