import moment from "moment";
import React from "react";

/**
 * Render a link to the org detail page
 * @param props
 */
export const Date: React.FunctionComponent<{
  date?: Date;
  utc?: boolean;
}> = (props: { date?: Date; utc?: boolean }) => {
  if (!props.date) {
    return null;
  }
  const local = moment(props.date).format("M/D/YY h:mma");
  const utcTime = moment.utc(props.date).format("M/D/YY HH:mm:ss");

  return <span>{props.utc ? utcTime + " UTC" : local}</span>;
};
