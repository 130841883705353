import React, { useState } from "react";
import DatePicker from "react-datetime";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import "react-datetime/css/react-datetime.css";

type CreateEnterpriseSubscriptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (params: {
    expiration?: Date;
    stripeCustomerId: string;
    memo: string;
  }) => void;
};
export const CreateEnterpriseSubscriptionModal: React.FunctionComponent<
  CreateEnterpriseSubscriptionModalProps
> = (props: CreateEnterpriseSubscriptionModalProps) => {
  const [expiration, setExpiration] = useState<Date>();
  const [stripeCustomerId, setStripeCustomerId] = useState("");
  const [memo, setMemo] = useState("");
  return (
    <Modal isOpen={props.isOpen}>
      <div className="modal-header">
        <h5 className="modal-title">Create an Enterprise Subscription</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <div className="form-group">
          <label>
            Expiration <i>(optional)</i>
          </label>
          <DatePicker onChange={(e) => setExpiration(new Date(e.toString()))} />
        </div>
        <div className="form-group">
          <label>
            Stripe Customer ID <i>(optional)</i>
          </label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => setStripeCustomerId(e.currentTarget.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Memo <i>(optional)</i>
          </label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => setMemo(e.currentTarget.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-light" onClick={props.onClose}>
          Close
        </button>
        <button
          className="btn btn-primary"
          onClick={() =>
            props.onSubmit({
              expiration,
              stripeCustomerId,
              memo,
            })
          }
        >
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
};
