import React from "react";

import { Survey } from "@pollyslack/hub/src/types";
import { list } from "../../services/api/Survey";

import { DocumentSearchPage } from "../../components/DocumentSearchPage";
import { SurveysTable } from "../../components/SurveysTable";

export class SurveysPage extends React.Component {
  public render() {
    return (
      <DocumentSearchPage
        typeName="Survey"
        placeholder="Polly, Organization or Track ID, or User Slack ID"
        load={list}
        renderTable={(surveys: Survey[]) => <SurveysTable surveys={surveys} />}
      />
    );
  }
}
