import React, { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

export type DocumentTabNavProps = {
  tabs: Tab[];
  initialTab: string;
};

type Tab = {
  id: string;
  label: string;
  onClick?: (id: string) => void;
};

export const DocumentTabNav: React.FunctionComponent<DocumentTabNavProps> = (
  props: DocumentTabNavProps,
) => {
  const [tab, setTab] = useState(props.initialTab);
  return (
    <Nav tabs>
      {props.tabs.map((t) => {
        return (
          <NavItem key={t.id}>
            <NavLink
              href="#"
              className={tab === t.id ? "active" : ""}
              onClick={() => {
                setTab(t.id);
                if (t.onClick) {
                  t.onClick(t.id);
                }
              }}
            >
              {t.label}
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );
};
