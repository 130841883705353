import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Brief, Post, User } from "@pollyslack/hub/src/types";

import { Poll } from "@pollyslack/hub/src/models/models/Poll";
import { UserDetails } from "src/components/UserDetails";
import { AudienceTable, TeamWithId } from "../../components/AudienceTable";
import { DocumentTabNav } from "../../components/DocumentTabNav";
import MenuBar from "../../components/MenuBar";
import { info, markDeleted } from "../../services/api/Poll";
import { QuestionsTable } from "../Survey/components/QuestionsTable";
import { BriefDetails } from "./components/BriefDetails";
import { PollDetails } from "./components/PollDetails";
import { PollTools } from "./components/PollTools";

interface PollPageContentState {
  poll?: Poll;
  author?: User;
  audience: TeamWithId[];
  question?: Post;
  briefs: Brief[];
  currentTab: "poll" | "question" | "audience" | "author" | "briefs";
}

class PollPageContent extends React.Component<
  RouteComponentProps<{ pollId: string }>,
  PollPageContentState
> {
  private tabs = [
    {
      id: "poll",
      label: "Polly",
      onClick: () => this.setState({ currentTab: "poll" }),
    },
    {
      id: "author",
      label: "Author",
      onClick: () => this.setState({ currentTab: "author" }),
    },
    {
      id: "audience",
      label: "Audience",
      onClick: () => this.setState({ currentTab: "audience" }),
    },
    {
      id: "question",
      label: "Question",
      onClick: () => this.setState({ currentTab: "question" }),
    },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      poll: undefined,
      author: undefined,
      audience: [],
      question: undefined,
      briefs: [],
      currentTab: "poll",
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const pollId = this.props.match.params.pollId;
    const response = await info({
      pollId,
      $populate: {
        question: true,
        teams: true,
        author: true,
        briefs: true,
      },
    }).catch((e) => alert(e));
    if (
      response?.briefs?.length >= 1 &&
      !this.tabs.find((tab) => tab.id === "briefs")
    ) {
      this.tabs.push({
        id: "briefs",
        label: "Briefs",
        onClick: () => this.setState({ currentTab: "briefs" }),
      });
    }
    this.setState({
      poll: response?.poll,
      author: response?.author,
      audience: response?.teams,
      question: response?.question,
      briefs: response?.briefs,
    });
  }

  public numberOfDms(): number {
    if (this.state.briefs) {
      const sent = this.state.briefs.filter(
        (si) => si.timestamps?.length && si.timestamps.length >= 1,
      );
      return sent.length;
    }
    return 0;
  }

  markPollDeleted = async () => {
    try {
      if (!this.state.poll) {
        return;
      }
      const response = await markDeleted(this.state.poll._id);
      if (!response.ok) {
        throw new Error(response.message);
      }
      alert("Polly deletion initiated");
      await this.load();
    } catch (e) {
      alert("Error: " + e);
    }
  };

  public render() {
    return (
      <div>
        <MenuBar />
        <div className="p-4">
          <h1>Polly Info</h1>
          <DocumentTabNav initialTab="poll" tabs={this.tabs} />
          <div className="mt-4">
            <PollTools
              deleteDisabled={this.state.poll?.deleted}
              onDeleteClick={this.markPollDeleted}
            />
            {this.state.poll &&
              this.state.currentTab === "poll" &&
              this.state.question && (
                <PollDetails
                  poll={{ ...this.state.poll, question: this.state.question }}
                />
              )}

            {this.state.author && this.state.currentTab === "author" && (
              <UserDetails user={this.state.author} />
            )}
            {this.state.audience && this.state.currentTab === "audience" && (
              <AudienceTable teams={this.state.audience} />
            )}
            {this.state.question && this.state.currentTab === "question" && (
              <QuestionsTable questions={[this.state.question]} />
            )}
            {this.state.briefs && this.state.currentTab === "briefs" && (
              <table className="table table-sm table-hover">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>
                      Message Sent ({this.numberOfDms()} /{" "}
                      {this.state.briefs.length})
                    </th>
                    <th>Deleted</th>
                    <th>Dm Channel</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.briefs.map((i) => {
                    return <BriefDetails key={i._id} brief={i} />;
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const PollPage = withRouter(PollPageContent);
