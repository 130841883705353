import React from "react";

type ExpanderToggleProps = {
  expanded: boolean;
  toggle: () => void;
  label?: string;
};

export const ExpanderToggle: React.FunctionComponent<ExpanderToggleProps> = (
  props: ExpanderToggleProps,
) => {
  return (
    <button
      className="btn btn-link"
      onClick={props.toggle}
      style={{ paddingLeft: 0, paddingRight: 0 }}
    >
      {props.label === undefined ? "Expand" : props.label}{" "}
      {props.expanded ? (
        <i className="fa fa-caret-down fa-fw" />
      ) : (
        <i className="fa fa-caret-right fa-fw" />
      )}
    </button>
  );
};
