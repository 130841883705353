import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Organization } from "@pollyslack/hub/src/types";

import { DocumentTabNav } from "../../components/DocumentTabNav";
import MenuBar from "../../components/MenuBar";
import { info } from "../../services/api/Organization";

import { OrgTab } from "./tabs/OrgTab";
import { UsersTab } from "./tabs/UsersTab";
import { ChannelsTab } from "./tabs/ChannelsTab";
import { SubscriptionsTab } from "./tabs/SubscriptionsTab";
import { ActivityTab } from "./tabs/ActivityTab";
import { EnterpriseTab } from "./tabs/EnterpriseTab";
import { SendDMsTab } from "./tabs/SendDMsTab";
import { AnalyticsTab } from "./tabs/AnalyticsTab";

interface OrganizationPageContentState {
  organization?: Organization;

  currentTab:
    | "organization"
    | "admins"
    | "users"
    | "channels"
    | "subscriptions"
    | "activity"
    | "enterprise"
    | "sendDMs"
    | "analytics";
}

class OrganizationPageContent extends React.Component<
  RouteComponentProps<{ orgId: string }>,
  OrganizationPageContentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      organization: undefined,
      currentTab: "organization",
    };
  }

  componentDidMount() {
    this.loadOrg();
  }

  reload = async () => {
    await this.loadOrg();
  };

  async loadOrg() {
    try {
      const response = await info(this.props.match.params.orgId);
      this.setState({ organization: response });
    } catch (e) {
      alert("Error: " + e);
    }
  }

  public render() {
    const tabs = [
      {
        id: "organization",
        label: "Organization",
        onClick: () => this.setState({ currentTab: "organization" }),
      },
      {
        id: "users",
        label: "Users",
        onClick: () => this.setState({ currentTab: "users" }),
      },
      {
        id: "channels",
        label: "Channels",
        onClick: () => this.setState({ currentTab: "channels" }),
      },
      {
        id: "subscriptions",
        label: "Subscriptions",
        onClick: () => this.setState({ currentTab: "subscriptions" }),
      },
      {
        id: "activity",
        label: "Activity",
        onClick: () => this.setState({ currentTab: "activity" }),
      },
      {
        id: "enterprise",
        label: "Enterprise",
        onClick: () => this.setState({ currentTab: "enterprise" }),
      },
      {
        id: "sendDMs",
        label: "Send DMs",
        onClick: () => this.setState({ currentTab: "sendDMs" }),
      },
      {
        id: "analytics",
        label: "MAU",
        onClick: () => this.setState({ currentTab: "analytics" }),
      },
    ];

    return (
      <div>
        <MenuBar />
        <div className="p-4">
          <h1>Organization Info</h1>
          <DocumentTabNav initialTab="organization" tabs={tabs} />
          <div className="mt-4">
            {this.state.organization &&
              this.state.currentTab === "organization" && (
                <OrgTab
                  organization={this.state.organization}
                  reload={this.reload}
                />
              )}
            {this.state.organization && this.state.currentTab === "users" && (
              <UsersTab
                organization={this.state.organization}
                reload={this.reload}
              />
            )}
            {this.state.organization &&
              this.state.currentTab === "channels" && (
                <ChannelsTab
                  organization={this.state.organization}
                  reload={this.reload}
                />
              )}
            {this.state.organization &&
              this.state.currentTab === "subscriptions" && (
                <SubscriptionsTab organization={this.state.organization} />
              )}
            {this.state.organization &&
              this.state.currentTab === "activity" && (
                <ActivityTab organization={this.state.organization} />
              )}
            {this.state.organization &&
              this.state.currentTab === "enterprise" && (
                <EnterpriseTab organization={this.state.organization} />
              )}
            {this.state.organization && this.state.currentTab === "sendDMs" && (
              <SendDMsTab
                organization={this.state.organization}
                reload={this.reload}
              />
            )}
            {this.state.organization &&
              this.state.currentTab === "analytics" && (
                <AnalyticsTab
                  organization={this.state.organization}
                  reload={this.reload}
                />
              )}
          </div>
        </div>
      </div>
    );
  }
}

export const OrganizationPage = withRouter(OrganizationPageContent);
