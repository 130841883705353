import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

type AddAuthorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (params: { channelId: string }) => void;
};
export const AddAuthorByChannelModal: React.FunctionComponent<
  AddAuthorModalProps
> = (props: AddAuthorModalProps) => {
  const [channelId, setChannelId] = useState("");
  return (
    <Modal isOpen={props.isOpen}>
      <div className="modal-header">
        <h5 className="modal-title">Add Subscription Author</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <div className="form-group">
          <label>New Authors from Channel</label>
          <input
            type="text"
            className="form-control"
            placeholder="mongo _id of the channel"
            onChange={(e) => setChannelId(e.currentTarget.value)}
          />
        </div>
        <span>
          <small>
            Not all users may be added from a channel if the last sync has
            occured before new users have joined. It may be beneficial to sync
            the organization first then come back to add authors by channel.
          </small>
        </span>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-light" onClick={props.onClose}>
          Close
        </button>
        <button
          className="btn btn-primary"
          onClick={() => props.onSubmit({ channelId })}
        >
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
};
