import React from "react";
import { Organization } from "@pollyslack/hub/src/types";

import { OrgActionsToolbar } from "../components/OrgActionsToolbar";
import { OrgDetails } from "../components/OrgDetails";
import { UpdateSubscriptionModal } from "../../../components/UpdateSubscriptionModal";

import { setOrgTier, triggerSync } from "../../../services/api/Organization";

import { PlanParams } from "./PlanParams";
import { decryptOrgToken } from "./helpers";

type OrgTabProps = {
  organization: Organization;
  reload: () => Promise<void>;
};

export const OrgTab: React.FunctionComponent<OrgTabProps> = (
  props: OrgTabProps,
) => {
  const [setPlanModalOpen, setSetPlanModalOpen] = React.useState(false);
  const [decryptedToken, setDecryptedToken] = React.useState<string>();
  return (
    <>
      <OrgActionsToolbar
        orgId={props.organization._id ?? ""}
        onTriggerSyncClick={() => triggerOrgSync(props.organization._id)}
        onUpdateSubscriptionClick={() => setSetPlanModalOpen(true)}
      />
      <OrgDetails
        organization={props.organization}
        decryptedSlackBotToken={decryptedToken}
        onTokenDecrypt={async () =>
          setDecryptedToken(await decryptOrgToken(props.organization._id))
        }
      />
      <UpdateSubscriptionModal
        isOpen={setPlanModalOpen}
        subscription={props.organization.settings.subscription}
        onClose={() => setSetPlanModalOpen(false)}
        onSubmit={async (params) => {
          await setOrgPlan(props.organization._id, params);
          await props.reload();
          setSetPlanModalOpen(false);
        }}
      />
    </>
  );
};

async function triggerOrgSync(orgId: string) {
  try {
    const response = await triggerSync(orgId);
    alert(response);
  } catch (e) {
    alert("Error: " + e);
  }
}

async function setOrgPlan(orgId: string, params: PlanParams) {
  try {
    await setOrgTier({
      orgId,
      ...params,
    });
  } catch (e) {
    alert("Error: " + e);
  }
}
