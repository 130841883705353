import React from "react";

import { ToolbarButton } from "../../../components/ToolbarButton";

type OrgActionsToolbarProps = {
  orgId: string;
  onTriggerSyncClick: () => void;
  onUpdateSubscriptionClick: () => void;
  hideTriggerSync?: boolean;
  hideUpdateSubscription?: boolean;
};

export const OrgActionsToolbar: React.FunctionComponent<
  OrgActionsToolbarProps
> = (props: OrgActionsToolbarProps) => {
  return (
    <div className="btn-group mb-2">
      {!props.hideTriggerSync && (
        <ToolbarButton
          icon="fa-sync"
          title="Trigger Sync"
          onClick={props.onTriggerSyncClick}
        />
      )}
      {!props.hideUpdateSubscription && (
        <ToolbarButton
          icon="fa-money-check-alt"
          title="Update Subscription"
          onClick={props.onUpdateSubscriptionClick}
        />
      )}
    </div>
  );
};
