import {
  AnonymityLevel,
  ResultsDeliveryType,
} from "@pollyslack/types/src/common";
import { NudgeMessageType, PollType } from "../../../../types";
import { IPollySchema } from "@pollyai/api-client-server";

/**
 * Replaces breaking spaces in a jsx string to non breaking spaces
 */
export function sanitizeJSXString(view: string): string {
  return view.replace(/\u00A0/g, " ");
}

/**
 * Returns the generated message string for a provided nudge message id.
 * @param messageName the string name of the nudge message being built
 */
export function getNudgeMessageString(
  messageName: NudgeMessageType,
  daysLeftInTrial: number,
): string {
  switch (messageName) {
    case NudgeMessageType.INITIAL_TRIAL:
      return "Welcome to your 7-day Polly trial! You’ve just unlocked more responses, access to premium features like exports, advanced analytics, and so much more.";
    case NudgeMessageType.EFFECTIVE_MEETING:
      return "Have a meeting coming up? Send a quick polly in advance to help everyone save time, stay focused, and share what matters most.";
    case NudgeMessageType.TRIAL_CONVERSION:
      return `Your Polly trial ends in ${daysLeftInTrial} days. Did you know Polly users often see a 10x increase in response rates? Choose a plan to keep using premium features.`;
    case NudgeMessageType.TRY_EXPORTS:
      return "Have a bunch of important Polly data that you want to make better sense of? Try exporting your polly results – you can import your Polly data into your most important tools, visualize your data how you want, or slice and dice your data.";
    default:
      return "Have a meeting coming up? Send a quick polly in advance to help everyone save time, stay focused, and share what matters most.";
  }
}

/**
 * Returns boolean indicating whether to show the names of votes or not.
 * @param {AnonymityLevel} anonymityLevel to check if it's Public or Anonymized Results
 * @param {boolean} isAuthor whether the user viewing the results is author or not
 */

export function canShowVoterNames(
  anonymityLevel: AnonymityLevel,
  isAuthor: boolean,
  isSharing: boolean = false,
  isAuthorDM: boolean = false,
): boolean {
  if (
    isAuthorDM &&
    anonymityLevel !== AnonymityLevel.ANONYMOUS &&
    anonymityLevel !== AnonymityLevel.CONFIDENTIAL
  ) {
    return true;
  }

  if (isSharing && anonymityLevel !== AnonymityLevel.PUBLIC) {
    return false;
  }

  return (
    anonymityLevel === AnonymityLevel.PUBLIC ||
    (anonymityLevel === AnonymityLevel.ANONYMIZE_PARTICIPANT_RESULTS &&
      isAuthor)
  );
}

/**
 * Returns boolean indicating whether to show the usernames or not.
 * @param {AnonymityLevel} anonymityLevel to check if it's Anonymous, Confidential or Anonymized Results
 * @param {boolean} isAuthor whether the user viewing the results is author or not
 */

export function doNotShowUsername(
  anonymityLevel: AnonymityLevel,
  isAuthor: boolean,
  isSharing: boolean = false,
  isAuthorDM: boolean = false,
  isSharedWithUser: boolean = false,
): boolean {
  if (
    (isAuthorDM || isSharedWithUser) &&
    (anonymityLevel === AnonymityLevel.PUBLIC ||
      anonymityLevel === AnonymityLevel.ANONYMIZE_PARTICIPANT_RESULTS)
  ) {
    return false;
  }

  if (isSharing && anonymityLevel !== AnonymityLevel.PUBLIC) {
    return true;
  }

  return (
    anonymityLevel === AnonymityLevel.ANONYMOUS ||
    anonymityLevel === AnonymityLevel.CONFIDENTIAL ||
    (anonymityLevel === AnonymityLevel.ANONYMIZE_PARTICIPANT_RESULTS &&
      !isAuthor)
  );
}

export const isLimitedForWeb = (
  pollType: PollType,
  results: ResultsDeliveryType,
) => {
  return (
    (pollType !== "1to5" &&
      pollType !== "1to10" &&
      pollType !== "fivePoint" &&
      pollType !== "verbalBinary" &&
      pollType !== "nps" &&
      pollType !== "openEnded") ||
    results === ResultsDeliveryType.THREADED
  );
};

export const isOpenForumPolly = (polly: IPollySchema) => {
  return (
    polly.type === "qna" &&
    polly.targets.some(
      (t) =>
        t.__typename === "PollySlackChannelTargetSchema" &&
        t.type === "slack-channel" &&
        t.role === "moderation",
    )
  );
};
