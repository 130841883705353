import { tokens as orgTokens } from "../../../services/api/Organization";
import { tokens as enterpriseTokens } from "../../../services/api/Enterprise";

export async function decryptOrgToken(orgId: string): Promise<string> {
  try {
    return (await orgTokens(orgId)).slackBotToken;
  } catch (e) {
    alert("Error: " + e);
    return undefined;
  }
}

export async function decryptEnterpriseGridToken(
  slackEnterpriseId: string,
): Promise<string> {
  try {
    return (await enterpriseTokens(slackEnterpriseId)).slackBotToken;
  } catch (e) {
    alert("Error: " + e);
    return undefined;
  }
}
