import * as React from "react";

import {
  Collapse,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from "reactstrap";

import { Link } from "react-router-dom";

import { SignedInContext } from "../../SignedInContext";

export default class NavMenu extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <SignedInContext.Consumer>
        {({ signedIn, setSignedIn }) => (
          <Navbar dark color="dark" expand="md">
            <NavbarBrand to="/">
              <img
                src="https://static.polly.ai/img/logo-color.svg"
                width="35"
                height="25"
                alt=""
              />
              <span className="ml-2">Polly Admin</span>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {signedIn ? (
                  <>
                    <NavItem>
                      <Link className="nav-link" to="/users">
                        Users
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/organizations">
                        Organizations
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/enterprise">
                        Enterprise
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/polls">
                        Pollys
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/surveys">
                        Multi-Question Pollys
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/tracks">
                        Tracks
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/workflows">
                        Workflows
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link className="nav-link" to="/meteor-resume-tokens">
                        Meteor Tokens
                      </Link>
                    </NavItem>
                    <NavItem>
                      <a
                        className="nav-link"
                        href="/auth/logout"
                        onClick={() => {
                          setSignedIn(false);
                        }}
                      >
                        Sign Out
                      </a>
                    </NavItem>
                  </>
                ) : (
                  <>
                    <NavItem>
                      <a href="/auth/google" className="nav-link">
                        Sign In
                      </a>
                    </NavItem>
                  </>
                )}
              </Nav>
            </Collapse>
          </Navbar>
        )}
      </SignedInContext.Consumer>
    );
  }
}
