import { NODE_ENV } from "../../common/constants";

let ROOT = "";
if (NODE_ENV === "development") {
  ROOT = "http://localhost:9000";
}

export async function get(endpoint: string) {
  const resp = await fetch(ROOT + endpoint, {
    method: "GET",
    cache: "default",
    credentials: NODE_ENV === "development" ? "include" : "same-origin",
  });
  if (resp.status === 401) {
    throw new Error(`${resp.status}: ${resp.statusText}`);
  }
  const data = await resp.json();
  return data;
}

export async function post(endpoint: string, params?: any) {
  const resp = await fetch(ROOT + endpoint, {
    method: "POST",
    cache: "default",
    credentials: NODE_ENV === "development" ? "include" : "same-origin",
    body: JSON.stringify({ ...params, _csrf: getCsrfCookie() }),
    headers: {
      "content-Type": "application/json",
      Accept: "application/json",
    },
  });
  if (resp.status === 401) {
    throw new Error(`${resp.status}: ${resp.statusText}`);
  }
  const data = await resp.json();
  if (resp.status === 400 || resp.status === 404) {
    throw new Error(data.message || `${resp.status}: ${resp.statusText}`);
  }
  return data;
}

function getCsrfCookie() {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; XSRF-TOKEN=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return undefined;
}
