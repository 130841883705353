import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Track, TrackPoll, User } from "@pollyslack/hub/src/types";

import { PollsTable } from "src/components/PollsTable";
import { SurveysTable } from "src/components/SurveysTable";

import { AudienceTable, TeamWithId } from "../../components/AudienceTable";
import { DocumentTabNav } from "../../components/DocumentTabNav";
import MenuBar from "../../components/MenuBar";
import { TrackActionsToolbar } from "../../components/TrackActionsToolbar";
import { UserDetails } from "../../components/UserDetails";
import { info, transferOwnership, update } from "../../services/api/Track";
import { info as userInfo } from "../../services/api/User";
import { TrackDetails } from "./components/TrackDetails";
import { TransferTrackModal } from "./components/TransferTrackModal";
import { PollWithDetails, SurveyWithDetails } from "../../common/apiTypes";

interface TrackPageContentState {
  track?: Track;
  author?: User;
  audience: TeamWithId[];
  questions: TrackPoll[];
  surveys: SurveyWithDetails[];
  polls: PollWithDetails[];

  transferModalOpen: boolean;
  transferUser?: User;
  currentTab:
    | "track"
    | "questions"
    | "audience"
    | "surveys"
    | "author"
    | "polls";
}

class TrackPageContent extends React.Component<
  RouteComponentProps<{ trackId: string }>,
  TrackPageContentState
> {
  private tabs = [
    {
      id: "track",
      label: "Track",
      onClick: () => this.setState({ currentTab: "track" }),
    },
    {
      id: "author",
      label: "Author",
      onClick: () => this.setState({ currentTab: "author" }),
    },
    {
      id: "audience",
      label: "Audience",
      onClick: () => this.setState({ currentTab: "audience" }),
    },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      track: undefined,
      author: undefined,
      audience: [],
      questions: [],
      surveys: [],
      polls: [],

      transferUser: undefined,
      transferModalOpen: false,
      currentTab: "track",
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const trackId = this.props.match.params.trackId;
    const response = await info({
      trackId,
      $populate: {
        questions: true,
        teams: true,
        author: true,
        surveys: true,
        polls: true,
      },
    });
    if (
      response?.surveys?.length >= 1 &&
      !this.tabs.find((tab) => tab.id === "surveys")
    ) {
      this.tabs.push({
        id: "surveys",
        label: "Multi-Question Pollys",
        onClick: () => this.setState({ currentTab: "surveys" }),
      });
    }
    if (
      response?.polls?.length >= 1 &&
      !this.tabs.find((tab) => tab.id === "polls")
    ) {
      this.tabs.push({
        id: "polls",
        label: "Pollys",
        onClick: () => this.setState({ currentTab: "polls" }),
      });
    }
    this.setState({
      track: response.track,
      audience: response.teams,
      author: response.author,
      surveys: response.surveys,
      polls: response.polls,
    });
  }

  async searchUser(userId: string): Promise<boolean> {
    try {
      const user = await userInfo(userId);
      if (user) {
        this.setState({ transferUser: user });
        return true;
      } else {
        return false;
      }
    } catch (_e) {
      return false;
    }
  }

  async transfer(userId: string) {
    try {
      const response = await transferOwnership({
        trackId: this.state.track?._id ?? "",
        userId,
      });
      alert("Transfered track to " + userId);
      this.setState({
        transferModalOpen: false,
        transferUser: undefined,
        track: response.track,
      });
    } catch (e) {
      alert("Error: " + e);
    }
  }

  async update(params: { active?: boolean }) {
    try {
      const response = await update({
        trackId: this.state.track?._id ?? "",
        ...params,
      });
      alert("Updated track");
      this.setState({
        track: response.track,
      });
    } catch (e) {
      alert("Error: " + e);
    }
  }

  public tabClass(tabName: string): string {
    if (this.state.currentTab === tabName) {
      return "nav-link active";
    } else {
      return "nav-link";
    }
  }

  public render() {
    const active = this.state.track?.active;
    return (
      <div>
        <MenuBar />
        <div className="p-4">
          <h1>Track Info</h1>
          <DocumentTabNav initialTab="track" tabs={this.tabs} />

          <div className="mt-4">
            {this.state.track && this.state.currentTab === "track" && (
              <>
                <TransferTrackModal
                  isOpen={this.state.transferModalOpen}
                  onClose={() => this.setState({ transferModalOpen: false })}
                  onSubmit={({ userId }) => this.transfer(userId)}
                  onUserSearch={({ userId }) => this.searchUser(userId)}
                  user={this.state.transferUser}
                />
                <TrackActionsToolbar
                  trackId={this.state.track._id}
                  onTransferOwnershipClick={() =>
                    this.setState({ transferModalOpen: true })
                  }
                  onDeactivateClick={
                    active ? () => this.update({ active: false }) : undefined
                  }
                  onReactivateClick={
                    !active ? () => this.update({ active: true }) : undefined
                  }
                />
                <TrackDetails track={this.state.track} />
              </>
            )}
            {this.state.audience && this.state.currentTab === "audience" && (
              <AudienceTable teams={this.state.audience} />
            )}
            {this.state.author && this.state.currentTab === "author" && (
              <UserDetails user={this.state.author} />
            )}
            {this.state.surveys && this.state.currentTab === "surveys" && (
              <SurveysTable surveys={this.state.surveys} />
            )}
            {this.state.polls && this.state.currentTab === "polls" && (
              <PollsTable polls={this.state.polls} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const TrackPage = withRouter(TrackPageContent);
