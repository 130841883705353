import React from "react";

import { WorkflowSchema as Workflow } from "@pollyslack/hub/src/schemas/workflow";
import { list } from "../../services/api/Workflow";

import { DocumentSearchPage } from "../../components/DocumentSearchPage";
import { WorkflowsTable } from "../../components/WorkflowsTable";

export class WorkflowsPage extends React.Component {
  public render() {
    return (
      <DocumentSearchPage
        typeName="Workflow"
        placeholder="Workflow, Organization or User ID"
        load={list}
        renderTable={(workflows: Workflow[]) => (
          <WorkflowsTable workflows={workflows} />
        )}
      />
    );
  }
}
