import React from "react";

import { User } from "@pollyslack/hub/src/types";

import { DocumentSearchPage } from "../../components/DocumentSearchPage";
import { UsersTable } from "../../components/UsersTable";

import { list } from "../../services/api/User";

export class UsersPage extends React.Component {
  public render() {
    return (
      <DocumentSearchPage
        typeName="User"
        placeholder="Mongo ID, Slack ID, Email, or Org ID"
        load={list}
        renderTable={(users: User[]) => {
          return <UsersTable users={users} />;
        }}
      />
    );
  }
}
