import React from "react";

import { Post } from "@pollyslack/hub/src/types";

import { BoolCheck } from "../../../components/BoolCheck";
import { DocumentTable } from "../../../components/DocumentTable";

type QuestionsTableProps = {
  questions: Post[];
};

export const QuestionsTable: React.FunctionComponent<QuestionsTableProps> = (
  props: QuestionsTableProps,
) => {
  const properties = [
    { title: "Post ID", render: (d: Post) => d._id },
    { title: "Title", render: (d: Post) => d.title },
    { title: "Type", render: (d: Post) => d.pollType },
    { title: "Votes", render: (d: Post) => d.votes },
    {
      title: "Public Comments",
      render: (d: Post) => <BoolCheck check={d.commentsPublic} />,
    },
    {
      title: "Multi-Vote",
      render: (d: Post) => <BoolCheck check={d.multipleAnswer} />,
    },
    {
      title: "Add Options",
      render: (d: Post) => <BoolCheck check={d.allowAddingMcOptions} />,
    },
    {
      title: "Required",
      render: (d: Post) => <BoolCheck check={d.surveyQuestionRequired} />,
    },
  ];
  return (
    <DocumentTable<Post> properties={properties} objects={props.questions} />
  );
};
