import * as React from "react";

import { Organization } from "@pollyslack/hub/src/types";

const SlackOrg: React.StatelessComponent<{ org: Organization }> = ({ org }) => {
  return <span key={org._id}>{getOrgLabel(org)}</span>;
};

export function getOrgLabel(org: Organization) {
  return `${org.name} (${org._id}, ${org.settings.slackTeamId})`;
}

export default SlackOrg;
