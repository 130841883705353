import React, { Fragment, PropsWithChildren, useState } from "react";
import styled from "styled-components";
import { Code } from "../Code";
import { ExpanderContent, ExpanderToggle } from "../Expander";

const BorderlessTable = styled.table`
  display: block;
  thead tr th {
    border-top: none !important;
  }
`;

interface Property<T> {
  title: string;
  render: (d: T) => React.ReactNode;
  hide?: boolean;
  style?: React.CSSProperties;
}

interface Props<T> {
  objects: T[];
  properties: Property<T>[];
}

interface RowProps<T> {
  d: T;
  properties: Property<T>[];
}

function Row<T extends { _id: string }>({ d, properties }: RowProps<T>) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Fragment key={d._id}>
      <tr key={d._id}>
        <td>
          <ExpanderToggle
            expanded={expanded}
            label=" "
            toggle={() => setExpanded(!expanded)}
          />
        </td>
        {properties
          .filter((p) => !p.hide)
          .map((p, i) => {
            return <td key={i}>{p.render(d)}</td>;
          })}
      </tr>
      <ExpanderContent expanded={expanded} key={`x+${d._id}`}>
        <tr key={`x+${d._id}`}>
          <td colSpan={properties.length + 1}>
            <Code object={d} />
          </td>
        </tr>
      </ExpanderContent>
    </Fragment>
  );
}

export function DocumentTable<T extends { _id: string }>({
  objects,
  properties,
}: PropsWithChildren<Props<T>>) {
  return (
    <BorderlessTable className="table table-dark table-sm table-hover overflow-auto">
      <colgroup>
        {properties
          .filter((p) => !p.hide)
          .map((p, i) => (
            <col key={i} style={p.style} />
          ))}
      </colgroup>
      <thead>
        <tr className="borderless">
          <th />
          {properties
            .filter((p) => !p.hide)
            .map((p, i) => (
              <th key={i}>{p.title}</th>
            ))}
        </tr>
      </thead>
      <tbody>
        {objects.map((d) => (
          <Row properties={properties} d={d} key={d._id} />
        ))}
      </tbody>
    </BorderlessTable>
  );
}

export type DocumentTableProperty<T> = Property<T>;
