import React, { useState } from "react";

import { SurveyInstance } from "@pollyslack/hub/src/types";

import { ExpanderToggle } from "../../../components/Expander";
import { ExpandableCodeRow } from "../../../components/Tables/ExpandableCodeRow";

type InstanceDetailsProps = {
  instance: SurveyInstance;
};

export const InstanceDetails: React.FunctionComponent<InstanceDetailsProps> = (
  props: InstanceDetailsProps,
) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <tr className={props.instance.submitted ? "table-success" : ""}>
        <td>
          {props.instance.submitted ? (
            <i className="fa fa-check text-success mr-2" />
          ) : (
            <i className="fas fa-clock mr-2" />
          )}
        </td>
        <td>{props.instance.targetUser}</td>
        <td>{props.instance.timestamp}</td>
        <td>{props.instance.submittedAt}</td>
        <td>{props.instance.nextRemindDate}</td>
        <td>
          <ExpanderToggle
            expanded={expanded}
            toggle={() => setExpanded(!expanded)}
          />
        </td>
      </tr>
      <ExpandableCodeRow
        expanded={expanded}
        colSpan={6}
        object={props.instance}
      />
    </>
  );
};
