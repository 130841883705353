import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Track } from "@pollyslack/hub/src/types";
import { getCadenceDisplayText } from "@pollyslack/hub/src/utils";

import { BoolCheck } from "../../../components/BoolCheck";
import { Code } from "../../../components/Code";
import { Date } from "../../../components/Date";
import { ExpanderContent, ExpanderToggle } from "../../../components/Expander";

type TrackDetailsProps = {
  track: Track;
};

export const TrackDetails: React.FunctionComponent<TrackDetailsProps> = (
  props: TrackDetailsProps,
) => {
  const track = props.track;
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <table className="table table-sm table-hover">
        <tbody>
          <tr>
            <th>ID</th>
            <td>{track._id}</td>
          </tr>
          <tr>
            <th>Title</th>
            <td>{track.title}</td>
          </tr>
          <tr>
            <th>Questions</th>
            <td>{track.numTrackPolls}</td>
          </tr>
          <tr>
            <th>Introduction</th>
            <td>{track.appeal}</td>
          </tr>
          <tr>
            <th>Appear As</th>
            <td>{track.appearAs}</td>
          </tr>
          <tr>
            <th>Created</th>
            <td>
              <Date date={track.createdAt} />
            </td>
          </tr>
          <tr>
            <th>Next Send</th>
            <td>
              <Date date={track.nextBriefDate} />
            </td>
          </tr>
          <tr>
            <th>Cadence</th>
            <td>{getCadenceDisplayText(track.briefCadence)}</td>
          </tr>
          <tr>
            <th>Org</th>
            <td>
              <Link to={`/organization/${track.orgId}`}>{track.orgId}</Link>
            </td>
          </tr>
          <tr>
            <th>Send by DM</th>
            <td>
              <BoolCheck check={track.dmAppeal} />
            </td>
          </tr>
          <tr>
            <th>Active</th>
            <td>
              <BoolCheck check={track.active} />
            </td>
          </tr>
          <tr>
            <th>Deleted</th>
            <td>
              <BoolCheck check={track.deleted} />
            </td>
          </tr>
          <tr>
            <th>Shared</th>
            <td>
              <BoolCheck check={track.shared} />
            </td>
          </tr>
          <tr>
            <th>Results Type</th>
            <td>{track.results}</td>
          </tr>
          <tr>
            <th>Post Results on Close</th>
            <td>
              <BoolCheck check={track.postResultsOnClose} />
            </td>
          </tr>
          <tr>
            <th>Reminders</th>
            <td>
              <span>
                {track.reminderCount && track.reminderCount > 0
                  ? `${track.reminderCount} every ${track.reminderFrequency} ${track.reminderFrequencyUnit}`
                  : "None"}
              </span>
            </td>
          </tr>
          <tr>
            <th>Org Template ID</th>
            <td>{track.orgTemplateId}</td>
          </tr>
          <tr>
            <th>Global Template ID</th>
            <td>{track.globalTemplateId}</td>
          </tr>
        </tbody>
      </table>
      View raw json{" "}
      <ExpanderToggle
        expanded={expanded}
        toggle={() => setExpanded(!expanded)}
      />
      <ExpanderContent expanded={expanded}>
        <Code object={props.track} />
      </ExpanderContent>
    </div>
  );
};
