import { SurveyWithDetails } from "../../common/apiTypes";

import { post } from "./rest";

const surveyRoot = "/surveys/";

export async function info(id: string): Promise<any> {
  return await post(`${surveyRoot}info`, { id });
}

export async function list(params: {
  search?: string;
  showDeleted?: boolean;
}): Promise<SurveyWithDetails[]> {
  return await post(`${surveyRoot}list`, params);
}

export async function markDeleted(id: string): Promise<any> {
  return await post(`${surveyRoot}markDeleted`, { id });
}
