import * as React from "react";

import { Post, PostOptions } from "@pollyslack/hub/src/types";

export type PostOptionsViewProps = {
  post: Post;
};

const PostOptionsView: React.StatelessComponent<PostOptionsViewProps> = ({
  post,
}) => {
  const postOptionsListView = post.options.map((postOptions: PostOptions) => {
    return (
      <div>
        <li>
          {postOptions.value.toString()}
          {postOptions.votes !== null && postOptions.votes !== undefined
            ? postOptions.votes + " vote(s)"
            : "0 vote(s)"}
        </li>
      </div>
    );
  });
  return (
    <div>
      <h3>{post.title}</h3>
      <ul>
        <li>Total Votes: {post.votes}</li>
        <li>
          Created At:{" "}
          {post.createdAt
            ? new Date(post.createdAt.toString()).toLocaleDateString() +
              " " +
              new Date(post.createdAt.toString()).toLocaleTimeString()
            : "N/A"}
        </li>
        {postOptionsListView}
      </ul>
    </div>
  );
};

export default PostOptionsView;
