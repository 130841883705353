import React, { useState } from "react";

import { WorkflowSchema as Workflow } from "@pollyslack/hub/src/schemas/workflow";

import { BoolCheck } from "../../../components/BoolCheck";
import { Code } from "../../../components/Code";
import { Date } from "../../../components/Date";
import { OrganizationLink, UserLink } from "../../../components/DocumentLink";
import { ExpanderContent, ExpanderToggle } from "../../../components/Expander";

type WorkflowDetailsProps = {
  workflow: Workflow;
};

export const WorkflowDetails: React.FunctionComponent<WorkflowDetailsProps> = (
  props: WorkflowDetailsProps,
) => {
  const workflow = props.workflow;
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <table className="table table-sm table-hover">
        <tbody>
          <tr>
            <th>ID</th>
            <td>{workflow._id}</td>
          </tr>
          <tr>
            <th>Title</th>
            <td>{workflow.title}</td>
          </tr>
          <tr>
            <th>Org</th>
            <td>
              <OrganizationLink docId={workflow.orgId} />
            </td>
          </tr>
          <tr>
            <th>User ID</th>
            <td>
              <UserLink docId={workflow.userId} />
            </td>
          </tr>
          <tr>
            <th>Created</th>
            <td>
              <Date date={workflow.createdAt} />
            </td>
          </tr>
          <tr>
            <th>Trigger</th>
            <td>{workflow.triggerConfig.eventType}</td>
          </tr>
          <tr>
            <th>Templates</th>
            <td>{workflow.actions.length}</td>
          </tr>
          <tr>
            <th>Active</th>
            <td>
              <BoolCheck check={workflow.active} />
            </td>
          </tr>
          <tr>
            <th>Deleted</th>
            <td>
              <BoolCheck check={workflow.deleted} />
            </td>
          </tr>
        </tbody>
      </table>
      View raw json{" "}
      <ExpanderToggle
        expanded={expanded}
        toggle={() => setExpanded(!expanded)}
      />
      <ExpanderContent expanded={expanded}>
        <Code object={props.workflow} />
      </ExpanderContent>
    </div>
  );
};
