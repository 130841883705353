import React, { useEffect, useState } from "react";

import { SearchMatches } from "./components/SearchMatches";

export interface DocumentSearchParams {
  search: string;
  showDeleted: boolean;
}

type DocumentSearchProps = {
  typeName: string;
  placeholder: string;
  omitDeleted?: boolean;
  loading: boolean;
  numFound: number;
  onSubmit: (searchParams: DocumentSearchParams) => void;
};

export const DocumentSearch: React.FunctionComponent<DocumentSearchProps> = (
  props: DocumentSearchProps,
) => {
  const [search, setSearch] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);
  const { onSubmit } = props;
  useEffect(() => {
    onSubmit({ search, showDeleted });
  }, [search, showDeleted, onSubmit]);

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fas fa-search" />
              </span>
            </div>{" "}
            <input
              className="form-control"
              type="text"
              placeholder={props.placeholder}
              value={search}
              onChange={(e) => {
                setSearch(e.currentTarget.value);
              }}
            />
          </div>
        </div>
        {!props.omitDeleted && (
          <div className="col">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="showDeleted"
                onChange={(e) => setShowDeleted(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="showDeleted">
                Show Deleted
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col">
          <div className="my-2">
            {!search ? (
              <>Enter an ID to get started</>
            ) : props.loading ? (
              <>
                <div
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                />
                Loading...
              </>
            ) : (
              <SearchMatches
                numFound={props.numFound}
                typeName={props.typeName}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
