import * as React from "react";

import { Organization } from "@pollyslack/hub/src/types";

import { OrganizationLink } from "../DocumentLink";

export type OrgViewProps = {
  org: Organization;
};

const OrgView: React.StatelessComponent<OrgViewProps> = ({ org }) => {
  return (
    <ul>
      <h4>Organization Info</h4>
      <li>
        <OrganizationLink docId={org._id ?? ""}>
          <b>Name:</b> {org.name}
        </OrganizationLink>
      </li>
      <li>
        <b>Create Date:</b>{" "}
        {org.createDate
          ? new Date(org.createDate.toString()).toLocaleDateString() +
            " " +
            new Date(org.createDate.toString()).toLocaleTimeString()
          : "?"}
      </li>
      <li>
        <b>Bot token:</b>{" "}
        {org.settings.slackBotTokenActive ? "Active" : "Inactive"}
      </li>
      <li>
        <b>Legacy:</b> {org.settings.legacyBotToken ? "yes" : "no"}
      </li>
      <li>
        <b>allow_message_deletion:</b>{" "}
        {org.settings.allow_message_deletion
          ? org.settings.allow_message_deletion
          : "true"}
      </li>
      <li>
        <b>Slack Plan:</b> {org.settings.plan}
      </li>
      <li>
        <b>Cached User Count:</b>{" "}
        {org.settings.cachedUserCount
          ? org.settings.cachedUserCount.userCount
          : "N/A"}
      </li>
      <li>
        <b>Last Counted On:</b>{" "}
        {org.settings.cachedUserCount
          ? new Date(
              org.settings.cachedUserCount.date.toString(),
            ).toLocaleDateString() +
            " " +
            new Date(
              org.settings.cachedUserCount.date.toString(),
            ).toLocaleTimeString()
          : "N/A"}
      </li>
    </ul>
  );
};

export default OrgView;
