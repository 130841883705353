import * as React from "react";

type State = {
  signedIn: boolean;
  setSignedIn: (signedIn: boolean) => void;
};

export const SignedInContext = React.createContext<State>({
  signedIn: false,
  setSignedIn: undefined,
});
