import {
  QuestionType,
  QuestionTemplateInterface,
} from "../../../schemas/questions/questionTypes";
import { PollType } from "../../../types";

/**
 * List of question templates that Polly supports.
 * Each question template has information needed to save options and questionType to the db
 * Order index is the order that options are saved in the db and shown in results
 * Render index is the order that options are shown in the authoring/taking experience
 */
const QuestionTemplates: {
  [pollType in PollType]?: QuestionTemplateInterface;
} = {
  [PollType.RANK_FIVE_EMOJI]: {
    id: PollType.RANK_FIVE_EMOJI,
    userFacingId: "five_emoji_rank",
    userFacingLabel: ":grinning: Emoji rank",
    questionType: QuestionType.ORDINAL,
    choices: [
      { orderIndex: 0, renderIndex: 4, label: ":cry: Awful" },
      { orderIndex: 1, renderIndex: 3, label: ":slightly_frowning_face: Bad" },
      { orderIndex: 2, renderIndex: 2, label: ":neutral_face: Neutral" },
      { orderIndex: 3, renderIndex: 1, label: ":slightly_smiling_face: Good" },
      { orderIndex: 4, renderIndex: 0, label: ":heart_eyes: Great" },
    ],
  },
};

/**
 * Map to access question templates via PollType
 * @type {Readonly<Map<PollType,QuestionTemplateInterface>>}
 */
export const questionTemplateMap = Object.freeze(
  new Map<PollType, QuestionTemplateInterface>([
    [PollType.RANK_FIVE_EMOJI, QuestionTemplates[PollType.RANK_FIVE_EMOJI]],
  ]),
);
