import { PlatformUserId } from "./platform/PollyPlatformClient";

// Every how many minutes we will check orgs to see if they need sync triggered
export const ORG_SYNC_CHECK_FREQUENCY_MINUTES = 10;

// We will trigger sync on an org if it's more than this many hours
export const ORG_SYNC_FREQUENCY_HOURS = 48;

// Delay before hard delete takes place for deletes from Enterprise Admin Management
export const HARD_DELETE_DELAY_HOURS = 48;
// Meteor cron job frequencies
export const QUEUE_PINGS_CRON_JOB_FREQUENCY_MINUTES = 1; // ping the various queues

export const SEND_POLLYS_CRON_JOB_FREQUENCY_MINUTES = 5; // send out all tracks and scheduled pollys (polls and surveys)
export const POLL_AND_SURVEY_REMINDERS_CRON_JOB_FREQUENCY_MINUTES = 1; // send out survey reminders
export const EXPORTS_CRON_JOB_FREQUENCY_MINUTES = 5; // send out exports
export const CLOSE_POLLS_AND_SURVEYS_CRON_JOB_FREQUENCY_MINUTES = 5; // check polls and surveys which need to be closed
export const PENDING_SQS_MESSAGES_CRON_JOB_FREQUENCY_MINUTES = 5; // send out delayed SQS messages stored in mongo
export const TRIAL_NUDGE_MESSAGES_CRON_JOB_FREQUENCY_MINUTES = 5; // send out trial nudge messages if it's time
export const NPS_APPEAL_CRON_JOB_FREQUENCY_MINUTES = 15; // send out nps survey appeal to qualitifed users via DMs

export const LOG_MEMORY_CRON_JOB_FREQUENCY_MINUTES = 1;

/**
 * System user to represent internal processes not triggered by users for activity logging
 */
export const SYSTEM_USER = "SYSTEM";

/** Default text that shows when a user is leaving a comment on a question */
export const DEFAULT_COMMENT_PROMPT_TEXT = "Please elaborate on your answer";

/** Default time zone */
export const DEFAULT_TIME_ZONE = "America/Los_Angeles";

export const SLACK_MAX_SECTION_TEXT = 3000;
export const SLACK_MAX_FIELD_TEXT = 2000;
export const SLACK_MAX_CONTEXT_TEXT = 255;
export const MAX_RESPONSE_LENGTH = SLACK_MAX_SECTION_TEXT - 100;
export const MAX_QUESTION_OPTION_LENGTH = SLACK_MAX_FIELD_TEXT - 150;
export const MAX_QUESTION_OPTIONS = 92;
export const MIN_QUESTION_TITLE_LENGTH = 3;

export const MAX_SECTION_LENGTH = SLACK_MAX_FIELD_TEXT - 100;
export const MAX_CONTEXT_LENGTH = SLACK_MAX_CONTEXT_TEXT - 50;
/** Audience size considered large enough to require a confirmation for DM sends */
export const LARGE_AUDIENCE_SIZE = 50;
/** Audience size considered large enough to not show live results for single question pollys */
export const DM_POLL_AUDIENCE_LIMIT = 500;
/** Number of author success epehmeral polly tips */
export const MAX_AUTHOR_EPHEMERAL_POLLY_TIP = 6;

export const POLLY_BLUE = "#35A8E0";
export const POLLY_SUCCESS = "#82D9B6";

export const PLATFORM_AUTH_URI: string = process.env["PLATFORM_AUTH_URI"];
export const PLATFORM_API_URI: string = process.env["PLATFORM_API_URI"];

/** Service credentials for Polly Platform */
export const PLATFORM_CLIENT_ID: PlatformUserId = process.env[
  "PLATFORM_CLIENT_ID"
] as PlatformUserId;
export const PLATFORM_CLIENT_SECRET: string =
  process.env["PLATFORM_CLIENT_SECRET"];

export const PLATFORM_VOTING_BASE_URL: string =
  process.env["PLATFORM_VOTING_BASE_URL"];

export const INTEGRATION_TEST_SLACK_URL =
  process.env["INTEGRATION_TEST_SLACK_URL"];

export const DISABLE_REDIS_TLS = process.env["DISABLE_REDIS_TLS"];
export const LEGACY_ENTERPRISE_BRANDING = "Legacy Enterprise";
export const OFFICIAL_MESSAGE_LOG_PREFIX = "[OFFICIAL_MESSAGE]: ";
export const WEB_LOGIN_JWT_SECRET = process.env["WEB_LOGIN_JWT_SECRET"];

export const ALERT_NOTIFICATION_PARAMS =
  process.env["ALERT_NOTIFICATION_PARAMS"];
export const PLATFORM_JWT_KEY = process.env["PLATFORM_JWT_KEY"];

export const ORG_LIST_FOR_ASYNC_GHSHEET_UPDATE = [
  "gXzP4yE5ngGPTGLdp", // Asad's Testing WS Local
  "34D3hPoXSRjHTZSjc", // Asad's Testing WS Beta
  "ih4QwDa7jALpCHxCY", // Asad's Testing WS Staging
  "ELNa57MCZYP6LZmnq", // Asad's Testing WS Prod
  "tdPmxinfZd56nWgPv", // MonteCarlo
  "tkdjmYaHG7Nk5Y9Hq", // Andalite Beta
  "qPFjGmKEcbqmQaHAC", // Andalite Staging
  "9Mx7Zu59K4ePkMpxy", // Andalite Prod
];

export const QNA_GUEST_USER_NAME = "Guest User";
export const POLLY_AI_ORG_ID = process.env["POLLY_AI_ORG_ID"];

// defaults to #admin-dm-testing in Polly AI workspace
export const ADMIN_DM_TEST_CHANNEL_ID =
  process.env["ADMIN_DM_TEST_CHANNEL_ID"] ?? "C06KS5GD1TK";

// defaults to #sales-notify-dm-sent in Polly AI workspace
export const SALES_NOTIFY_DM_SENT_CHANNEL_ID =
  process.env["SALES_NOTIFY_DM_SENT_CHANNEL_ID"] ?? "C06J399J98X";

export const ADMIN_OPS_CUSTOM_DM_BURST = 25;
export const ADMIN_OPS_CUSTOM_DM_PER_SECOND = 2;

export const MONTHS_UNBLOCK_RESULTS_FOR = 3;
