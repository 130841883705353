import { SlackEnterpriseId } from "../../slackIdTypes";
import { hasKey } from "../../utils";

import { Tier, tierDefs } from "../tier";
import {
  AllowedFeatures,
  FeatureGrants,
  MergeFeatureGrants,
  NumericFeature,
} from "../feature";

import { SubscriptionSchema, SubscriptionType } from "./schema";
import { subs } from "./db";
import { FilterQuery } from "mongodb";
import { UserSubscriptionInfo } from "../../userSubscriptionInfoTypes";

export function isPaidPlan(subscription: SubscriptionSchema): boolean {
  return (
    ![Tier.FREE, Tier.MANAGED_TRIAL_ORG].includes(subscription.tier) &&
    !subscription.isTrial
  );
}

export function isBelowSeatsLimit(
  userSubscription: SubscriptionSchema,
): boolean {
  return userSubscription.authorIds.length < userSubscription.licenses;
}

export function getAllowedFeatures(
  ...sources: SubscriptionSchema[]
): AllowedFeatures {
  const featureMaps: FeatureGrants[] = [];
  let minAudienceLimit = Infinity;
  sources.forEach((s) => {
    featureMaps.push(...tierDefs[s.tier].features);
    if (s.featureOverrides) {
      featureMaps.push(s.featureOverrides);

      // A value of 0 is not allowed in the admin panel. So any falsey value
      // means it's not set. This is currently naively choosing the smallest number
      // as indicating the most restrictive. Ideally we would pick a user override
      // over the org override but we don't have that distinction here.
      if (s.featureOverrides.DirectMessageAudienceLimit) {
        minAudienceLimit = Math.min(
          minAudienceLimit,
          s.featureOverrides.DirectMessageAudienceLimit,
        );
      }
    }
  });

  const mergedFeatureGrants = MergeFeatureGrants(...featureMaps);

  // If any override exists for the audience limit use it.
  if (minAudienceLimit !== Infinity) {
    mergedFeatureGrants.DirectMessageAudienceLimit = minAudienceLimit;
  }
  return mergedFeatureGrants;
}

export async function getSubscription(
  params: (
    | { subscriptionId: string; subscriptionType: SubscriptionType }
    | { userId: string }
    | { slackEnterpriseId: SlackEnterpriseId }
  ) & { throwIfMissing?: boolean },
): Promise<SubscriptionSchema> {
  let query: FilterQuery<SubscriptionSchema> = {};
  let type: SubscriptionType;
  if (hasKey(params, "subscriptionId")) {
    type = params.subscriptionType;
    query = {
      _id: params.subscriptionId,
      subscriptionType: type,
    };
  } else if (hasKey(params, "userId")) {
    type = SubscriptionType.USER_LEVEL;
    query = {
      subscribingUserId: params.userId,
      subscriptionType: type,
    };
  } else {
    type = SubscriptionType.ENTERPRISE_LEVEL;
    query = {
      slackEnterpriseId: params.slackEnterpriseId,
      subscriptionType: type,
    };
  }
  const existing = await subs.find(query).toArray();
  if (!existing.length) {
    return null;
  }
  // shouldn't be more than one but just in case, prefer active,
  // fall back on most recent
  const current =
    existing.find((sub) => sub.active) ||
    existing.sort((a, b) => {
      const aDate = a.cancelDate ?? a.endDate ?? a.startDate;
      const bDate = b.cancelDate ?? b.endDate ?? b.startDate;
      return bDate.getTime() - aDate.getTime();
    })[0];
  // lock the subscription
  const result = (
    await subs.findOneAndUpdate(
      { _id: current._id },
      { $set: { subscriptionType: type, updatedAt: new Date() } },
    )
  )?.value;
  if (!result && params.throwIfMissing) {
    throw new Error(`No subscription found for ${JSON.stringify(query)}`);
  }
  return result;
}

export function getDaysOfHistory(subscription: UserSubscriptionInfo): number {
  const primarySub = subscription.primarySubscription;
  return (
    primarySub.featureOverrides?.DaysOfHistory ??
    subscription.features[NumericFeature.DaysOfHistory]
  );
}
