import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import {
  FeatureGrants,
  BooleanFeature,
  NumericFeature,
  isBooleanFeature,
  isNumericFeature,
  SubscriptionSchema as Subscription,
  SubscriptionType,
  Tier,
} from "@pollyslack/hub/src/features/types";

import { DateTime } from "../Forms/DateTime";
import { PlanSelector } from "../Forms/PlanSelector";
import { getEnumValues } from "@pollyslack/hub/src/utils";
import moment from "moment";

type UpdateSubscriptionModalProps = {
  isOpen: boolean;
  subscription: Subscription;
  onClose: () => void;
  onSubmit: (params: {
    endDate?: string;
    tier: Tier;
    isTrial: boolean;
    stripeCustomerId?: string;
    stripeSubscriptionItemId?: string;
    billingAdminIds?: string[];
    subscribingUserId?: string;
    /** if true, you should wipe the endDate in mongo */
    clearEndDate: boolean;
    featureOverrides?: FeatureGrants;
    licenses?: number;
  }) => void;
};

export const UpdateSubscriptionModal: React.FunctionComponent<
  UpdateSubscriptionModalProps
> = (props: UpdateSubscriptionModalProps) => {
  const [endDate, setEndDate] = useState<Date | undefined>(
    props.subscription.endDate,
  );
  const [clearEndDate, setClearEndDate] = useState(false);
  const [tier, setTier] = useState(props.subscription.tier);
  const [isTrial, setIsTrial] = useState(props.subscription.isTrial);
  const [stripeCustomerId, setStripeCustomerId] = useState(
    props.subscription.payment?.stripeCustomerId,
  );
  const [stripeSubscriptionItemId, setStripeSubscriptionItemId] = useState(
    props.subscription.payment?.stripeSubscriptionItemId,
  );
  const [billingAdminIds, setBillingAdminIds] = useState(
    props.subscription.billingAdminIds,
  );

  const [featureOverrides, setFeatureOverrides] = useState<FeatureGrants>(
    props.subscription.featureOverrides ?? {},
  );
  // default licenses to 1 in case it's missing in the db
  const [licenses, setLicenses] = useState(props.subscription.licenses ?? 1);
  const [licenseValidationError, setLicenseValidationError] = useState("");

  const minLicenses = 0;
  function handleChangeLicenses(value: number) {
    if (value >= minLicenses) {
      setLicenseValidationError("");
    } else {
      setLicenseValidationError(`Minimum Number of Licenses is ${minLicenses}`);
    }
  }

  const features = [
    ...getEnumValues(BooleanFeature),
    ...getEnumValues(NumericFeature),
  ].sort();

  function setBooleanFeatureOverride(f: BooleanFeature, v: boolean) {
    const updated = { ...featureOverrides };
    if (v) {
      updated[f] = true;
    } else {
      delete updated[f];
    }
    setFeatureOverrides(updated);
  }

  function setNumericFeatureOverride(f: NumericFeature, v: number) {
    const updated = { ...featureOverrides };
    updated[f] = v;
    setFeatureOverrides(updated);
  }

  function wipeEndDate() {
    setEndDate(undefined);
    setClearEndDate(true);
  }

  function setDate(date: Date) {
    setEndDate(date);
    setClearEndDate(false);
  }

  return (
    <Modal isOpen={props.isOpen} style={{ maxWidth: "700px" }}>
      <div className="modal-header">
        <h5 className="modal-title">Update Subscription</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <div className="form-group">
          <label>Select a Plan</label>
          <PlanSelector
            onChange={setTier}
            subscriptionType={props.subscription.subscriptionType}
            plan={props.subscription.tier || tier}
          />
        </div>
        <div className="form-group">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="is-trial"
              defaultChecked={isTrial}
              onChange={(e) => setIsTrial(e.currentTarget.checked)}
            />
            <label htmlFor="is-trial" className="form-check-label">
              Trial
            </label>
          </div>
        </div>
        {props.subscription.subscriptionType ===
          SubscriptionType.USER_LEVEL && (
          <div className="form-group">
            <label>Number of Licenses</label>
            <input
              type="number"
              className={
                licenseValidationError.length > 0
                  ? "form-control is-invalid"
                  : "form-control"
              }
              placeholder="License Count..."
              defaultValue={licenses}
              onChange={(e) => {
                handleChangeLicenses(e.currentTarget.valueAsNumber);
                setLicenses(e.currentTarget.valueAsNumber);
              }}
            />
            <span className="form-text text-danger">
              {licenseValidationError}
            </span>
          </div>
        )}
        <div className="form-group">
          <label>
            Stripe Customer ID <i>(optional)</i>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Stripe Customer ID..."
            defaultValue={props.subscription.payment?.stripeCustomerId}
            onChange={(e) => setStripeCustomerId(e.currentTarget.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Stripe Subscription Item ID <i>(optional, starts with si_)</i>
          </label>
          <input
            type="text"
            className="form-control"
            pattern="si_.*"
            placeholder="Stripe Subscription Item ID..."
            defaultValue={props.subscription.payment?.stripeSubscriptionItemId}
            onBlur={(e) => {
              if (
                e.currentTarget.value &&
                e.currentTarget.value.length > 0 &&
                e.currentTarget.value.indexOf("si_") !== 0
              ) {
                alert("Stripe Subscription Item ID should start with si_");
                return;
              }
            }}
            onChange={(e) => setStripeSubscriptionItemId(e.currentTarget.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Billing Admin Ids<i>(comma separated)(optional)</i>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Billing Admin Ids..."
            defaultValue={props.subscription.billingAdminIds}
            onChange={(e) =>
              setBillingAdminIds(e.currentTarget.value?.split(",") ?? [])
            }
          />
        </div>
        <div className="row">
          <div className="col">
            <label>
              Select an Expiration Date <i>(optional)</i>
            </label>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-9">
            <DateTime
              onChange={(e) => setDate(moment(e).toDate())}
              value={endDate}
            />
          </div>
          <div className="col-3">
            <button
              className="btn btn-outline-secondary w-100"
              onClick={wipeEndDate}
            >
              Clear
            </button>
          </div>
        </div>
        <div className="form-group">
          <label>
            Select Feature Overrides <i>(optional)</i>
          </label>
          <div className="overflow-auto px-5" style={{ maxHeight: "30vh" }}>
            {features.map((f) => (
              <div className="form-row" key={f}>
                <div className="col">
                  <label htmlFor={f}>{f}</label>
                </div>
                <div className="col-3 d-flex flex-row-reverse">
                  {isBooleanFeature(f) && (
                    <BooleanInput
                      id={f}
                      defaultValue={featureOverrides[f]}
                      onChange={(v) => setBooleanFeatureOverride(f, v)}
                    />
                  )}
                  {isNumericFeature(f) && (
                    <NumericInput
                      defaultValue={featureOverrides[f]}
                      minValue={0 /* TODO use selected plan allowance + 1 */}
                      onChange={(v) => setNumericFeatureOverride(f, v)}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-light" onClick={props.onClose}>
          Close
        </button>
        <button
          disabled={licenseValidationError.length > 0}
          className="btn btn-primary"
          onClick={() => {
            if (
              props.subscription.licenses &&
              licenses < props.subscription.licenses
            ) {
              const confirmed = window.confirm(
                `Are you sure you want to decrease Number of Licenses from ${props.subscription.licenses} to ${licenses}?`,
              );
              if (!confirmed) return;
            }
            if (
              featureOverrides.OrgMonthlyResponseLimitEnabled &&
              !featureOverrides.OrgMonthlyResponseLimit
            ) {
              window.alert(
                `"OrgMonthlyResponseLimit" must be greater than 0 if "OrgMonthlyResponseLimitEnabled" is checked`,
              );
              return;
            }
            props.onSubmit({
              endDate: endDate?.toString(),
              tier,
              clearEndDate,
              isTrial,
              stripeCustomerId,
              stripeSubscriptionItemId,
              billingAdminIds,
              featureOverrides,
              licenses,
              subscribingUserId:
                billingAdminIds?.length > 0 ? billingAdminIds[0] : "",
            });
          }}
        >
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
};

type BooleanInputProps = {
  id: string;
  defaultValue: boolean;
  onChange: (v: boolean) => void;
};
export const BooleanInput: React.FunctionComponent<BooleanInputProps> = (
  props: BooleanInputProps,
) => (
  <input
    type="checkbox"
    className="form-check-input float-right"
    id={props.id}
    defaultChecked={props.defaultValue}
    onChange={(e) => props.onChange(e.currentTarget.checked)}
  />
);

type NumericInputProps = {
  defaultValue: number;
  minValue: number;
  onChange: (v: number) => void;
};

export const NumericInput: React.FunctionComponent<NumericInputProps> = (
  props: NumericInputProps,
) => (
  <input
    type="number"
    min={props.minValue}
    max={9999999}
    className="form-control"
    value={props.defaultValue}
    onChange={(e) => {
      let value = parseInt(e.currentTarget.value, 10);
      if (value === props.minValue) {
        value = NaN;
      }
      props.onChange(value);
    }}
  />
);
