import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

type AddAuthorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (params: { userId: string }) => void;
};
export const AddAuthorModal: React.FunctionComponent<AddAuthorModalProps> = (
  props: AddAuthorModalProps,
) => {
  const [userId, setUserId] = useState("");
  return (
    <Modal isOpen={props.isOpen}>
      <div className="modal-header">
        <h5 className="modal-title">Add Subscription Author</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <div className="form-group">
          <label>New Author</label>
          <input
            type="text"
            className="form-control"
            placeholder="mongo _id of the new author"
            onChange={(e) => setUserId(e.currentTarget.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-light" onClick={props.onClose}>
          Close
        </button>
        <button
          className="btn btn-primary"
          onClick={() => props.onSubmit({ userId })}
        >
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
};
