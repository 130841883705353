import { BooleanFeature, FeatureGrants, NumericFeature } from "../feature";

export type TierDef = {
  order: number;
  userName:
    | "Enterprise"
    | "Free"
    | "Standard"
    | "Pro"
    | "Team"
    | "Audience"
    | "Business";
  features: FeatureGrants[];
};

export enum Tier {
  FREE = "FREE",
  UL_STANDARD_RESPONSE_BASED = "UL_STANDARD_RESPONSE_BASED",
  UL_PRO_RESPONSE_BASED = "UL_PRO_RESPONSE_BASED",
  UL_PRO_RESPONSE_LITE = "UL_PRO_RESPONSE_LITE",
  ENTERPRISE = "ENTERPRISE",
  MANAGED_TRIAL_ORG = "MANAGED_TRIAL_ORG",
  MANAGED_TRIAL_USER = "MANAGED_TRIAL_USER",
  TEAM = "TEAM",
  AUDIENCE = "AUDIENCE",
  BUSINESS = "BUSINESS",
}

// the tiers in order from worst to best
export const USER_LEVEL_TIERS = Object.freeze([
  Tier.UL_STANDARD_RESPONSE_BASED,
  Tier.UL_PRO_RESPONSE_BASED,
  Tier.UL_PRO_RESPONSE_LITE,
  Tier.MANAGED_TRIAL_USER,
  Tier.AUDIENCE,
  Tier.ENTERPRISE,
]);
export const ORG_LEVEL_TIERS = Object.freeze([
  Tier.FREE,
  Tier.ENTERPRISE,
  Tier.MANAGED_TRIAL_ORG,
  Tier.TEAM,
  Tier.BUSINESS,
]);

export const ALL_TIERS = Object.freeze([
  ...USER_LEVEL_TIERS,
  ...ORG_LEVEL_TIERS,
]);

const free: TierDef = {
  order: 0,
  userName: "Free",
  features: [
    {
      [NumericFeature.DaysOfHistory]: 45,
      [NumericFeature.DirectMessageAudienceLimit]: 500,
      [NumericFeature.ResponsesPerMonth]: 25,
    },
  ],
};
const standard: TierDef = {
  order: 10,
  userName: "Standard",
  features: [
    {
      [BooleanFeature.AccessSharedItems]: true,
      [BooleanFeature.AdvancedShareControls]: true,
      [BooleanFeature.ExportQuestion]: true,
      [BooleanFeature.PaidTier]: true,
      [BooleanFeature.SlashPollyExport]: true,
      [BooleanFeature.SlashPollyShare]: true,
      [BooleanFeature.TrendHistory]: true,
      [NumericFeature.DaysOfHistory]: Infinity,
      [NumericFeature.DirectMessageAudienceLimit]: Infinity,
      [NumericFeature.ResponsesPerMonth]: 500,
    },
  ],
};
const proLegacy: TierDef = {
  order: 25,
  userName: "Pro",
  features: [
    ...standard.features,
    {
      [BooleanFeature.Analytics]: true,
      [BooleanFeature.Api]: true,
      [BooleanFeature.GoogleSheetsSync]: true,
      [NumericFeature.ResponsesPerMonth]: 1000,
    },
  ],
};
const pro: TierDef = {
  order: 30,
  userName: "Pro",
  features: [
    ...standard.features,
    {
      [NumericFeature.ResponsesPerMonth]: 1000,
    },
  ],
};
const team: TierDef = {
  order: 35,
  userName: "Team",
  features: [
    {
      [BooleanFeature.AccessSharedItems]: true,
      [BooleanFeature.AdvancedShareControls]: true,
      [BooleanFeature.ExportQuestion]: true,
      [BooleanFeature.PaidTier]: true,
      [BooleanFeature.SlashPollyExport]: true,
      [BooleanFeature.SlashPollyShare]: true,
      [BooleanFeature.TrendHistory]: true,
      [NumericFeature.DaysOfHistory]: Infinity,
      [NumericFeature.DirectMessageAudienceLimit]: Infinity,
      [NumericFeature.ResponsesPerMonth]: Infinity,
    },
  ],
};

const audience: TierDef = {
  order: 35,
  userName: "Team",
  features: [...team.features],
};

const business: TierDef = {
  order: 40,
  userName: "Business",
  features: [
    ...proLegacy.features,
    {
      // Borrowed from Enterprise
      [BooleanFeature.EnterpriseAdminDashboard]: true,
      [BooleanFeature.EnterpriseUsagePage]: true,
      [BooleanFeature.EnterpriseUserManagement]: true,
      [NumericFeature.ResponsesPerMonth]: Infinity,
      [NumericFeature.DirectMessageAudienceLimit]: Infinity,
      [BooleanFeature.AuthenticatedWebLinks]: true,

      // Additional features
      [BooleanFeature.QnAv2]: true,
      [BooleanFeature.ManageSuggestionBox]: true,
      [BooleanFeature.Workflows]: true,
    },
  ],
};

const enterprise: TierDef = {
  order: 45,
  userName: "Enterprise",
  features: [
    ...proLegacy.features,
    {
      [BooleanFeature.EnterpriseActivityLogs]: true,
      [BooleanFeature.EnterpriseAdminDashboard]: true,
      [BooleanFeature.EnterpriseSettingsDataRetention]: true,
      [BooleanFeature.EnterpriseUserManagement]: true,
      [NumericFeature.ResponsesPerMonth]: Infinity,
      [NumericFeature.DirectMessageAudienceLimit]: Infinity,
      [BooleanFeature.AuthenticatedWebLinks]: true,
    },
  ],
};
const managedTrialOrganization: TierDef = {
  order: 0,
  userName: "Free",
  features: [
    {
      [BooleanFeature.RequestManagedTrialLicense]: true,
    },
  ],
};
const managedTrialUser: TierDef = {
  order: 5,
  userName: "Pro",
  features: [
    ...pro.features,
    {
      [BooleanFeature.ManagedTrialLicense]: true,
    },
  ],
};

export const tierDefs: { [key in Tier]: TierDef } = {
  [Tier.FREE]: free,
  [Tier.UL_STANDARD_RESPONSE_BASED]: standard,
  [Tier.UL_PRO_RESPONSE_LITE]: pro,
  [Tier.UL_PRO_RESPONSE_BASED]: proLegacy,
  [Tier.ENTERPRISE]: enterprise,
  [Tier.MANAGED_TRIAL_ORG]: managedTrialOrganization,
  [Tier.MANAGED_TRIAL_USER]: managedTrialUser,
  [Tier.TEAM]: team,
  [Tier.AUDIENCE]: audience,
  [Tier.BUSINESS]: business,
  [Tier.UL_PRO_RESPONSE_BASED + "_semi_annual"]: pro, // hack for ab test to allow two pro plan cards to be dispalyed and selected
};

const enterprise_level_plans = ["Enterprise", "Team", "Audience", "Business"];

export function isEnterpriseTypePlan(
  planUserName: TierDef["userName"],
): boolean {
  return enterprise_level_plans.includes(planUserName);
}
