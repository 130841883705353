import React, { useEffect, useState } from "react";
import { Organization } from "@pollyslack/hub/src/types";
import { ToolbarButton } from "../../../components/ToolbarButton";
import { ExpanderContent, ExpanderToggle } from "../../../components/Expander";
import { Code } from "../../../components/Code";
import { LoadingScreen } from "../../../components/LoadingScreen";
import { toast } from "react-toastify";
import { getCookie } from "../../../common/cookiesHelper";
import {
  sendTestDM,
  getActiveUserList,
  getSlackAdminUserList,
  triggerDM,
  validateUserList,
  getPreviouslyTriggeredDmData,
} from "../../../services/api/Organization";

type SendDMsTabProps = {
  organization: Organization;
  reload: () => Promise<void>;
};
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
export const SendDMsTab: React.FunctionComponent<SendDMsTabProps> = (
  props: SendDMsTabProps,
) => {
  const testChannel = "#admin-dm-testing";
  const [previousDMJson, setPreviousDMJson] = useState(null);
  const senderEmail = (getCookie("authId") ?? "").replace("%40", "@");
  const orgName = props.organization.name;
  const [previousDMinfoExpanded, setPreviousDMinfoExpanded] = useState(false);

  useEffect(() => {
    getPreviouslyTriggeredDmData(props.organization._id).then((data) => {
      return setPreviousDMJson(data);
    });
  }, []);

  const [userCount, setUserCount] = useState(null);
  const [blockKitText, setBlockKitText] = useState("");
  const [userListText, setUserListText] = useState("");
  const [confirmedText, setConfirmedText] = useState("");
  const [isUndertakingCheckboxChecked, setIsUndertakingCheckboxChecked] =
    useState(false);
  const [testMessageSent, setTestMessageSent] = useState(false);
  const [userListVerified, setUserListVerified] = useState(false);
  const [userListVerifificationResult, setUserListVerifificationResult] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateBlockKitJSON = (text: string) => {
    try {
      // Attempt to parse the text as JSON
      const jsonObj = JSON.parse(text);

      // Check if the parsed object has a 'blocks' key
      // eslint-disable-next-line no-prototype-builtins
      if (jsonObj.hasOwnProperty("blocks")) {
        return true; // Valid JSON and contains 'blocks' key
      } else {
        return false; // Valid JSON but does not contain 'blocks' key
      }
    } catch (error) {
      // If an error occurs during parsing, the text is not valid JSON
      return false;
    }
  };

  const handleSendTestMessage = async () => {
    setIsLoading(true);
    const isValidJSON = validateBlockKitJSON(blockKitText);
    if (!isValidJSON) {
      toast.error(
        "The JSON payload is not valid. Please check the format and try again.",
      );
      setIsLoading(false);
      return;
    }
    const cleanedBlockKitText = JSON.stringify(
      JSON.parse(blockKitText)["blocks"],
    );
    const res = await sendTestDM(cleanedBlockKitText);
    await sleep(500); // Add a short pause
    if (!res) {
      toast.error("Failed to send test message!");
      setIsLoading(false);
      return;
    }

    toast.info("Test message sent successfully!");
    setTestMessageSent(true);
    setIsLoading(false);
  };

  const cleanText = (text: string) => {
    return text.replace(/\s+/g, "").replace(/,+/g, ",");
  };
  const handleSendDMs = async () => {
    setIsLoading(true);
    toast.success("Direct messages sent successfully!");
    setConfirmedText("");
    setIsUndertakingCheckboxChecked(false);
    const cleanedText = cleanText(userListText);
    const userIds = cleanedText.split(",");
    const cleanedBlockKitText = JSON.stringify(
      JSON.parse(blockKitText)["blocks"],
    );
    await triggerDM(cleanedBlockKitText, props.organization._id, userIds);
    setUserListVerified(false);
    setIsLoading(false);
  };

  const handleVerifyUserList = async () => {
    console.log(userListText);
    const cleanedText = cleanText(userListText);
    setUserListText(cleanedText);
    let userIds = cleanedText.split(",");
    // Dedup
    userIds = Array.from(new Set(userIds));
    setUserListText(userIds.join(","));

    setIsLoading(true);
    const res = await validateUserList(props.organization._id, userIds);
    await sleep(500);
    if (!res || res.status !== "success") {
      toast.error("Could not verify all users!");
      setIsLoading(false);
      setUserListVerified(false);
      setUserListVerifificationResult(res.message);
      return;
    }

    setUserListVerified(true);
    setUserListVerifificationResult(res.message);
    setUserCount(userIds.length);
    setIsLoading(false);
  };
  const handleGetActiveUserList = async () => {
    console.log(userListText);
    setIsLoading(true);
    const res = await getActiveUserList(props.organization._id);
    await sleep(500);
    setUserListText(res.join(","));
    setIsLoading(false);
  };
  const handleGetSlackAdminUserList = async () => {
    console.log(userListText);
    setIsLoading(true);
    const res = await getSlackAdminUserList(props.organization._id);
    await sleep(500);
    setUserListText(res.join(","));
    setIsLoading(false);
  };

  // This function updates the checkbox state
  const handleUndertakingCheckboxChange = () => {
    setIsUndertakingCheckboxChecked(!isUndertakingCheckboxChecked);
  };

  // This function could be used to handle text input changes
  const handleConfirmationTextChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setConfirmedText(event.target.value);
  };

  // Condition to enable button
  const sendDMButtonDisabled =
    confirmedText !== "CONFIRMED" || !isUndertakingCheckboxChecked;
  return (
    <div className="col-12 col-md-10 mx-md-auto">
      {isLoading && <LoadingScreen text="Please wait..." />}
      <div className="font-weight-500" style={{ color: "#444" }}>
        <div className="pb-3">
          View raw json of previous DMs sent to users in this organization{" "}
          <ExpanderToggle
            expanded={previousDMinfoExpanded}
            toggle={() => setPreviousDMinfoExpanded(!previousDMinfoExpanded)}
          />
          <ExpanderContent expanded={previousDMinfoExpanded}>
            <Code object={previousDMJson} />
          </ExpanderContent>
        </div>
        <div className="row">
          <div className="col">
            <div
              className="text-danger font-weight-600"
              style={{ fontSize: "0.95rem" }}
            >
              <i className="fa fa-exclamation-triangle"></i>{" "}
              <strong>WARNING!!!</strong> Do not use this functionality without{" "}
              <b>explicit permission from Polly Leadership</b>. Please note that
              all activity in this Admin app is logged and monitored as part of
              our security requirements.
            </div>
            <textarea
              className="mt-3 p-3"
              placeholder="Paste the JSON payload from Slack’s Block Kit Builder here"
              rows={6}
              style={{ width: "100%" }}
              onChange={(e) => {
                setBlockKitText(e.target.value);
                setTestMessageSent(false);
              }}
            />
            <p>
              <b>Note:</b> on clicking Submit, the message will be sent to{" "}
              <b>{testChannel}</b> in the Polly workspace. Please verify the
              accuracy, compatibility and usability of all text, images and CTAs
              before proceeding.
            </p>
            <button
              onClick={handleSendTestMessage}
              className="btn btn-outline-secondary font-weight-600"
              disabled={blockKitText === ""}
            >
              <i className="fa fa-paper-plane pr-1"></i> Send Test Message
            </button>
          </div>
        </div>
        {testMessageSent && (
          <div className="row">
            <div className="col">
              <hr className="my-4" style={{ border: "1px solid" }} />
              <div className="text-danger font-weight-bold">
                <i className="fa fa-exclamation-triangle"></i> Select which
                users should receive the above JSON payload
              </div>
              <div className="btn-group mb-2 mt-3 ">
                <ToolbarButton
                  icon=""
                  title="Send to Active Users"
                  onClick={handleGetActiveUserList}
                  className="font-weight-bold btn-outline-secondary"
                />

                <ToolbarButton
                  icon=""
                  title="Send to Slack Admins"
                  onClick={handleGetSlackAdminUserList}
                  className="font-weight-bold btn-outline-secondary"
                />
              </div>
              <div>
                <div>
                  Paste a comma separated list of Mongo userIDs that will
                  receive the DM, or pre-fill this list by clicking one of the
                  buttons above. <br />
                  <br />
                </div>
                <div>
                  <b>Active Users: </b> &nbsp; &nbsp;anyone who has created a
                  polly in the last 180 days, or has an active track
                </div>
                <div>
                  <b>Slack Admins:</b> &nbsp;all admins, owners and primary
                  owners of the workspace.
                </div>
              </div>
              <textarea
                value={userListText}
                className="mt-3 p-3"
                rows={6}
                style={{ width: "100%" }}
                onChange={(e) => setUserListText(e.target.value)}
              />
              <div className="font-weight-bold">
                <i className="fa fa-exclamation-triangle"></i> Note: Double
                check that this list of userIDs are correct before sending the
                message.
              </div>
              {userListVerifificationResult && (
                <div className="my-2">
                  <span
                    className={
                      (userListVerified
                        ? "bg-success text-light"
                        : "bg-warning text-dark") +
                      "  py-1 px-2 font-weight-600"
                    }
                    style={{ borderRadius: "10px" }}
                  >
                    {userListVerifificationResult}
                  </span>
                  <br />
                </div>
              )}
              <button
                onClick={handleVerifyUserList}
                className="btn btn-outline-success font-weight-bold my-3"
                disabled={userListText === ""}
              >
                <i className="fa fa-plus-circle pr-1"></i> Verify User List
              </button>
            </div>
          </div>
        )}
        {testMessageSent && userListVerified && (
          <div className="row">
            <div className="col">
              <hr className="my-4" style={{ border: "1px solid" }} />
              <h4 className="mb-2">Read Carefully!!!</h4>
              <div
                className="text-danger font-weight-600"
                style={{ fontSize: "0.95rem" }}
              >
                <i className="fa fa-exclamation-triangle"></i>{" "}
                <strong>WARNING</strong> - you are about to send direct messages
                to: <br />
                <div
                  className="text-danger font-weight-600"
                  style={{ fontSize: "0.95rem" }}
                >
                  <strong>{userCount} users(s)</strong> in the{" "}
                  <strong>{orgName}</strong> workspace!
                </div>
                <br />
              </div>
              <div
                className="text-danger font-weight-600"
                style={{ fontSize: "0.9rem" }}
              >
                Please type CONFIRMED below to continue
              </div>
              <div className="mt-1">
                <input
                  className="font-weight-bold mr-3 px-1"
                  type="text"
                  value={confirmedText}
                  onChange={handleConfirmationTextChange}
                  style={{ fontSize: "0.95rem", color: "blue" }}
                ></input>{" "}
                by:
                <input
                  className="font-weight-bold ml-3"
                  disabled={true}
                  value={senderEmail}
                ></input>
              </div>
              <br /> <br /> <br />
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="sendDMUndertakingCheckbox"
                    checked={isUndertakingCheckboxChecked}
                    onChange={handleUndertakingCheckboxChange}
                    style={{ cursor: "pointer", width: "15px", height: "15px" }}
                  />
                  <label
                    className="form-check-label font-weight-600"
                    htmlFor="sendDMUndertakingCheckbox"
                    style={{ fontSize: "0.95rem" }}
                  >
                    I understand that I am sending direct messages to users via
                    the Polly app, and acknowledge that my activity in this
                    regard is being logged for security and verification
                    purposes.
                  </label>
                </div>
                <button
                  onClick={handleSendDMs}
                  disabled={sendDMButtonDisabled}
                  className="btn btn-outline-danger font-weight-bold my-3"
                >
                  <i className="fa fa-paper-plane pr-1"></i> Send DM
                </button>
                <br /> <br /> <br /> <br /> <br /> <br />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
