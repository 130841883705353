import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { ErrorBoundary } from "./components/ErrorBoundary";
import { PageRoutes } from "./routes";

import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <ErrorBoundary>
      <ToastContainer />
      <BrowserRouter>
        <PageRoutes />
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
