import * as React from "react";

import StaticAssets from "@pollyslack/assets/src/StaticAssets";
import MenuBar from "src/components/MenuBar";

export class App extends React.Component<any, any> {
  render() {
    return (
      <div className="container-fluid" style={{ height: "100vh" }}>
        <div className="d-flex flex-column align-content-stretch h-100">
          <div className="row">
            <div className="col p-0">
              <MenuBar />
            </div>
          </div>
          <div className="row flex-grow-1 align-items-center">
            <img
              className="mx-auto"
              src={StaticAssets.START_TRIAL_ANIMATION}
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}
