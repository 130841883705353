import React from "react";

import {
  AllowedFeatures,
  AnyFeature,
  BooleanFeature,
  NumericFeature,
  isNumericFeature,
} from "@pollyslack/hub/src/features/types";
import { BoolCheck } from "src/components/BoolCheck";

type FeaturesTableProps = {
  features: AllowedFeatures;
  type: "boolean" | "numeric";
};
export const FeaturesTable: React.FunctionComponent<FeaturesTableProps> = (
  props: FeaturesTableProps,
) => {
  const { features, type } = props;
  const featureNames =
    type === "boolean"
      ? Object.keys(features)
          .filter((f) => !isNumericFeature(f as AnyFeature))
          .sort()
      : Object.keys(features)
          .filter((f) => isNumericFeature(f as AnyFeature))
          .sort();
  const toValue =
    type === "boolean"
      ? (f: string) => (
          <BoolCheck check={features[f as BooleanFeature] as boolean} />
        )
      : (f: string) => (features[f as NumericFeature] as number) ?? 0;
  return (
    <table className="table table-sm table-borderless table-hover">
      <tbody>
        {featureNames.map((f) => {
          return (
            <tr key={f}>
              <td>{f}</td>
              <td>{toValue(f)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
