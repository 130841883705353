import { post } from "./rest";

import { Track } from "@pollyslack/hub/src/types";

const root = "/tracks/";

export async function info(params: {
  trackId: string;
  $populate?: {
    questions?: boolean;
    teams?: boolean;
    author?: boolean;
    surveys?: boolean;
    polls?: boolean;
  };
}): Promise<any> {
  return await post(`${root}info`, params);
}

export async function list(searchParams: {
  search?: string;
  showDeleted?: boolean;
}): Promise<Track[]> {
  return await post(`${root}list`, searchParams);
}

export async function update(params: {
  trackId: string;
  active?: boolean;
}): Promise<any> {
  return await post(`${root}update`, params);
}

export async function transferOwnership(params: {
  trackId: string;
  /** mongo id of the new owner */
  userId: string;
}): Promise<{ track: Track }> {
  return await post(`${root}transferOwnership`, params);
}
