import React from "react";

import { WorkflowSchema } from "@pollyslack/hub/src/schemas/workflow";
import { Organization, Survey, Track } from "@pollyslack/hub/src/types";

import { list as listPolls } from "../../../services/api/Poll";
import { list as listSurveys } from "../../../services/api/Survey";
import { list as listTracks } from "../../../services/api/Track";
import { list as listWorkflows } from "../../../services/api/Workflow";

import { OrgActivity } from "../components/OrgActivity";
import { PollWithDetails } from "../../../common/apiTypes";

type ActivityTabProps = {
  organization: Organization;
};

export const ActivityTab: React.FunctionComponent<ActivityTabProps> = (
  props: ActivityTabProps,
) => {
  const [polls, setPolls] = React.useState<PollWithDetails[]>([]);
  const [surveys, setSurveys] = React.useState<Survey[]>([]);
  const [tracks, setTracks] = React.useState<Track[]>([]);
  const [workflows, setWorkflows] = React.useState<WorkflowSchema[]>([]);
  const [reloadToggle, setReloadToggle] = React.useState(false);
  setReloadToggle; // silence lint
  React.useEffect(() => {
    // Use a local variable in the closure to prevent setting state on an unmounted component
    let cancelled = false;
    async function loadUsers() {
      const pollsResponse = await listPolls({
        search: props.organization?._id,
      });
      const surveysResponse = await listSurveys({
        search: props.organization?._id,
      });
      const tracksResponse = await listTracks({
        search: props.organization?._id,
      });
      const workflowsResponse = await listWorkflows({
        search: props.organization?._id,
      });
      if (!cancelled) {
        setPolls(pollsResponse);
        setSurveys(surveysResponse);
        setTracks(tracksResponse);
        setWorkflows(workflowsResponse);
      }
    }
    loadUsers();
    return function cleanup() {
      cancelled = true;
    };
  }, [reloadToggle]);
  return (
    <OrgActivity
      polls={polls}
      surveys={surveys}
      tracks={tracks}
      workflows={workflows ?? []}
    />
  );
};
