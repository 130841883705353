import React from "react";

import {
  ORG_LEVEL_TIERS,
  Tier,
  USER_LEVEL_TIERS,
  SubscriptionType,
} from "@pollyslack/hub/src/features/types";

export type PlanSelectorProps = {
  onChange: (plan: Tier) => void;
  subscriptionType: SubscriptionType;
  plan?: Tier;
};
export const PlanSelector: React.FunctionComponent<PlanSelectorProps> = (
  props: PlanSelectorProps,
) => {
  return (
    <select
      className="form-control"
      placeholder="Select a Plan"
      defaultValue={props.plan}
      onChange={(e) => {
        props.onChange(e.currentTarget.value as Tier);
      }}
    >
      {props.subscriptionType === SubscriptionType.ORG_LEVEL ||
      props.subscriptionType === SubscriptionType.ENTERPRISE_LEVEL
        ? ORG_LEVEL_TIERS.map((tier) => (
            <option value={tier} key={tier}>
              {tier}
            </option>
          ))
        : USER_LEVEL_TIERS.map((tier) => (
            <option value={tier} key={tier}>
              {tier}
            </option>
          ))}
    </select>
  );
};
