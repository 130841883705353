import React from "react";

import { Organization } from "@pollyslack/hub/src/types";
import { DocumentSearchPage } from "../../components/DocumentSearchPage";
import { EnterpriseTable } from "./components/EnterpriseTable";
import { list } from "../../services/api/Enterprise";

export class EnterprisePage extends React.Component {
  public render() {
    return (
      <DocumentSearchPage
        typeName="Enterprise"
        placeholder="Enterprise Grid ID or Slack Enterprise ID (exact match)"
        omitDeleted
        load={list}
        renderTable={(organizations: Organization[]) => (
          <EnterpriseTable organizations={organizations} />
        )}
      />
    );
  }
}
