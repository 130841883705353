import { User } from "@pollyslack/hub/src/types";

import { ResumeTokenInfo } from "../../common/apiTypes";
import { post } from "./rest";

export async function list(): Promise<{
  ok: boolean;
  error?: string;
  tokens?: ResumeTokenInfo[];
  users?: User[];
}> {
  return await post("/meteor-resume-tokens/list");
}

export async function create(
  userId: string,
): Promise<{ ok: boolean; error?: string; token?: string; when?: Date }> {
  return await post("/meteor-resume-tokens/create", { userId });
}

export async function deleteToken(
  userId: string,
  hashedToken: string,
): Promise<{ ok: boolean; error?: string }> {
  return await post("/meteor-resume-tokens/deleteToken", {
    userId,
    hashedToken,
  });
}
